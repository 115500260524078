import * as React from "react";
import { useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
  Typography,
  DialogContent,
  DialogActions,
  TextField,
  NativeSelect,
  Select,
  Chip,
  ListItemText,
  Dialog,
  DialogTitle,
  InputAdornment,
  MenuItem
} from "@material-ui/core";
import { TextBar } from "src/components/sub-components/TextBar";
import { alpha, makeStyles, Theme } from "@material-ui/core/styles";
import { primaryBlue, primaryGray, white } from "src/components/colors";
import { environment } from "src/environments/environment";
import { AddIconLarge, CloseIcon, DownArrow, QuestionIcon, UpArrow , UploadFile,Pointer} from "src/components/Icons";
import AddNewDecoder from "./CreateCodec";
import ReactSwitch from "react-switch";
import { AlertBar } from "src/components/sub-components/AlertBar";
import { useHistory } from 'react-router-dom';
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { AddDeviceParameterConfiguration } from "./AddDeviceParameterConfiguration";
import { BootstrapInput } from "../../product-types/add-new-firmware/steps/Step1";
import { CloseIconColor } from "src/components/Icons";
import { blue } from "@material-ui/core/colors";
import useStyles from "src/app/maintenance/assets/styles";

export function DefineProductTypeComponent(props) {
  const classes = local();
  const style = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showSensorPopup, setShowSensorPopup] = useState(false);
  const [showActuatorPopup, setShowActuatorPopup] = useState(false);
  const [showSensorOptions, setShowSensorOptions] = useState(false);
  const [showActuatorOptions, setShowActuatorOptions] = useState(false);
  const [isConnectionDropDown, setIsConnectionDropDown] = useState(false);
  const [isProtocolDropDown, setIsProtocolDropDown] = useState(false);
  const [isDataFormatDropDown, setIsDataFormatDropDown] = useState(false);
  const [isInputTypeDropDown, setIsInputTypeDropDown] = useState(false);
  const [isFormatDropDown, setIsFormatDropDown] = useState(false);
  const [isCodecDropDown, setIsCodecDropDown] = useState(false);
  const [addAnotherProduct, setAddAnotherProduct] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isToggled, setIsToggled] = useState(true);
  const [otaUpgradable, setOtaUpgradable] = useState(true);
  const [isTranscoding, setIsTranscoding] = useState(false);
  const [showCreateCodecDialog, setShowCreateCodecDialog] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSensors, setFilteredSensors] = useState([]);

  const [sensors, setSensors] = useState([]);
  const [actuators, setActuators] = useState([]);
  const [sensorsOfDevice, setSensorsOfDevice] = useState([]);
  const [actuatorsOfDevice, setActuatorsOfDevice] = useState([]);
  const [step, setStep] = useState("select");
  const [codecs, setCodecs] = useState([]);
  const [selectedCodec, setSelectedCodec] = useState('');
  const [productName, setProductName] = useState("");
  const [protocol, setProtocol] = useState("");
  const [dataFormat, setDataFormat] = useState("");
  const [connectivity, setConnectivity] = useState("");
  const [persistenceValue, setSelectedPersistence] = useState('');
  const [codecToSend, setCodecToSend] = useState("");
  const [remotelyConfigurable, setRemotelyConfigurable] = useState(true);

  //Error Messages
  const [errors, setErrors] = useState({
    productNameError: null,
    sensorsError: null,
    actuatorsError: null,
    protocolError: null,
    dataFormatError: null,
    connectivityError: null,
    persistenceError: null,
    parametersError:null,
  })

  const connectivityOptions = ["WIFI", "GSM"];
  const protocolOptions = ["MQTT", "HTTP"];
  const dataFormatOptions = ["String", "Json"];
  const inputTypeOptions = ["Text", "JSON", "HTML"]
  const formatOptions = ["String", "Integer", "Double", "Float", "Char"];

  const [addParameter, setAddParameter] = useState(false);
  const [mainType, setMainType] = useState(""); // network , topic or message
  const [type, setType] = useState("");
  const [index, setIndex] = useState(-1);

  //parameter inputs
  const [parameter, setParameter] = useState("");
  const [id, setId] = useState("");
  const [inputType, setInputType] = useState("");
  const [defaultVal, setDefaultVal] = useState("");
  const [format, setFormat] = useState("");
  const [join, setJoin] = useState([]);

  //final states that should send to BE ***************
  const [parameters, setParameters] = useState([]);
  const [parametersAddedError, setParametersAddedError] = useState(false);
  const [parameterError, setParameterError] = useState({
    error: false,
    errorMsg: "",
  });
  const [networkVisibility, setNetworkVisibility] = useState(false);
  const [topicVisibility, setTopicVisibility] = useState(false);
  const [messageVisibility, setMessageVisibility] = useState(false);

  const [open, setOpen] = useState(true);
  const [idError, setIdError] = useState({ error: false, errorMsg: "" });


  const openNetworkVisibility = () => {
    setNetworkVisibility(!networkVisibility);
  };
  const openTopicVisibility = () => {
    setTopicVisibility(!topicVisibility);
  };
  const openMessageVisibility = () => {
    setMessageVisibility(!messageVisibility);
  };

  const readParameters = (values) => {
    if (type === "add-new") {
      let error;
      if (values.parameter === "" || values.parameterId === "") {
        error = true;
        if (values.parameter === "") {
          setParameterError({
            error: true,
            errorMsg: "Parameter Name is required",
          });
        }
        if (values.parameterId === "") {
          setIdError({ error: true, errorMsg: "Id is required" });
        }
        if (error === true) {
          return;
        }
      }

      if (parameters.filter((obj) => obj.parameterId === values.parameterId).length > 0) {
        setIdError({ error: true, errorMsg: "Id Already exist" });
        return;
      }
      setParameters((current) => [...current, values]);
    }
    if (type === "edit" && index !== -1) {
      const keys = Object.keys(values);
      keys.map((k) => {
        if (values[k] !== "") {
          parameters[index][k] = values[k];
        }
      });
      setParameters(parameters);
      setType("");
    }
    setParameter("");
    setId("");
    setInputType("");
    setDefaultVal("");
    setFormat("");
    setJoin([]);
    setAddParameter(false);
  };

  const openAddParameter = (mainType, type, i) => {
    setAddParameter(true);
    setMainType(mainType);
    setType(type);
    setIndex(i);
    if (type === "edit") {
      setInputType(parameters[i].inputType);
      setFormat(parameters[i].format);
      setJoin(parameters[i].joinParameter);
      setDefaultVal(parameters[i].defaultValue);
      setId(parameters[i].parameterId);
    }
  };

  const closeAddParameter = () => {
    setType("");
    setAddParameter(false);
    setParameter("");
    setId("");
    setInputType("");
    setDefaultVal("");
    setFormat("");
    setJoin([]);
    setParameterError({ error: false, errorMsg: "" });
    setIdError({ error: false, errorMsg: "" });
  };

  const selectJoin = (e) => {
    setJoin(e.target.value);
  };
  
  const removeJoin = (value) => {
    setJoin(join.filter((d) => d !== value));
  };


  // Event listener to handle clicks outside dropdowns
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (
        showSensorOptions ||
        showActuatorOptions ||
        isConnectionDropDown ||
        isProtocolDropDown ||
        isDataFormatDropDown ||
        isCodecDropDown
      ) {
        if (
          !event.target.closest('.sensor-dropdown') &&
          !event.target.closest('.actuator-dropdown') &&
          !event.target.closest('.connection-dropdown') &&
          !event.target.closest('.protocol-dropdown') &&
          !event.target.closest('.dataformat-dropdown') &&
          !event.target.closest('.codec-dropdown')
        ) {
          setShowSensorOptions(false);
          setShowActuatorOptions(false);
          setIsConnectionDropDown(false);
          setIsProtocolDropDown(false);
          setIsDataFormatDropDown(false);
          setIsCodecDropDown(false);
        }
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [
    showSensorOptions,
    showActuatorOptions,
    isConnectionDropDown,
    isProtocolDropDown,
    isDataFormatDropDown,
    isCodecDropDown,
  ]);

  React.useEffect(() => {
    fetchCodecs();
    fetchMetaData();
  }, []);

  // Fetch MetaData from the API endpoint
  const fetchMetaData = () => {
    setLoading(true);
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    axios.get(`${environment.host}/core/meta-data`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        const sortedSensors = response.data.content.sensors.sort((a, b) => (a.name < b.name ? -1 : 1));
        setSensors(sortedSensors);
        setFilteredSensors(sortedSensors);
        setActuators(response.data.content.actuators.sort((a, b) => (a.name < b.name ? -1 : 1)));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching metadata:', error);
        setLoading(false);
      });
  };

  // Fetch product types from the API endpoint
  const fetchCodecs = async () => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    await axios.get(`${environment.host}/core/codec`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        setCodecs(response.data.content);
      })
      .catch((error) => {
        console.error('Error fetching codecs:', error.response.statusText);
      });
  };

  //API endpoint for the save product type
  const saveProductType = async () => {
    checkTranscoding();

    const dependencyMap = new Map();
    const deviceParameterConfiguration = {};
    const versions =[];
    parameters.forEach(param => {
      const paramId = param.parameterId;
      const joinParameters = param.joinParameter;

      joinParameters.forEach(joinParamId => {
        if (!dependencyMap.has(joinParamId)) {
          dependencyMap.set(joinParamId, []);
        }
        if (!dependencyMap.get(joinParamId).includes(paramId)) {
          dependencyMap.get(joinParamId).push(paramId);
        }

        if (!dependencyMap.has(paramId)) {
          dependencyMap.set(paramId, []);
        }
        if (!dependencyMap.get(paramId).includes(joinParamId)) {
          dependencyMap.get(paramId).push(joinParamId);
        }
      });

      delete param.joinParameter;
    });


    const joinParametersObj = Array.from(dependencyMap).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

    const version={};
    version["versionNum"] = "0.0.0";
    version["status"] ="APPROVED";
    version["remoteConfigurations"] = parameters;
    version["joinParameters"] = joinParametersObj;

    versions.push(version);


    const data = {
      productName: productName,
      sensorCodes: sensorsOfDevice.map(sensor => sensor.code),
      actuatorCodes: actuatorsOfDevice.map(actuator => actuator.code),
      persistence: persistenceValue === 'true',
      protocol: protocol,
      connectivity: connectivity,
      codecName: codecToSend,
      transcoder: isToggled === false,
      otaUpgradable: otaUpgradable === false,
      remotelyConfigurable: remotelyConfigurable === false,
      dataFormat: dataFormat,
      versions: versions
    };
    console.log('Data:', data);
    return await axios.post(`${environment.host}/core/product/productType`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
      .then((response) => {
        if (response.data.message === 'Success') {
          setReqSuccess(true);
          setErrorMessage('Product Type Added Successfully');
          setToaster(true);
          resetFormFields();
        }

        return response.data;
      })
      .catch((error) => {
        setLoading(false);
        setToaster(true);
        setErrorMessage(error.message);
        setReqSuccess(false);
        setTimeout(() => {
          setToaster(false);
        }, 4000);
        throw new Error(error.response.data.message);
      });
  };

  const resetFormFields = () => {
    setProductName('');
    setSensorsOfDevice([]);
    setShowSensorPopup(false)
    setActuatorsOfDevice([]);
    setShowActuatorPopup(false)
    setConnectivity('');
    setProtocol('');
    setDataFormat('');
    setOtaUpgradable(true);
    setRemotelyConfigurable(true);
    setSelectedPersistence('');
    setCodecToSend('');
    setAddAnotherProduct(false);
    setIsSubmitted(false);
    setIsToggled(true);
    setIsTranscoding(false);
    setParameters([]);
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const redirectToHome = () => {
    resetFormFields();
    setTimeout(() => {
      history.push(addAnotherProduct ? `/DefineProductType` : ``)
    }, 2000);
  };
  const handleBack = () => {
    resetFormFields();
    history.push(``);
  }

  const handleSave = async () => {
    console.log('Save')
    let isError = false;
    setIsSubmitted(true);

    const validationError = validationForm()
    setErrors(validationError)
    if (Object.keys(validationError).length > 0) {
      return;
    }
    setLoading(true);
    try {
      const response = await saveProductType();
      if (response.message === 'Success') {
        if (addAnotherProduct) {
          setLoading(false);
        } else {
          redirectToHome();
        }
      }
    } catch (error) {
      console.error('Error saving product type:', error);
      setLoading(false);
    }
  };

  const handleSensorPopup = () => {
    setShowSensorPopup(!showSensorPopup);
  };
  const handleActuatorPopup = () => {
    setShowActuatorPopup(!showActuatorPopup);
  };
  const handleProtocolChange = (option) => {
    setProtocol(option);
    setErrors(prevErrors => ({
      ...prevErrors,
      protocolError: null
    }));
  };
  const handleConnectivityChange = (option) => {
    setConnectivity(option);
    setErrors(prevErrors => ({
      ...prevErrors,
      connectivityError: null
    }));
  };
  const handleInputTypeChange = (option) => {
    setInputType(option);
    setErrors(prevErrors => ({
      ...prevErrors,
      inputTypeError: null
    }));
  }

  const handleFormatChange = (option) => {
    setFormat(option);
    setErrors(prevErrors => ({
      ...prevErrors,
      dataFormatError: null
    }));
  };
  const handleCodecChange = (option) => {
    setCodecToSend(option);
  };
  const handleCodecCreation = (name) => {
    setCodecToSend(name);
  };
  const handleCheckboxChange = (event) => {
    setAddAnotherProduct(event.target.checked);
  };

  const handleDataFormatChange = (option) => {
    setDataFormat(option);
    setErrors(prevErrors => ({
      ...prevErrors,
      dataFormatError: null
    }));
  };

  const validationForm = () => {
    let error: any = {}
    if (productName.trim() === "") {
      error.productNameError = "Product Type is Required"
    }

    if (sensorsOfDevice.length == 0 && actuatorsOfDevice.length == 0) {
      if (sensorsOfDevice.length == 0) {
        error.sensorsError = "Sensors are Required"
      }
      if (actuatorsOfDevice.length == 0) {
        error.actuatorsError = "Actuators are Required"
      }
    }

    if (protocol.trim() === "") {
      error.protocolError = "Protocol is Required"
    }

    if ((!otaUpgradable || !remotelyConfigurable) && dataFormat.trim() === "") {
      error.dataFormatError = "Data Format is Required"
    }

    if (!remotelyConfigurable && (!parameters || parameters.length === 0)) {
      error.parametersError = "Remote Config Parameters are Required";
    }
    
   if (connectivity.trim() === "") {
      error.connectivityError = "Connection is Required"
    }

    if (persistenceValue === '') {
      error.persistenceError = "Persistence is Required"
    }

    return error

  }

  const handleSensorSelection = (sensorName) => {
    if (sensorName === 'AddSensor') {
      setShowSensorPopup(true);
    } else {
      const selectedSensor = sensors.find(sensor => sensor.name === sensorName);
      if (selectedSensor && !sensorsOfDevice.some(sensor => sensor.name === selectedSensor.name)) {
        setSensorsOfDevice(prevSensors => [
          ...prevSensors,
          { name: selectedSensor.name, code: selectedSensor.code }
        ]);
      }
      setErrors(prevErrors => ({
        ...prevErrors,
        sensorsError: null,
        actuatorsError: null
      }));
    }
    setSearchQuery('');
    setFilteredSensors(sensors);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query === '') {
      setFilteredSensors(sensors);
    } else {
      const filtered = sensors.filter(sensor => sensor.name.toLowerCase().includes(query.toLowerCase()));
      setFilteredSensors(filtered);
    }
  };

  const removeSensorFromDevice = (indexToRemove) => {
    setSensorsOfDevice(prevSensors =>
      prevSensors.filter((sensor, index) => index !== indexToRemove)
    );
  };

  const handleActuatorSelection = (actuatorName) => {
    if (actuatorName === 'AddSensor') {
      setShowActuatorPopup(true);
    } else {
      const selectedActuator = actuators.find(actuator => actuator.name === actuatorName);
      if (selectedActuator && !actuatorsOfDevice.some(actuator => actuator.name === selectedActuator.name)) {
        setActuatorsOfDevice(prevActuators => [
          ...prevActuators,
          { name: selectedActuator.name, code: selectedActuator.code }
        ]);
      }
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      actuatorsError: null,
      sensorsError: null
    }));
  };
  const removeActuatorFromDevice = (indexToRemove) => {
    setActuatorsOfDevice(prevActuators =>
      prevActuators.filter((actuator, index) => index !== indexToRemove)
    );
  };

  const SensorPopup = () => {
    return (
      <Popup
        type="sensor"
        onClose={() =>
          setShowSensorPopup(false)
        } />
    );
  };
  const ActuatorPopup = () => {
    return (
      <Popup
        type="actuator"
        onClose={() =>
          setShowActuatorPopup(false)
        } />
    );
  };
  const Popup = ({ type, onClose }) => {
    return (
      <div style={{ color: 'black' }}>
        <div>
          <b>Need to add a {type === 'sensor' ? 'sensor' : 'actuator'}?</b> <br />
          Contact the admin for assistance!
        </div>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '0px',
            right: '5px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <CloseIcon />
        </button>
      </div>
    );
  };
  const handleChangeToggle = () => {
    handleTranscoding();
    setIsToggled(!isToggled)
    setIsCodecDropDown(false);
    setSelectedCodec("");
    setCodecToSend("");
    setStep("select");
  }
  const handleChangeOTAToggle = () => {
    setOtaUpgradable(!otaUpgradable)
  }

  const handleChangeRemoteToggle = () => {
    const newValue = !remotelyConfigurable;
  
    setRemotelyConfigurable(newValue);
  
    if (!newValue) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  
  
const handleTranscoding = () => {
    if (isToggled) {
      setIsTranscoding(true);
    }
  }

  const checkTranscoding = () => {
    if (isTranscoding) {
      if (codecToSend === "") {
        localStorage.setItem('transcodingIsPending', 'true');
      }
    }
  }
  const handleCreateCodecClick = () => {
    setStep("create");
    setShowCreateCodecDialog(!showCreateCodecDialog);
  };
  const handleSelectCodecClick = () => {
    setStep("select");
    setSelectedCodec("");
    fetchCodecs();
  };

  const successCallback = (message) => {
    if (message === "Success") {
      setLoading(false);
      setToaster(true);
      setErrorMessage('Codec Added Successfully');
      setReqSuccess(true);
    }
  }

  return (
    <div className={`testing`}>
      <div className="page-heading">
        Step 01
      </div>

      <Grid container spacing={5} style={{ paddingTop: "50px" }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={12}
            style={{
              textAlign: "center",
              paddingBottom: "40px"
            }}
          >
            <h4>Define Product Type</h4>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Product Name
                <span className={classes.required}>*</span> :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <FormControl className={style.input}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder="Add Product Name"
                  value={productName}
                  onChange={(e) => {
                    setProductName(e.target.value);
                    setErrors(prevErrors => ({
                      ...prevErrors,
                      productNameError: null
                    }));
                  }}
                />
                {errors.productNameError && (
                  <span className="form-error">
                    {errors.productNameError}
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Sensors
                <span className={classes.required}>*</span> :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <div className={`cloud-container ${showSensorPopup ? classes.popup : ''}`}>
                {showSensorPopup ? (
                  <label className="no-data-available" style={{ width: "100%" }}>
                    <SensorPopup />
                  </label>
                ) : (
                  <>
                    {sensorsOfDevice.length === 0 && (
                      <label className="no-data-available" style={{ width: "100%" }}>
                        No Sensor Added
                      </label>
                    )}
                    {sensorsOfDevice.map((sensor, index) => (
                      <div key={index} className="cloud-item">
                        <span className="sensor-number">{index + 1}</span>
                        <span className="cloud-item-text">{sensor.name}</span>
                        <div className="cloud-item-close">
                          <i onClick={() => removeSensorFromDevice(index)}
                            className="fa fa-times-circle"
                            style={{ color: "#d94737" }}>
                          </i>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {!showSensorPopup && (
                <>
                  <FormControl className={style.input} onClick={() => setShowSensorOptions(true)}>
                    <div className={classes.formInput} style={{width: '100%'}}>
                      <span style={{color: "grey"}}>
                        {showSensorOptions ? (
                          <InputBase
                            placeholder="Search Sensors"
                            style={{ fontFamily: "'Poppins',sans-serif", fontSize: '13px' }}
                            value={searchQuery}
                            onChange={handleSearchChange}
                            fullWidth
                            autoFocus
                          />
                        ) : (
                          <>Add Sensors</>
                        )}
                      </span>
                      {showSensorOptions ? <UpArrow /> : <DownArrow />}
                    </div>
                    {showSensorOptions && (
                      <Grid container className={classes.dropDownGrid}>
                        <Grid item xs={12} md={12}
                              className={style.option}
                              onClick={handleSensorPopup}
                        >
                          <div style={{margin: "0px",marginTop:"2px", display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                            <AddIconLarge/>
                            <span style={{marginLeft: '5px'}}> Add Sensors </span>
                          </div>
                        </Grid>
                        {filteredSensors.map((sensor, index) => (
                          <Grid item xs={12} md={12}
                                className={style.option}
                                key={index}
                                onClick={() => handleSensorSelection(sensor.name)}
                          >
                            <span style={{ marginLeft: '10px' }}> {sensor.name} </span>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    {errors.sensorsError && (
                      <span className="form-error">
                        {errors.sensorsError}
                      </span>
                    )}
                  </FormControl>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Actuators:
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <div className={`cloud-container ${showActuatorPopup ? classes.popup : ''}`}>
                {showActuatorPopup ? (
                  <label className="no-data-available" style={{ width: "100%" }}>
                    <ActuatorPopup />
                  </label>
                ) : (
                  <>
                    {actuatorsOfDevice.length === 0 && (
                      <label className="no-data-available" style={{ width: "100%" }}>
                        No Actuator Added
                      </label>
                    )}
                    {actuatorsOfDevice.map((actuator, index) => (
                      <div key={index} className="cloud-item">
                        <span className="sensor-number">{index + 1}</span>
                        <span className="cloud-item-text">{actuator.name}</span>
                        <div className="cloud-item-close">
                          <i
                            onClick={() => removeActuatorFromDevice(index)}
                            className="fa fa-times-circle"
                            style={{ color: "#d94737" }}
                          ></i>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {!showActuatorPopup && (
                <>
                  <FormControl className={style.input} onClick={() => setShowActuatorOptions(true)}>
                    <Typography className={classes.formInput}>
                      <span style={{ color: "grey" }}> Add Actuators </span>
                      {showActuatorOptions ? <UpArrow /> : <DownArrow />}
                    </Typography>
                    {showActuatorOptions && (
                      <Grid container className={classes.dropDownGrid}>
                        <Grid item xs={12} md={12}
                              className={style.option}
                              onClick={handleActuatorPopup}
                        >
                          <div style={{ margin: "0px", display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <AddIconLarge />
                            <span style={{ marginLeft: '5px' }}> Add Actuators </span>
                          </div>
                        </Grid>
                        {actuators.map((actuator, index) => (
                          <Grid item xs={12} md={12}
                                className={style.option}
                                key={index}
                                onClick={() => handleActuatorSelection(actuator.name)}
                          >
                            <span style={{ marginLeft: '10px' }}> {actuator.name} </span>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    {errors.actuatorsError && (
                      <span className="form-error">
                        {errors.actuatorsError}
                      </span>
                    )}
                  </FormControl>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Connectivity
                <span className={classes.required}>*</span> :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <FormControl className={style.input}>
                <Grid container style={{height: '40px'}}>
                  <Grid container className={classes.formInput}
                    style={{ cursor: 'pointer' }}
                    alignContent='center'
                    onClick={() => setIsConnectionDropDown(true)}
                  >
                    <Grid item style={{ flex: 1 }}>
                      <input
                        placeholder="Select Connection"
                        className={classes.optionInput}
                        value={connectivity}
                        onChange={(e) => setConnectivity(e.target.value)}
                      />
                    </Grid>
                    <Grid item> {isConnectionDropDown ? <UpArrow /> : <DownArrow />} </Grid>
                  </Grid>
                  {isConnectionDropDown && (
                    <div className={classes.dropdown}>
                      {connectivityOptions.map((option, index) => {
                        return (
                          <Grid item className={style.option} key={index}
                                onClick={() => {
                                  handleConnectivityChange(option)
                                }}
                          >
                            {option}
                          </Grid>
                        )
                      })
                      }
                    </div>
                  )}
                </Grid>
                {errors.connectivityError && (
                  <span className="form-error">
                    {errors.connectivityError}
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Protocol
                <span className={classes.required}>*</span> :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <FormControl className={style.input}>
                <Grid container style={{height: '40px'}}>
                  <Grid container className={classes.formInput}
                    style={{ cursor: 'pointer' }}
                    alignContent='center'
                    onClick={() => setIsProtocolDropDown(true)}
                  >
                    <Grid item style={{ flex: 1 }}>
                      <input
                        placeholder="Select Protocol"
                        className={classes.optionInput}
                        value={protocol}
                        onChange={(e) => setProtocol(e.target.value)}
                      />
                    </Grid>
                    <Grid item> {isProtocolDropDown ? <UpArrow /> : <DownArrow />} </Grid>
                  </Grid>
                  {isProtocolDropDown && (
                    <div className={classes.dropdown}>
                      {protocolOptions.map((option, index) => {
                        return (
                          <Grid item className={style.option} key={index}
                                onClick={() => {
                                  handleProtocolChange(option)
                                }}
                          >
                            {option}
                          </Grid>
                        )
                      })
                      }
                    </div>
                  )}
                </Grid>
                {errors.protocolError && (
                  <span className="form-error">
                    {errors.protocolError}
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Persistence
                <span className={classes.required}>* </span>
                <Tooltip
                  title="Persistence in device end refers to the ability of a device to retain data or settings even after being powered off or restarted ensuring continuity and smooth operation"
                  classes={{ tooltip: classes.questionTooltip }}
                >
                  <IconButton style={{ padding: "0px" }}>
                    <QuestionIcon />
                  </IconButton>
                </Tooltip> :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}>
              <FormControl>
                <Grid container alignItems="center">
                  <RadioGroup row
                    value={persistenceValue}
                    onChange={(e) => {
                      setSelectedPersistence(e.target.value);
                      setErrors(prevErrors => ({
                        ...prevErrors,
                        persistenceError: null
                      }));
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio className={style.radio} />}
                      label="True"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio className={style.radio}/>}
                      label="False"
                    />
                  </RadioGroup>
                  {isSubmitted && errors.persistenceError && (
                    <Grid item>
                      <span className="form-error">
                        {errors.persistenceError}
                      </span>
                    </Grid>
                  )}
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                OTA Upgradable:
              </Typography>
            </Grid>
            <Grid item xs={8} md={9} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <FormControl>
                <ReactSwitch
                  checked={!otaUpgradable}
                  onChange={handleChangeOTAToggle}
                  offColor='#D9D9D9'
                  onColor='#2A7CED'
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Remotely Configurable:
              </Typography>
            </Grid>
            <Grid item xs={8} md={9} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <FormControl>
                <ReactSwitch
                  checked={!remotelyConfigurable}
                  onChange={handleChangeRemoteToggle}
                  offColor='#D9D9D9'
                  onColor='#2A7CED'
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                />
              </FormControl>
            </Grid>
          </Grid>
          {errors.parametersError && (
                    <span className="form-error">
                      {errors.parametersError}
                    </span>
                  )}
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      {(!otaUpgradable || !remotelyConfigurable) && (
        <Grid container spacing={5}>
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={6}>
            <Grid container alignItems="center">
              <Grid item xs={4} md={3}>
                <Typography className={classes.text}>
                  Data Format
                  <span className={classes.required}>*</span> :
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <FormControl className={style.input}>
                  <Grid container style={{ height: '40px' }}>
                    <Grid container className={classes.formInput}
                      style={{ cursor: 'pointer' }}
                      alignContent='center'
                      onClick={() => setIsDataFormatDropDown(true)}
                    >
                      <Grid item style={{ flex: 1 }}>
                        <input
                          placeholder="Select Data Format"
                          className={classes.optionInput}
                          value={dataFormat}
                          onChange={(e) => setDataFormat(e.target.value)}
                        />
                      </Grid>
                      <Grid item> {isDataFormatDropDown ? <UpArrow /> : <DownArrow />} </Grid>
                    </Grid>
                    {isDataFormatDropDown && (
                      <div className={classes.dropdown}>
                        {dataFormatOptions.map((option, index) => {
                          return (
                            <Grid item className={style.option} key={index}
                              onClick={() => {
                                handleDataFormatChange(option)
                              }}
                            >
                              {option}
                            </Grid>
                          )
                        })
                        }
                      </div>
                    )}
                  </Grid>
                  {errors.dataFormatError && (
                    <span className="form-error">
                      {errors.dataFormatError}
                    </span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
      )}

      <>
        {(open && !remotelyConfigurable )&&  (
          <Dialog open={open} fullWidth maxWidth="md">
            <Grid container>
              <Grid item xs={10} md={10}>
                <DialogTitle>
                  {type === "view" ? "View" : type === "edit" ? "Edit" : "Add"}{" "}
                  Parameter
                </DialogTitle>
              </Grid>
              <Grid item xs={2} md={2} style={{ textAlign: "right" }}>
                <IconButton
                 onClick={() => {
                  if (addParameter) {
                    closeAddParameter();
                  } else {
                    if (!parameters || parameters.length === 0) {
                      setParametersAddedError(true);
                    } 
                    setOpen(false);
                    
                  }
                }}
                
                >
                  <CloseIconColor />
                </IconButton>
              </Grid>
            </Grid>

            <DialogContent>
              {(!remotelyConfigurable && !addParameter) && (
                <Grid container style={{ textAlign: "center", marginTop: "20px" }}>
                  <AddDeviceParameterConfiguration
                    openNetworkVisibility={openNetworkVisibility}
                    openTopicVisibility={openTopicVisibility}
                    openMessageVisibility={openMessageVisibility}
                    networkVisibility={networkVisibility}
                    topicVisibility={topicVisibility}
                    messageVisibility={messageVisibility}
                    open={(mainType, type, i) =>
                      openAddParameter(mainType, type, i)
                    }
                    parameters={parameters}
                    readParameters={(parameters) => readParameters(parameters)}
                    parametersAddedError={parametersAddedError}
                  />
                </Grid>

              )}

              {addParameter && (
                <Grid container style={{ textAlign: "center", marginTop: "20px" }}>
                  <Grid item xs={6} style={{ textAlign: "left", paddingBottom: "20px" }}>
                    <Typography className={classes.text}>Enter Parameter</Typography>
                    <TextBar
                      disabled={type === "view" ? true : false}
                      error={parameterError}
                      onChange={(e) => {
                        setParameter(e.target.value);
                        setParameterError({ error: false, errorMsg: "" });
                      }}
                      value={
                        type === "edit" || type === "view"
                          ? parameters[index].parameter
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6} style={{ textAlign: "left", paddingBottom: "20px", paddingLeft: "20px" }}>
                    <Typography className={classes.text}>Enter ID</Typography>
                    <TextBar
                      disabled={type === "add-new" ? false : true}
                      type={"number"}
                      error={idError}
                      onChange={(e) => {
                        setId(e.target.value);
                        setIdError({ error: false, errorMsg: "" });
                      }}
                      onWheel={(e) => e.target.blur()}
                      value={
                        type === "edit" || type === "view"
                          ? parameters[index].parameterId
                          : ""
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{ textAlign: "left", paddingBottom: "20px" }}
                  >
                    <Typography className={classes.text}>Enter Input Type</Typography>
                    <FormControl className={style.input}>
                      <Grid container style={{height: '40px'}}>
                        <Grid container className={classes.formInput}
                          style={{ cursor: 'pointer' }}
                          alignContent='center'
                          onClick={() => setIsInputTypeDropDown(!isInputTypeDropDown)}
                          >
                          <Grid item style={{ flex: 1 }}>
                            <input
                              disabled={type === "view"}
                              placeholder="Select Input Type"
                              className={classes.optionInput}
                              value={type === "view" ? parameters[index].inputType : inputType}
                              onChange={(e) => setInputType(e.target.value)}
                            />
                            </Grid>
                            <Grid item> {isInputTypeDropDown ? <UpArrow /> : <DownArrow />} </Grid>
                          </Grid>
                          {isInputTypeDropDown && (
                            <div className={classes.dropdown}>
                              {inputTypeOptions.map((option, index) => {
                                return (
                                  <Grid item className={style.option} key={index}
                                    onClick={() => {
                                      handleInputTypeChange(option)
                                      setIsInputTypeDropDown(!isInputTypeDropDown)
                                    }}
                                  >
                                    {option}
                                  </Grid>
                                )
                              })
                              }
                            </div>
                          )}
                      </Grid>
                      {/* <NativeSelect
                        id="demo-customized-select-native"
                        disabled={type === "view"}
                        value={type === "view" ? parameters[index].inputType : inputType}
                        onChange={(e) => setInputType(e.target.value)}
                      >
                        <option value="" disabled hidden>Select Input Type</option>
                        <option value="text">Text</option>
                        <option value="json">JSON</option>
                        <option value="html">HTML</option>
                      </NativeSelect> */}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{
                      textAlign: "left",
                      paddingBottom: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography className={classes.text}>Default &nbsp;
                    <Tooltip
                          arrow
                          title={
                            <React.Fragment>
                              <Grid container>
                                <Grid item xs={12} md={12}>
                                  <Typography
                                    style={{fontSize: "12px", color: '#FFFFFF', fontWeight: 300, fontFamily: 'poppins'}}
                                  >{"Use curly braces {} to specify values that vary by device. For example, "}<br/>
                                  {"S2D/SA/VA/{deviceId}"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                          interactive
                          placement="right"
                        >
                         <IconButton style={{padding: "1px 2px 2px 0px"}}>
                            <QuestionIcon/>
                          </IconButton>
                        </Tooltip>
                    </Typography>
                    <TextBar
                      disabled={type === "view" ? true : false}
                      error={{ error: false }}
                      onChange={(e) => setDefaultVal(e.target.value)}
                      value={
                        type === "edit" || type === "view"
                          ? parameters[index].defaultValue
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6} style={{ textAlign: "left", paddingBottom: "20px" }}>
                    <Typography className={style.label}>Enter Format</Typography>
                    <FormControl className={style.input}>
                      <Grid container style={{height: '40px'}}>
                        <Grid container className={classes.formInput}
                        style={{ cursor: 'pointer' }}
                        alignContent="center"
                        onClick={() => setIsFormatDropDown(!isFormatDropDown)}
                        >
                          <Grid item style={{ flex: 1 }}>
                            <input
                              disabled={type === "view"}
                              placeholder="Select Format Type"
                              className={classes.optionInput}
                              value={type === "view" ? parameters[index].format : format}
                              onChange={(e) => setFormat(e.target.value)}
                            />
                          </Grid>
                          <Grid item> {isFormatDropDown ? <UpArrow /> : <DownArrow />} </Grid>
                          </Grid>
                          {isFormatDropDown && (
                            <div className={classes.dropdown}>
                              {formatOptions.map((option, index) => {
                                return (
                                  <Grid item className={style.option} key={index}
                                    onClick={() => {
                                      handleFormatChange(option)
                                      setIsFormatDropDown(!isFormatDropDown)
                                    }}
                                  >
                                    {option}
                                  </Grid>
                                )
                              })
                              }
                            </div>
                          )}
                        </Grid>
                      {/* <NativeSelect
                        id="demo-customized-select-native"
                        disabled={type === "view"}
                        value={type === "view" ? parameters[index].format : format}
                        onChange={(e) => setFormat(e.target.value)}
                      >
                        <option className="" value="" disabled hidden>Select Format Type</option>
                        <option value="String">String</option>
                        <option value="int">Integer</option>
                        <option value="double">Double</option>
                        <option value="float">Float</option>
                        <option value="char">Char</option>
                      </NativeSelect> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ textAlign: "left", paddingBottom: "20px" , paddingLeft: "20px" }}>
                    <Typography className={style.label}>Join Parameter</Typography>
                    <FormControl style={{ padding: "0px", minWidth: "78%" }}>
                      <Select
                        disabled={type === "view" ? true : false}
                        id="demo-customized-select-native"
                        value={
                          type === "view" ? parameters[index].joinParameter : join
                        }
                        className={style.input}
                        onChange={selectJoin}
                        multiple
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        renderValue={(selected: string[]) => (
                          <div>
                            {selected.map((value, i) => (
                              <Chip
                                key={i}
                                label={value}
                                className={style.chip}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                                onDelete={() => removeJoin(value)}
                              />
                            ))}
                          </div>
                        )}
                        input={<BootstrapInput />}
                      >
                        {type === "add-new" ? (
                          parameters
                            .filter((obj) => obj.parameterCategory === mainType)
                            .map((p, j) => {
                              return (
                                <MenuItem value={p.parameterId} key={j}>
                                  <ListItemText
                                    primary={p.parameterId}
                                    style={{ color: primaryGray }}
                                  />
                                </MenuItem>
                              );
                            })
                        ) : type === "edit" ? (
                          parameters
                            .filter((obj) => obj.parameterCategory === mainType)
                            .filter((ob) => ob.parameterId !== parameters[index].parameterId)
                            .map((p, j) => {
                              return (
                                <MenuItem value={p.parameterId} key={j}>
                                  <ListItemText
                                    primary={p.parameterId}
                                    style={{ color: primaryGray }}
                                  />
                                </MenuItem>
                              );
                            })
                        ) : (
                          <div></div>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            {(addParameter && type !== "view") && (
              <DialogActions>
                <Button className={style.backButton} variant="contained" onClick={() => closeAddParameter()}>Cancel</Button>
                <Button className={style.saveButton}
                  variant="contained"
                  onClick={() => {
                    readParameters({
                      parameterCategory: mainType,
                      parameter,
                      parameterId: id,
                      inputType,
                      format,
                      joinParameter: join,
                      defaultValue: defaultVal
                    });
                  }}
                >
                  {type === "edit" ? "Update" : "Save"}
                </Button>
              </DialogActions>
            )}
          </Dialog>
        )}
      </>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Transcoding :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9} style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
              <FormControl>
                <ReactSwitch
                  checked={!isToggled}
                  onChange={handleChangeToggle}
                  offColor='#D9D9D9'
                  onColor='#2A7CED'
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      {!isToggled && (
        <div>
          <Grid container spacing={5}>
            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6}>
  <Grid container>
    <Grid item xs={4} md={3}></Grid>
    <Grid item xs={8} md={9}>
      <Grid container>

        {/* SELECT CODEC METHOD */}
        <Grid item xs={6} md={6}
              onClick={() => setStep("select")}
              className={classes.formInput}
              style={{
                borderBottom: step === "select" ? "3px solid #2A7CED" : "1px solid #ccc",  
                cursor: 'pointer',
                padding: '0',
                textAlign: 'center',
                color: primaryGray,
                backgroundColor: white,
                borderRadius: '4px 0 0 4px',
              }}
        >
           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Pointer width={20} height={20}/>
  <span style={{ marginLeft: '8px' }}></span> 
  SELECT CODEC METHOD
</div>
            
          
        </Grid>

        {/* CREATE CODEC */}
        <Grid item xs={6} md={6}
              onClick={() => setStep("create")}
              className={classes.formInput}
              style={{
                borderBottom: step === "create" ? "3px solid #2A7CED" : "1px solid #ccc",  
                cursor: 'pointer',
                padding: '0px',
                textAlign: 'center',
                color: primaryGray,
                backgroundColor: white,
                borderRadius: '0 4px 4px 0',
              }}
        >
         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <UploadFile width={20} height={20} />
  <span style={{ marginLeft: '8px' }}></span> 
  CREATE CODEC
</div>

        </Grid>

      </Grid>
    </Grid>
  </Grid>
</Grid>

            <Grid item xs={1} md={3}></Grid>
          </Grid>

          {/* create codec form elements */}
          {step === 'create' && (
            <Grid container spacing={5}>
              <Grid item xs={1} md={3}></Grid>
              <Grid item xs={10} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={4} md={3}></Grid>
                  <Grid item xs={8} md={9}>
                    <AddNewDecoder
                      onClose={handleSelectCodecClick}
                      codecs={codecs}
                      onCodecToPlaceholder={handleCodecCreation}
                      successCallback={successCallback}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* select codec form elements */}
          {step !== 'create' && (
            <Grid container spacing={5}>
              <Grid item xs={1} md={3}></Grid>
              <Grid item xs={10} md={6}>
                <Grid container>
                  <Grid item xs={4} md={3}></Grid>
                  <Grid item xs={8} md={9}>
                    <FormControl className={style.input}>
                      <Grid container style={{height: '40px'}}>
                        <Grid container className={classes.formInput}
                          style={{ cursor: 'pointer' }}
                          alignContent='center'
                          onClick={() => setIsCodecDropDown(true)}
                        >
                          <Grid item style={{ flex: 1 }}>
                            <input
                              placeholder={codecToSend === '' ? "Select Codec" : codecToSend}
                              className={classes.optionInput}
                              value={selectedCodec}
                              //onChange={() => setIsCodecDropDown(true)}
                              onChange={(e) => setSelectedCodec(e.target.value)}
                              readOnly={true}
                            />
                          </Grid>
                          <Grid item> {isCodecDropDown ? <UpArrow /> : <DownArrow />} </Grid>
                        </Grid>
                        {isCodecDropDown && (
                          <div style={{ width: '100%', zIndex: 1 }}>
                            <div className={classes.dropdown}>
                              {codecs.map((codec, index) => {
                                return (
                                  <Grid item xs={12} md={12}
                                        className={style.option}
                                        key={index}
                                        onClick={() => {
                                          handleCodecChange(codec.codecName)
                                        }}
                                  >
                                    <span style={{ marginLeft: '10px' }}> {codec.codecName} </span>
                                  </Grid>
                                )
                              })
                              }
                            </div>
                            <div style={{ marginTop: '20px' }} />
                          </div>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
          )}
        </div>
      )}

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}></Grid>
            <Grid item xs={8} md={9}>
              <FormGroup>
                <FormControlLabel
                  className={classes.text}
                  label="Add Another Product"
                  control={
                    <Checkbox
                      className={style.radio}
                      checked={addAnotherProduct}
                      onChange={handleCheckboxChange}
                    />
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5} style={{ paddingLeft: "10px" }}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingLeft: "400px"
          }}>
          <Button
            className={style.backButton}
            variant="contained"
            color="secondary"
            style={{
              marginRight: '10px'
            }}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button 
            className={style.saveButton} 
            variant="contained"
            color="primary"
            style={{

              boxShadow: 'none',
              height:"40px",
             
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={toaster}
        autoHideDuration={6000}
        onClose={handleCloseToaster}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToaster}
          severity={reqSuccess === true ? "success" : "error"}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80} />
        </div>
      }
    </div>
  );
}

const local= makeStyles((theme: Theme) => ({
  text: {
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif"
  },
  required: {
    color: "red",
  },
  formInput: {
    height: "38px",
    borderRadius: "5px",
    padding: "10px 15px",
    fontFamily: "'Poppins', sans-serif",
    border: "1px solid #C7C4C4",
    backgroundColor: white,
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  dropDownGrid: {
    backgroundColor: white,
    border: '1px solid #C7C4C4',
    borderRadius: '5px',
    height: '120px',
    overflowY: 'auto',
    marginBottom: '10px'
  },
  dropdown: {
    textAlign: 'left',
    border: '1px solid #C7C4C4',
    margin: 'px',
    borderRadius: '5px',
    backgroundColor: white,
    width: '100%',
    zIndex: 1,
    maxHeight: '160px',
    overflowY: 'auto',
  },
  optionInput: {
    cursor: 'pointer',
    border: 'none',
    fontFamily: "'Poppins', sans-serif"
  },
  helperText: {
    color: "red",
    fontSize: "small",
    paddingTop: "7px",
  },
  popup: {
    backgroundColor: "#CEEBCE",
  },
  dragAndDrop: {
    top: "316px",
    left: "361px",
    height: "180px",
    background: "#F8F8F8 0% 0% no-repeat padding-box",
    border: "2px dashed #427AE5",
    borderRadius: "5px",
    opacity: 1
  },
  questionTooltip: {
    backgroundColor: "#334B5A",
    color: 'white',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '12px',
  },
  radio: {
    '&.Mui-checked': {
      color: 'blue',
    },
  },
  saveButton: {
    width: '100px',
    borderRadius: '8px',
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
  },
  input: {
    width: "100%",
    textAlign: "left",
  },
  label: {
    fontSize: "15px",
    color: primaryGray,
    paddingBottom: "5px",
  },
  chip: {
    borderRadius: "4px",
    backgroundColor: "#C7C4C436",
    marginLeft: "5px",
  },
  inputDefaultValue: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  labelDefaultValue: {
    marginBottom: "20px",
  },
  chipDefault: {
    borderRadius: "2px",
    backgroundColor: "#C7C4C436",
    marginLeft: "2px",
    marginBottom: "10px",
  },
  add: {
    backgroundColor: primaryBlue,
    color: "white",
    marginBottom: "10px",
    marginRight: "10px",
    width: "20%",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  cancel: {
    backgroundColor: "white",
    color: primaryGray,
    marginBottom: "10px",
    marginRight: "10px",
    width: "20%",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  option: {
    paddingLeft: '10px',
    height: '30px',
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: "13px"
  },
  


}));
export default local;
