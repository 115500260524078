import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Grid, makeStyles, Theme, Typography,alpha} from "@material-ui/core";
import {primaryBlue, white} from "src/components/colors";
import {Link, useLocation} from 'react-router-dom';
import {DirectAPIComponent} from "./DirectAPI";
import {KitModelAPIComponent} from "./KitModelAPI";
import {environment} from "src/environments/environment";
import {toast} from "react-toastify";
import {LearnMoreComponent} from "./LearnMore";
import {DirectApiIcon, KitModelBasedApiIcon, QuestionIcon} from "src/components/Icons";
import { Loader } from "./components/Loader";
import axios from "axios";

export function UserApplicationComponent(props) {
  const classes = useStyles();
  const location = useLocation()
  const [isApiClick, setIsApiClick] = useState(false)
  const [isLearnMoreClick, setIsLearnMoreClick] = useState(false)
  const [apiType, setApiType] = useState("")
  const [allKitModels, setAllKitModels] = useState([])
  const [allDevices, setAllDevices] = useState([])
  const [lastDeviceId, setLastDeviceId] = useState("")
  const [isMappedKitCompleted, setIsMappedKitCompleted] = useState({})
  const [loading, setLoading] = useState(false)
  //Sensors
  const [allSensors, setAllSensors] = useState([])

  //Actuators
  const [allActuators, setAllActuators] = useState([])

  //Operations
  const [allTypes, setAllTypes] = useState([])

  //Kits
  const [allKits, setAllKits] = useState([])


  useEffect(() => {
    if (location.data) {
      setIsMappedKitCompleted(location.data.stepCompleted)
      console.log(location.data.stepCompleted.step3Completed)

    }

  }, []);

  useEffect(() => {
    Promise.all([
      getKitModels(),
      getKits(),
      metaData(),
      getAllKitTypes(),
      getDevices()
    ]).then(() => {
      setLoading(false); // Hide loading indicator when all API calls complete
    }).catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false); // Ensure loading indicator is hidden on error
    });
  }, []);

  const getKitModels = () => {
    return new Promise<void>((resolve, reject) => {
      axios.get(`${environment.host}/core/kit-model`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        }
      })
        .then((response) => {
          setAllKitModels(response.data.content)
          resolve();
        })
        .catch((error) => {
          console.error('Error fetching kit models:', error);
          reject(error);
        });
    })
  }

  const getDevices = () => {
    setLoading(true)
    return new Promise<void>((resolve, reject) => {
      axios.get(`${environment.host}/core/device`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        }
      })
        .then((response) => {
          setAllDevices(response.data.content)
          const lastDeviceId = response.data.content[response.data.content.length - 1].id
          setLastDeviceId(lastDeviceId)
          setLoading(false)
          resolve();
        })
        .catch((error) => {
          console.error('Error fetching devices:', error);
          reject(error);
        });
    })
  }

  const getKits = () => {
    return new Promise<void>((resolve, reject) => {
      axios.get(`${environment.host}/core/kit`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        }
      })
        .then((response) => {
          setAllKits(response.data.content)
          resolve();
        })
        .catch((error) => {
          console.error('Error fetching kits:', error);
          reject(error);
        });
    })
  }

  const metaData = () => {
    return new Promise<void>((resolve, reject) => {
      axios.get(`${environment.host}/core/meta-data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        }
      })
        .then((response) => {
          const sensors = response.data.content.sensors.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
          })
          const actuators = response.data.content.actuators.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
          })
          const types = response.data.content.kitTypes;
          setAllSensors(sensors)
          setAllActuators(actuators)
          resolve();
        })
        .catch((error) => {
          console.error('Error fetching metaData:', error);
          reject(error);
        });
    })
  }

  const getAllKitTypes = () => {
    return new Promise<void>((resolve, reject) => {
      axios.get(`${environment.host}/core/kitType`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        }
      })
        .then((response) => {
          const type = response.data.content;
          setAllTypes(type)
          resolve();
        })
        .catch((error) => {
          console.error('Error fetching kitTypes:', error);
          reject(error);
        });
    })
  }

  const handleLearnMoreClick = () => {
    setIsLearnMoreClick(true);
  };

  const directApi = () => {
    setIsApiClick(true),
      setApiType('directApi')
  }

  const handleKitModelApiClick = () => {
    setIsApiClick(true),
      setApiType('kitModelBasedApi')
  }

  return (
    <div className={`testing`}>
      <div className="page-heading">
        Step 03
      </div>

      <div style={{fontSize: '16px', lineHeight: '24px', marginTop: '50px'}}>
        <h4>Connect with the User Application
          {isApiClick ?
            (apiType === "directApi" ? ' [Direct API]' : ' [Kit Model Based API]')
            : null}
        </h4>
      </div>
      {isLearnMoreClick ? (
        <LearnMoreComponent setIsLearnMoreClick={setIsLearnMoreClick}/>
      ) : (
        <>
          {isApiClick === false && (
            <Grid container style={{paddingTop: '50px'}}>
              <Grid item xs={2} md={2}></Grid>
              <Grid item xs={8} md={8}>
                <Grid container justifyContent="flex-end">
                  <Link to="#" onClick={handleLearnMoreClick}>
                    <Grid item className={classes.moreContainer}>
                      <Grid item className={classes.more} style={{paddingRight: "4px", marginBottom: "10px"}}>
                        Learn more
                      </Grid>
                      <span style={{ marginTop: '4px' }}>
                      <QuestionIcon/>
                      </span>
                    </Grid>
                  </Link>
                </Grid>
                <Grid container>
                  <Grid item xs={5} md={5} onClick={() => {
                    directApi()
                  }} className={classes.options}>
                    <Grid container>
                      <Grid container justifyContent="center">
                        <DirectApiIcon color={primaryBlue} strokeOpacity={0}/>
                      </Grid>
                      <Grid container justifyContent="center" style={{color:primaryBlue}}>
                        <Typography>Direct API</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={2}></Grid>
                  <Grid item xs={5} md={5} onClick={() => {
                    handleKitModelApiClick()
                  }} className={classes.options}>
                    <Grid container>
                      <Grid container justifyContent="center">
                        <KitModelBasedApiIcon strokeColor={primaryBlue} fillColor1={primaryBlue}/>
                      </Grid>
                      <Grid container justifyContent="center"  style={{color:primaryBlue}}>
                        <Typography>Kit Model Based API</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container style={{height: '30px'}}></Grid>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link to="">
                      <Button
                        className={classes.getStarted}
                        style={{textTransform: "none"}}
                        onClick={() => setIsApiClick(false)}
                      >
                        BACK
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} md={2}></Grid>
            </Grid>
          )}
        </>
      )}
      {isApiClick && (
        apiType === 'directApi' ? (
          <DirectAPIComponent setIsApiClick={setIsApiClick} isMappedKitCompleted={isMappedKitCompleted} lastDeviceId={lastDeviceId}/>
        ) : (
          <KitModelAPIComponent
            setIsApiClick={setIsApiClick}
            allSensors={allSensors}
            allActuators={allActuators}
            allTypes={allTypes}
            allKitModels={allKitModels}
            allDevices={allDevices}
            allKits={allKits}
            getKitModels={() => getKitModels()}
            getKits={() => getKits()}
            getKitType={() => getAllKitTypes()}
            isMappedKitCompleted={isMappedKitCompleted}
            step3Completed={location.data ? `${location.data.stepCompleted.step3Completed}` : localStorage.getItem('step3Completed')}
            loading={loading}
            setLoading={setLoading}
            getDevices={() => getDevices()}
            metaData={() => metaData()}
          />
        )
      )}
      <Loader loading={loading}/>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    transition: "0.55s",
    textAlign: "left",
    margin: "30px 100px 0px 100px",
  },
  container: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center'
  },
  boxContainer: {
    position: "absolute",
    padding: "20px",
    margin: "20px 40px 0px 0px",
    background: "#FFFFFF",
    boxShadow: "0px 3px 8px #00000012",
    borderRadius: "16px",
    opacity: 1,
  },
  connectText: {
    position: 'relative',
    width: '274px',
    height: '24px',
    marginTop: '30px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    display: 'flex',
    justifyContent: 'center'
  },
  getStarted: {
    backgroundColor: primaryBlue,
    borderRadius: "4px",
    color: white,
    boxShadow: "none",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      backgroundColor: alpha(primaryBlue, 0.8),
      // color: white,
      // boxShadow: "none",
      // border: 'solid 1px #000000'
    },
    // border: 'solid 1px #555555',
    width: '161px',
    height: '40px',
    lineHeight:"24px",
    fontSize:"16px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '40px',
    marginRight: '150px'
  },
  options: {
    width: 'calc((100% - 40px ) / 3)',
    height: '400px',
    borderRadius: '5px',
    backgroundColor: white,
    boxShadow: '0 2px 10px #0000000A',
    minWidth: '200px',
    textAlign: 'center',
    lineHeight: '20px',
    color: white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    "&:hover": {
      opacity: 0.8
    },
    margin: 0,
  },
  disabledOptions: {
    width: 'calc((100% - 40px ) / 3)',
    height: '400px',
    borderRadius: '5px',
    backgroundColor: '#D9D9D9',
    minWidth: '200px',
    textAlign: 'center',
    lineHeight: '20px',
    color: "#555555",
    opacity: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // cursor:'pointer',
    "&:hover": {
      opacity: 0.8
    },
    margin: 0,
  },
  type: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '45px',
    backgroundColor: '#2A7CED26',
    height: '29px',
    fontSize: 16,
    color: '#000000',
    lineHeight: 24,
    alignItems: 'center',
    borderRadius: '5px',
  },
  pageHeading: {
    borderRadius: '6px',
    width: '200px',
    top: '30px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 8px #0000000A',
    border: '0.48px solid #EDEDED',
    padding: '10px 20px 4px',
    color: '#324054',
    fontWeight: 500,
    textAlign: 'center'
  },
  more: {
    textDecoration: 'underline',
    color: '#2A7CED',
    weight: 400,
    height: '24px',
  },
  moreContainer: {
    display: 'flex',
  }

}));

