import React, { useEffect, useState } from 'react';
import { Grid, InputBase, IconButton, Button, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { lightGray, white } from 'src/components/colors';
import useStyles from '../../assets/styles';
import { ParameterDropDown } from './ParameterDropDown';
import { DropDown } from '../components/DropDown';
import { matchSensorNames} from '../components/apiHelper';

export const SubTestCases = ({
    subTestCases, 
    setSubTestCases,
    subTestCaseErrors,
    selectedBatch
}) => {
  
  const classes = useStyles();
  const [parameters, setParameters] = useState([]);

  const [successCriterias, setSuccessCriterias] = useState([
    "No of Data",
    "Greater than",
    "Greater than or equal to",
    "Less than",
    "Less than or equal to",
    "Is equal to",
    "Is not equal to",
    "Is between",
    "Is not between",
    "Average",
    "Increasing",
    "Decreasing"
  ]);

  useEffect(() => {
    const getParameters = async () => {
      try {
        const parameters = await matchSensorNames(selectedBatch);
        setParameters(parameters);
      } catch (err) {
        console.error('Error fetching parameters:', err);
      }
    };
    if (selectedBatch) {
      getParameters();
    }
  }, [selectedBatch]);

  const parameterOptions = parameters.map(param => ({
    label: param.name,
    value: param.code   
  }));

  const handleSubTestCaseChange = (index, { target: { name, value } }) => {
    const newSubTestCases = [...subTestCases];
    newSubTestCases[index][name] = value;
    setSubTestCases(newSubTestCases);
  };

  const handleParameterChange = (index, selectedParameter) => {
    const newSubTestCases = [...subTestCases];
    newSubTestCases[index].parameter = selectedParameter;
    setSubTestCases(newSubTestCases);
  };

  const handleAddSubTestCase = () => {
    setSubTestCases([...subTestCases, {
      subTestCaseName: '',
      successCriteria: '',
      parameter: '',
      criteriaValue: '',
      minValue: '',
      maxValue: ''
    }]);
  };

  const handleDeleteSubTestCase = (index) => {
    if (subTestCases.length > 1) {
      const newSubTestCases = subTestCases.filter((_, i) => i !== index);
      setSubTestCases(newSubTestCases);
    }
  };

  return (
    <>
      {subTestCases.map((subTestCase, index) => (
        <Grid container alignItems="center" spacing={2} key={index}
              style={{ marginTop: "20px", borderRadius: "5px", backgroundColor: lightGray }}>
          <Grid container alignItems="center" spacing={2} style={{ margin: "20px" }}>
            <Grid item xs={12} md={3}>
                <Typography className={classes.typo}>
                  Sub Test Case: 
                  <span style={{ color: 'red' }}>*</span>
                </Typography> 
            </Grid>
            <Grid item xs={12} md={9}>
              <InputBase
                className={classes.formInput}
                style={{ backgroundColor: white }}
                type="text"
                placeholder="Enter Sub Test Case Name Here"
                name="subTestCaseName"
                value={subTestCase.subTestCaseName}
                onChange={(e) => handleSubTestCaseChange(index, e)}
              />
              {subTestCaseErrors[index] && subTestCaseErrors[index].subTestCaseNameError && (
                <Grid container justifyContent="flex-end" alignItems="center" >
                  <Typography className={classes.errorText}>
                    {subTestCaseErrors[index].subTestCaseNameError}
                  </Typography>
                </Grid>                
              )}
            </Grid>

            <Grid item xs={12} md={3}>
                <Typography className={classes.typo}>
                  Parameter: 
                  <span style={{ color: 'red' }}>*</span>
                </Typography> 
            </Grid>
            <Grid item xs={12} md={9}>
              <ParameterDropDown
                options={parameterOptions}
                type='parameter'
                emptyTag='Select Parameter'
                setSelectOption={(selectedParameter) => 
                    handleParameterChange(index, selectedParameter)
                }
                value={subTestCase.parameter}
                isSingle={true}
              />
              {subTestCaseErrors[index] && subTestCaseErrors[index].parameterError && (
                <Grid container justifyContent="flex-end" alignItems="center" >
                  <Typography className={classes.errorText}>
                    {subTestCaseErrors[index].parameterError}
                  </Typography>
                </Grid>                
              )}
            </Grid>

            <Grid item xs={12} md={3}>
                <Typography className={classes.typo}>
                  Success Criteria: 
                  <span style={{ color: 'red' }}>*</span>
                </Typography> 
            </Grid>
            <Grid item xs={12} md={9}>
              <DropDown
                options={successCriterias}
                type='successCriteria'
                emptyTag='Select Success Criteria'
                setSelectOption={(value) => 
                    handleSubTestCaseChange(index, { target: { name: 'successCriteria', value } })}
                value={subTestCase.successCriteria}
                isSingle={true}
              />
              {subTestCaseErrors[index] && subTestCaseErrors[index].successCriteriaError && (
                <Grid container justifyContent="flex-end" alignItems="center" >
                  <Typography className={classes.errorText}>
                    {subTestCaseErrors[index].successCriteriaError}
                  </Typography>
                </Grid>
              )}
            </Grid>

            {["No of Data","Greater than", "Greater than or equal to", "Less than", "Less than or equal to", "Is equal to", "Is not equal to", "Average"]
              .includes(subTestCase.successCriteria) && (
              <>
                <Grid item md={3}></Grid>
                <Grid item xs={12} md={9}>
                  <InputBase
                    className={classes.formInput}
                    style={{ backgroundColor: white }}
                    type="text"
                    placeholder="Enter Value Here"
                    name="criteriaValue"
                    value={subTestCase.criteriaValue}
                    onChange={(e) => handleSubTestCaseChange(index, e)}
                  />
                  {subTestCaseErrors[index] && subTestCaseErrors[index].criteriaValueError && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <Typography className={classes.errorText}>
                        {subTestCaseErrors[index].criteriaValueError}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            {["Is between", "Is not between", "Increasing", "Decreasing"]
              .includes(subTestCase.successCriteria) && (
              <>
                <Grid item xs={3} md={3}></Grid>
                <Grid item xs={4} md={4}>
                  <InputBase
                    className={classes.formInput}
                    style={{ backgroundColor: white }}
                    type="text"
                    placeholder="Min Value"
                    name="minValue"
                    value={subTestCase.minValue}
                    onChange={(e) => handleSubTestCaseChange(index, e)}
                  />
                  {subTestCaseErrors[index] && subTestCaseErrors[index].minValueError && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <Typography className={classes.errorText}>
                        {subTestCaseErrors[index].minValueError}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={4} md={4}>
                  <InputBase
                    className={classes.formInput}
                    style={{ backgroundColor: white }}
                    type="text"
                    placeholder="Max Value"
                    name="maxValue"
                    value={subTestCase.maxValue}
                    onChange={(e) => handleSubTestCaseChange(index, e)}
                  />
                  {subTestCaseErrors[index] && subTestCaseErrors[index].maxValueError && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <Typography className={classes.errorText}>
                        {subTestCaseErrors[index].maxValueError}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            <Grid item xs={12} md={12} container justifyContent="flex-end">
              <IconButton
                className={classes.deleteButton}
                onClick={() => handleDeleteSubTestCase(index)}
                disabled={subTestCases.length === 1}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>

          </Grid>
        </Grid>
      ))}

      <Grid container justifyContent="flex-end" alignItems="flex-start" spacing={2} style={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          className={classes.addNew}
          style={{ textTransform: "none" }}
          onClick={handleAddSubTestCase}
        >
          <AddIcon className={classes.typo}/>
          Add New Sub Test Case
        </Button>
      </Grid>

    </>
  );
};

