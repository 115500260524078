import React, {useEffect, useState} from "react";
import axios from "axios";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import {primaryBlue, white} from "src/components/colors";
import {DownArrowIcon, HorizontalLine, UpArrow} from "src/components/Icons";
import {environment} from "src/environments/environment";
import {AlertBar} from "src/components/sub-components/AlertBar";
import {useHistory} from "react-router-dom";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {Loader} from "../step-3/components/Loader";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function AddUserComponent(props) {
  const classes = useStyles();
  const [isAdvanceOption, setIsAdvanceOption] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [userRoleId, setUserRoleId] = useState(null)
  const [selectedReferences, setSelectedReferences] = useState('');
  const [addAnotherUser, setAddAnotherUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [references, setReferences] = useState([]);
  const [hostUrl, setHostUrl] = useState('');

  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState({password: false, confirmPassword: false})

  //error states
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [referenceError, setReferenceError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [mobileFormatError, setMobileFormatError] = useState(false);
  const [loading, setLoading] = useState(false);

  //alert states
  const [alertOpen, setAlertOpen] = useState(false)
  const [severity, setSeverity] = useState("success")
  const [topic, setTopic] = useState("")
  const [message, setMessage] = useState("")

  const history = useHistory();

  type User = {
    lastName: string;
    email: string;
    mobile: string;
    userRoleId: any;
    references?: any[];
    username?: string;
    password?: string;
    confirmPassword?: string;
    host: any;
  };


  const handleAdvanceOption = () => {
    setIsAdvanceOption(!isAdvanceOption);
  };

  const handleCheckboxChange = (event) => {
    setAddAnotherUser(event.target.checked);
  };

  const showErrorMessage = () => {
    setAlertOpen(true);
    setSeverity("error");
    setTopic("Error");
    setMessage("Email already exists");
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  }

  const showMobileErrorMessage = () => {
    setAlertOpen(true);
    setSeverity("error");
    setTopic("Error");
    setMessage("Mobile Number already exists");
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  }

  useEffect(() => {
    const userId = localStorage.getItem('USER_ID');
    const fetchMetaData = async () => {
      try {
        const response = await axios.get(`${environment.host}/user/${userId}/metadata`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },

        });
        const metaData = response.data;
        const hostUrl = metaData.content.configuration.configHost;
        setHostUrl(hostUrl);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchMetaData();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${environment.host}/ums/super/user-role`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        });
        if (response.data.content) {
          setRoles(response.data.content);
          console.log('Roles:', response.data.content);
          const lastRole = response.data.content[response.data.content.length - 1];
          setSelectedRole(lastRole.name);
          setUserRoleId(lastRole.id)
          setLoading(false);
        } else {
          console.error('Error fetching roles:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching the roles:', error);
      }
    };

    fetchRoles();
  }, []);

  const referenceId = selectedReferences ? references.find(ref => ref.name === selectedReferences).id : '';

  useEffect(() => {
    const userRoleId = roles.find(role => role.name === selectedRole) ? roles.find(role => role.name === selectedRole)!.id : undefined;
    const fetchReferences = async () => {
      if (!userRoleId) {
        return;
      }
      try {
        const response = await axios.get(`${environment.host}/ums/super/user-role/${userRoleId}/references`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        });
        if (response.data.content) {
          setReferences(response.data.content);
        } else {
          console.error('Error fetching references:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching the references:', error);
      }
    }
    fetchReferences();
  }, [selectedRole, roles]);

  useEffect(() => {
    const userId = localStorage.getItem('USER_ID');
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${environment.host}/ums/user/${userId}/system-user?filter=none`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        });
        if (response.data.content) {
          setUsers(response.data.content);
          console.log('Users:', response.data.content);
        } else {
          console.error('Error fetching users:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const saveUser = async () => {
    let user: User = {
      lastName: lastName,
      email: email,
      mobile: mobile,
      userRoleId: userRoleId,
      references: referenceId ? [referenceId] : [],
      host: hostUrl
    };

    // If advance option is enabled, include username, password, and confirmPassword in the user object
    if (isAdvanceOption) {
      user = {
        ...user,
        username: username,
        password: password,
        confirmPassword: confirmPassword
      };
    }
         await axios.post(`${environment.host}/ums/super/user`, user, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        })
        .then((response) => {

        if (response.status === 200) {
          console.log('User added successfully:', response.data);
          setAlertOpen(true);
          setSeverity("success");
          setTopic("Success");
          setMessage("User added successfully. Please go to the Dashboard to activate the user");
          setTimeout(() => {
            setAlertOpen(false);
          }, 3000);
          if (addAnotherUser) {
            setLoading(false);
            resetUserForm();
            return;
          } else {
            setLoading(false);
            resetUserForm();
            redirectToHomePage();
          }
          return response.data.content;
        }
      })
    .catch ((error) => {
      console.error('Error saving user:', error);
      setAlertOpen(true);
      setSeverity("error");
      setTopic("Error");
      setMessage(error.response.data.message);
      setTimeout(() => {
        setAlertOpen(false);
      }, 3000);
      throw error;
    });
  };

  const resetUserForm = () => {
    setLastName('');
    setEmail('');
    setMobile('');
    setSelectedRole(selectedRole);
    setSelectedReferences('');
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setAddAnotherUser(false);
  };

  const redirectToHomePage = () => {
    resetUserForm();
    setTimeout(() => {
      history.push('/');
    }, 3000);
  };

  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };

  const handleAddUser = async () => {
    let isError = false;
    setLoading(true);

    if (!hostUrl && !isAdvanceOption) {
      setAlertOpen(true);
      setSeverity("error");
      setTopic("Error");
      setMessage("UMS host url is not set, please contact admin or use with Advance option");
      setTimeout(() => {
        setAlertOpen(false);
      }, 5000);
    }

    if (lastName.trim() === '') {
      setLastNameError(true);
      isError = true;
    }
    if (email.trim() === '') {
      setEmailError(true);
      isError = true;
    }
    if (email.trim() && !isEmailValid(email)) {
      setEmailFormatError(true);
      isError = true;
    } else {
      setEmailFormatError(false);
    }
    if (mobile.trim() === '') {
      setMobileError(true);
      isError = true;
    }
    const isMobileValid = /^\d+$/.test(mobile.trim());
    if (mobile.trim() && !isMobileValid) {
      setMobileFormatError(true);
      isError = true;
    } else {
      setMobileFormatError(false);
    }
    if (selectedRole === '') {
      setRoleError(true);
      isError = true;
    }
    if (selectedReferences === '' && userRoleId >= 10) {
      setReferenceError(true);
      isError = true;
    }
    if (isAdvanceOption) {
      if (username.trim() === '') {
        setUsernameError(true);
        isError = true;
      }
      if (password.trim() === '') {
        setPasswordError(true);
        isError = true;
      }
      if (password.trim() && password.trim().length < 8) {
        setPasswordLengthError(true);
        isError = true;
      } else {
        setPasswordLengthError(false);
      }
      if (confirmPassword.trim() === '') {
        setConfirmPasswordError(true);
        isError = true;
      } else {
        setConfirmPasswordError(false);
      }
      if (confirmPassword.trim() && password.trim() !== confirmPassword.trim()) {
        setPasswordMatchError(true);
        isError = true;
      } else {
        setPasswordMatchError(false);
      }
    } else {
      setUsername('');
      setPassword('');
      setConfirmPassword('');
    }
    if (isError) {
      setLoading(false);
      return;
    }

    const mobileExists = users.some((user) => user.mobile === mobile);

    if (mobileExists) {
      showMobileErrorMessage();
    }

    const emailExists = users.some((user) => user.email === email);
    console.log('Email exists:', emailExists)

    if (emailExists) {
      showErrorMessage();
    }
    try {
      await saveUser();
      setLoading(false);
      localStorage.setItem('userCreated', 'true');
      // redirectToMainPage(true);
    } catch (error) {
      setLoading(false);
      console.error('Error add user:', error);
    }
  };

  const handleNameChange = (event) => {
    setLastName(event.target.value);
    setLastNameError(false);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
    if (email && isEmailValid(email)) {
      setEmailFormatError(false);
    }
  };
  const handleMobileChange = (event) => {
    setMobile(event.target.value);
    setMobileError(false);
    if (mobile && /^\d+$/.test(mobile)) {
      setMobileFormatError(false);
    }
  };
  const handleReferencesChange = (event) => {
    setSelectedReferences(event.target.value);
    setReferenceError(false);
  }
  const handleRoleChange = (event) => {
    const roleName = roles.find(role => role.id === parseInt(event.target.value)).name
    setSelectedRole(roleName);
    setUserRoleId(event.target.value)
    setRoleError(false);
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setUsernameError(false);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordError(false);
  };

  const handleClickShowPassword = (id) => {
    if (id == 'password') {
      setShowPassword((prevState) => ({
        ...prevState,
        password: !prevState.password
      }))
    } else {
      setShowPassword((prevState) => ({
        ...prevState,
        confirmPassword: !prevState.confirmPassword
      }))
    }

  }

  return (
    <div className={`testing`}>
      <div style={{fontSize: '16px', lineHeight: '24px', marginTop: '15px'}}>
        <h4>Add Users</h4>
      </div>
      <Grid container spacing={5} style={{paddingTop: "50px"}}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Name
                <span className={classes.required}>*</span> :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <FormControl className={classes.input}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder="Enter Your Name"
                  value={lastName}
                  onChange={(e) => {
                    handleNameChange(e);
                  }}
                />
                {lastNameError && (
                  <span className="form-error">
                    Name is required
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Email
                <span className={classes.required}>*</span> :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <FormControl className={classes.input}>
                <input
                  className={classes.formInput}
                  type="email"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => {
                    handleEmailChange(e);
                  }}
                />
                {emailError && (
                  <span className="form-error">
                    Email is required
                  </span>
                )}
                {emailFormatError && (
                  <span className="form-error">
                    Email is not valid
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Mobile
                <span className={classes.required}>*</span> :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <FormControl className={classes.input}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder="Enter Your Mobile Number"
                  value={mobile}
                  onChange={(e) => {
                    handleMobileChange(e);
                  }}
                />
                {mobileError && (
                  <span className="form-error">
                    Mobile Number is required
                  </span>
                )}
                {mobileFormatError && (
                  <span className="form-error">
                    Mobile Number is not valid
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Role
                <span className={classes.required}>*</span> :
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <FormControl className={classes.input}>
                <select
                  className={classes.formInput}
                  value={userRoleId || ''}
                  onChange={handleRoleChange}
                >
                  {/* <option value="" disabled hidden>Select Role</option> */}
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>{`${role.name} (${role.id})`}</option>
                  ))}
                </select>
                {roleError && (
                  <span className="form-error">
                    User Role is Required
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
      {selectedRole && userRoleId >= 10 && references && Array.isArray(references) &&
        <Grid container spacing={5}>
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={6}>
            <Grid container alignItems="center">
              <Grid item xs={4} md={3}>
                <Typography className={classes.text}>
                  User References
                  <span className={classes.required}>*</span> :
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <FormControl className={classes.input}>
                  <select
                    className={classes.formInput}
                    value={selectedReferences}
                    onChange={handleReferencesChange}
                  >
                    <option value="" disabled hidden>Select References</option>
                    {references.map((ref) => (
                      <option key={ref.id} value={ref.name}>{ref.name}</option>
                    ))}
                  </select>
                  {
                    referenceError && (
                      <span className="form-error">
                        User Reference is Required
                      </span>
                    )
                  }
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
      }

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text} style={{fontWeight: 'bold'}}>
                Advance Option
              </Typography>
            </Grid>
            <Grid item xs={9} md={8}>
              <HorizontalLine/>
            </Grid>
            <Grid onClick={() => handleAdvanceOption()} style={{cursor: 'pointer'}}>
              {isAdvanceOption ?
                <UpArrow/>
                : <DownArrowIcon/>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isAdvanceOption ?
        <div>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6}>
              <Grid container alignItems="center" style={{marginTop: '20px'}}>
                <Grid item xs={4} md={3}>
                  <Typography className={classes.text}>
                    Username
                    <span className={classes.required}>*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={8} md={9}>
                  <FormControl className={classes.input}>
                    <input
                      className={classes.formInput}
                      type="text"
                      placeholder="Enter Your Username"
                      autoComplete="off"
                      value={username}
                      onChange={(e) => {
                        handleUsernameChange(e);
                      }}
                    />
                    {usernameError && (
                      <span className="form-error">
                        Username is required
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} md={3}></Grid>
          </Grid>

          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6}>
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <Typography className={classes.text}>
                    Password
                    <span className={classes.required}>*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={8} md={9}>
                  <FormControl className={classes.input}>
                    <Input
                      className={classes.formInput}
                      type={showPassword.password ? "text" : "password"}
                      placeholder="Enter Your Password"
                      onChange={(e) => handlePasswordChange(e)}
                      value={password}
                      disableUnderline={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={
                              () => handleClickShowPassword('password')
                            }
                          >
                            {showPassword.password ? (
                              <VisibilityOff/>
                            ) : (
                              <Visibility/>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {passwordError && (
                      <span className="form-error">
                        Password is required
                      </span>
                    )}
                    {passwordLengthError && (
                      <span className="form-error">
                        Password must be 8 or more characters
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} md={3}></Grid>
          </Grid>

          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6}>
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <Typography className={classes.text}>
                    Confirm Password
                    <span className={classes.required}>*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={8} md={9}>
                  <FormControl className={classes.input}>
                    <Input
                      className={classes.formInput}
                      type={showPassword.confirmPassword ? "text" : "password"}
                      placeholder="Please Confirm Your Password"
                      onChange={(e) => handleConfirmPasswordChange(e)}
                      value={confirmPassword}
                      disableUnderline={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={
                              () => handleClickShowPassword('confirmPassword')
                            }
                          >
                            {showPassword.confirmPassword ? (
                              <VisibilityOff/>
                            ) : (
                              <Visibility/>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {confirmPasswordError && (
                      <span className="form-error">
                        Confirm Password is required
                      </span>
                    )}
                    {!confirmPasswordError && passwordMatchError && (
                      <span className="form-error">
                        Password Should match
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} md={3}></Grid>
          </Grid>
        </div>
        : <></>
      }

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}></Grid>
            <Grid item xs={8} md={9}>
              <FormGroup>
                <FormControlLabel
                  className={classes.text}
                  control={
                    <Checkbox
                      checked={addAnotherUser}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Add Another User"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button style={{
                border: '0.5px solid #555555',
                borderRadius: '5px',
                padding: '5px 30px',
                marginRight: '10px',
                cursor: 'pointer'
              }}
                      onClick={() => props.setIsUserClick(false)}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button style={{
                borderRadius: '5px',
                padding: '5px 30px',
                color: white,
                backgroundColor: primaryBlue,
                cursor: 'pointer'
              }}
                      onClick={handleAddUser}
              >
                Create User
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
      <AlertBar open={alertOpen} severity={severity} topic={topic} message={message}/>
      <Loader loading={loading}/>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
  },
  text: {
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif"
  },
  required: {
    color: "red",
  },
  label: {
    minWidth: 150,
    textAlign: 'left',
  },
  formInput: {
    height: "38px",
    borderRadius: "5px",
    padding: "10px 15px",
    fontFamily: "'Poppins', sans-serif",
    width: '100%',
    backgroundColor: 'white',
    border: '0.5px solid black',
    fontSize: '13px'
  },
  saveButton: {
    width: '100px',
    borderRadius: '8px',
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
  }
}));

