import * as React from "react";
import { Box, Button, createTheme, Grid, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import { DropDown } from "../../components/DropDown";
import useStyles from "src/app/maintenance/assets/styles";
import {useState} from 'react';
import { DocumentIcon } from "src/components/Icons";
import { DescriptionComponent } from "./Description";


const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function SelectAModelComponent({setFormValue,errors,setErrors}) {
  const classes = useStyles();
  const [selectedMOdel,setSelectedModel] = useState("")
  const [showDescription,setShowDescription] = useState(false)

  const models = ['model s','Xgboost regressior','Random forest']
 
  const handleSelectModel = (value) =>{
    setSelectedModel(value)
    setFormValue(prevValue =>({
      ...prevValue,
      "model":value
    }))
    setErrors(prevError => ({
      ...prevError,
      "model": null
    }))

  }
  const showDescriptionDialog = () =>{
    setShowDescription(true); 
  }
  const handleCloseWindow = () =>{
    setShowDescription(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>

      <Grid container className={classes.root}>
        <Grid container className={classes.formContent}>

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typo}>
                  Model
                  <span style={{ color: 'red' }}>*</span>
                </Typography> 
                <DropDown
                    options={models}
                    type='model'
                    emptyTag='Select Model'
                    setSelectOption={setSelectedModel}
                    value={selectedMOdel}
                    // handleError={handleError}
                    onClick= {handleSelectModel}
                    isSingle={true}
                    icon ={<DocumentIcon />}
                    iconClick = {showDescriptionDialog}
                  />
                  {errors.model &&
                    <Grid item xs={12} style={{ padding: "0px 8px" }}>
                      <Typography className={classes.errorText}>
                        {errors.model}
                      </Typography>
                    </Grid>
                  }
              </Grid>
            </Grid>
        </Grid>
      </Grid>
      {showDescription && 
        <DescriptionComponent 
          open={showDescription} 
          handleCloseWindow={handleCloseWindow}
        />

      }   
        
      </div>
    </ThemeProvider>
  );
}
