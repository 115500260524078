import { Box, Button, createTheme, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputBase, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, Tooltip, Typography } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AddIcon, Arrow, EditIcon, ExpandMoreIcon, NonSuccessCriteriaIcon, TestCaseIcon, TrashIcon, ViewIcon, CancelIcon, ExpandCollapseIcon } from 'src/components/Icons';
import { white } from 'src/components/colors';
import { DropDown } from '../maintenance/test-automation/components/DropDown';
import { getAllBatches, getAllMainTestCases } from '../testing-automation/api-helper/apiHelper';
import { Close } from '@material-ui/icons';
import { fetchMetaData, matchSensorNames } from '../maintenance/test-automation/components/apiHelper';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { Loader } from '../maintenance/test-automation/components/Loader';
import useStyles from '../maintenance/assets/styles';
import { ParameterDropDown } from '../maintenance/test-automation/step2/ParameterDropDown';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import { formatSuccessCriteria } from '../utils';

export const TestCasesComponent = () => {
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });
    const [batches,setBatches] = useState([]);
    const [hover, setHover] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState('');
    const [mainTestCases, setMainTestCases] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [loading, setLoading] = useState(false);
    const [isDeletePopupOpen,setIsDeletePopupOpen] = useState(false);
    const [selectedSubTestCase,setSelectedSubTestCase] = useState(null);
    const [selectedTestCase,setSelectedTestCase] = useState(null);
    const [isCreatePopupOpen,setIsCreatePopupOpen] = useState(false);
    const [isViewSubTest,setIsViewSubTest] = useState(false);
    const [sensors, setSensors] = useState([]);

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)

    const onHover = (id) => {
        setHover(id)
    }
    const removeHover = () => {
        setHover(null)
    }

    const handleExpandClick = (id) => {
        setExpandedRows(prev => ({ ...prev, [id]: !prev[id] }));
    };

    useEffect(() => {
        fetchMetaData();
    }, []);

    useEffect(() => {
        if (selectedTestCase) {
            console.log('Test case selected:', selectedTestCase);
        }
    }, [selectedTestCase]);

    useEffect (() => {
        const fetchAllBatches = async () => {
            try{
                const fetchedBatches = await getAllBatches();
                setBatches(fetchedBatches);
                console.log(fetchedBatches);
                if(fetchedBatches.length > 0){
                    setSelectedBatch(fetchedBatches[0]);
                    handleSelectBatchNumber(fetchedBatches[0]);
                }
            } catch (error) {
                console.log('Error fetching batch numbers:', error);
            }
        }
        fetchAllBatches();
    }, []);

    const fetchMainTestCases = async () => {
        setLoading(true);
        try {
            if (selectedBatch) {
                const fetchedMainTestCases = await getAllMainTestCases(selectedBatch);
                if (fetchedMainTestCases) {
                    const filteredTestCases = fetchedMainTestCases.data.filter(
                        (testCase) => testCase.default === false
                    );
                    setMainTestCases(filteredTestCases);
                    console.log('Main Test Cases:', filteredTestCases);
                }
            }
        } catch (error) {
            console.log('Error fetching main test cases:', error);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if (selectedBatch) {
            console.log('Selected Batch:', selectedBatch);
            fetchMainTestCases();
        }
    }, [selectedBatch]);

    const createSubTestCase = async (subTestCase) => {
        console.log('Creating sub test case:', subTestCase);
        setLoading(true);
        try {
            const response = await axios.post(`${environment.host}/core/deviceTest/testCase/${selectedTestCase.id}/subTestCase`, subTestCase, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')
                }
            });
            console.log('Sub test case created:', response.data);
            setIsToasterOpen(true);
            setToasterMessage('Sub Test Case Created Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);
            setIsCreatePopupOpen(false);
            await fetchMainTestCases();
        } catch (error) {
            console.error('Error creating sub test case:', error);
        } finally {
            setLoading(false);
        }
    };

    const deleteTestCase = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`${environment.host}/core/deviceTest/testCase/${selectedTestCase.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')
                }
            });
            console.log('Test case deleted:', response.data);
            setIsToasterOpen(true);
            setToasterMessage('Test Case Deleted Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);
            await fetchMainTestCases();
        } catch (error) {
            console.error('Error deleting test case:', error);
        } finally {
            setLoading(false);
        }
    };

    const deleteSubTestCase = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`${environment.host}/core/deviceTest/testCase/${selectedTestCase.id}/subTestCase/${selectedSubTestCase.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')
                }
            });
            console.log('Sub test case deleted:', response.data);
            setIsToasterOpen(true);
            setToasterMessage('Sub Test Case Deleted Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);
            await fetchMainTestCases();
        } catch (error) {
            console.error('Error deleting sub test case:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const getMetaData = async () => {
          const data = await fetchMetaData();
          setSensors(data || []);
        };
    
        getMetaData();
    }, []);

    const getSensorNameByCode = (code) => {
        const sensor = sensors.find((sensor) => sensor.code === code);
        return sensor ? sensor.name : 'Unknown Sensor';
    };

    
    const handleSelectBatchNumber = async (value) => {
        setSelectedBatch(value);
    };

    const handleDeleteIcon = (testCase) => {
        setSelectedTestCase(testCase);
        setSelectedSubTestCase(null);
        setIsDeletePopupOpen(true);
    };
    
    const handleSubTestDeleteIcon = (testCase, subTest) => {
        setSelectedTestCase(testCase);
        setSelectedSubTestCase(subTest);
        setIsDeletePopupOpen(true);
    };
    
    const cancelDeletePopup = () => {
        setIsDeletePopupOpen(false);
        setSelectedSubTestCase(null);
    };

    const handleViewPopup = (subTestCase) =>{
        setSelectedSubTestCase(subTestCase);
        setIsViewSubTest(true)
    }

    const handleCreatePopUp = (testCase) =>{
        setSelectedTestCase(testCase);
        setIsCreatePopupOpen(true)
    }

    const cancelCreatePopup = () =>{
        setIsCreatePopupOpen(false)
    }


    const cancelViewPopup = () =>{
        setIsViewSubTest(false)
    }


    return (
        <ThemeProvider theme={theme} >
            <Grid item md={12} xs={12} style={{padding:'25px'}}>
                <Grid container style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <Grid container style={{display: "flex", justifyContent :'flex-start', margin:'50px 0px',alignItems:'center',backgroundColor:'white',padding:'15px', width:"50%"}}>
                        <Grid item style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                            <NonSuccessCriteriaIcon />
                            <Link to={'/TestAutomation'} style={{paddingLeft: '10px', textDecoration: 'none'}}>
                            <Typography style={{color: '#858DAA'}}>Setup Device Pulse</Typography>
                            </Link>
                        </Grid>
                        <Arrow/>
                        <Grid item style={{color:'#2A7BEC',display:'flex',alignItems:'center'}}>
                            <TestCaseIcon />
                            <Typography style={{marginLeft:'10px'}}>Test Cases</Typography>
                        </Grid>      
                    </Grid>
                    <Grid container style={{display: 'flex', flexDirection:'row', width:'50%', justifyContent:'flex-end', alignItems:'center'}}>
                        <Grid item >
                        <Typography style={{fontSize:"15px", paddingRight:'15px'}}>
                            Batch 
                            <span style={{ color: 'red' }}>*</span>
                            :
                        </Typography> 
                        </Grid>
                        <Grid item >
                        <DropDown
                            options={batches}
                            type="batchNumber"
                            emptyTag="Select"
                            setSelectOption={handleSelectBatchNumber}
                            isSingle={true}
                            value={selectedBatch}
                        />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{width:'80%'}}>Test Case</TableCell>
                                <TableCell style={{width:'20%'}} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mainTestCases.length > 0 ? (
                                mainTestCases.map((testcase, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 1 ? '#ECEFF1' : '#FFFFFF' }}>
                                            <TableCell style={{ width: '80%' }}>{testcase.mainTestTitle}</TableCell>
                                            <TableCell style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Grid item style={{ position: 'relative', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                    {hover === testcase.id && (
                                                        <Typography
                                                            style={{
                                                                fontSize: '12px',
                                                                fontFamily: 'Poppins',
                                                                textAlign: 'center',
                                                                borderRadius: '3px',
                                                                backgroundColor: 'white',
                                                                color: '#5E5C5C',
                                                                minWidth: '200px',
                                                                position: 'absolute'
                                                            }}
                                                        >
                                                            Add New Sub Test Case
                                                        </Typography>
                                                    )}

                                                    <IconButton
                                                        size={'small'}
                                                        onMouseEnter={() => onHover(testcase.id)}
                                                        onMouseLeave={removeHover}
                                                        style={{ paddingRight: '10px' }}
                                                        onClick={() => handleCreatePopUp(testcase)}
                                                    >
                                                        <AddIcon fill="none" opacity={1} />
                                                    </IconButton>
                                                </Grid>
                                                <IconButton size={'small'} onClick={() =>handleDeleteIcon(testcase)}>
                                                    <TrashIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleExpandClick(testcase.id)}>
                                                    {expandedRows[testcase.id] ? <ExpandCollapseIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                        {expandedRows[testcase.id] && (
                                            <React.Fragment>
                                                {testcase.subTestCases.map((subTest, subIndex) => (
                                                    <TableRow key={`subTest-${subTest.id}-${subIndex}`}>
                                                        <TableCell style={{ width: '80%', paddingLeft: '50px', backgroundColor:'#E5E8EA' }}>
                                                            {subTest.subTestTitle}
                                                        </TableCell>
                                                        <TableCell style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor:'#E5E8EA', paddingRight:'55px'  }}>
                                                            <Grid item style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
                                                                <IconButton size={'small'} onClick={() => handleViewPopup(subTest)}>
                                                                    <ViewIcon />
                                                                </IconButton>
                                                                <IconButton size={'small'}>
                                                                    <EditIcon stroke={'#707070'} />
                                                                </IconButton>
                                                                <IconButton size={'small'} onClick={() => handleSubTestDeleteIcon(testcase, subTest)}>
                                                                    <TrashIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                        No Test Cases Available for The Selected Batch
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </Grid>
            </Grid>
            <DeletePopup 
                open={isDeletePopupOpen} 
                closePopup={cancelDeletePopup} 
                testCase={selectedTestCase} 
                subTestCase={selectedSubTestCase} 
                deleteTestCase={deleteTestCase} 
                deleteSubTestCase={deleteSubTestCase} 
            />
            <ViewPopup 
                open={isViewSubTest} 
                handleWindowClose={cancelViewPopup} 
                subTestCase={selectedSubTestCase} 
                getSensorNameByCode={getSensorNameByCode}
            />
            <CreatePopup 
                open={isCreatePopupOpen} 
                handleWindowClose={() => {cancelCreatePopup()}} 
                selectedBatch={selectedBatch}
                createSubTestCase={createSubTestCase}
            />
            <Loader loading={loading} />
            <ToasterComponent
                toaster={isToasterOpen}
                message={toasterMessage}
                reqSuccess={reqSuccess}
            />
        </ThemeProvider>
    );
};

export const DeletePopup = ({open,closePopup,testCase, subTestCase, deleteTestCase, deleteSubTestCase}) =>{
    if (!testCase) {
        return null;
    }
    // console.log('Test Case:', testCase);

    const handleDelete = () => {
        if (subTestCase) {
            deleteSubTestCase(); 
        } else {
            deleteTestCase();
        }
        closePopup();
    };
    return (
        <Dialog open={open} onClose={closePopup}>
            <Grid item style={{ display: 'flex', flexDirection: 'row', boxShadow: '0 0 15px 0 #00000014'  }}>
                <DialogTitle style={{ width: '90%'}}>Delete {subTestCase ? "Sub Test Case" : "Test Case"}</DialogTitle>
                <IconButton style={{ justifyContent: "flex-end", cursor: "pointer" }} onClick={closePopup}>
                    <CancelIcon />
                </IconButton>
            </Grid>
            <DialogContent>
                    <Box>
                        <Grid item style={{ marginBottom: '15px', padding: '10px' }}>
                            <Typography>
                                Are you sure you want to delete {subTestCase ? subTestCase.subTestTitle : testCase.mainTestTitle}?
                            </Typography>
                        </Grid>
                        <Grid container justifyContent='flex-end'>
                            <Grid item style={{ display: 'flex', justifyContent: 'space-between', width: '40%', marginBottom:'10px', gap:'10px' }}>
                                <Button variant='outlined' style={{ flexGrow: 1 }} onClick={closePopup}>Cancel</Button>
                                <Button variant='contained' style={{ backgroundColor: '#FF4343', color: 'white' }} onClick={handleDelete}>
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
            </DialogContent>
            
        </Dialog>
    );
};

export const ViewPopup = ({open,handleWindowClose, subTestCase, getSensorNameByCode }) => { 
    return(
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span>View Sub Test Case</span>
              <IconButton
                onClick={handleWindowClose}
                style={{ paddingRight: '0px' }}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
            <DialogContent >
                <Box sx={{minHeight:'135px'}}>
                    {subTestCase && (
                    <Grid container spacing={3} style={{display: 'flex', flexDirection:'row', overflowY:'hidden', padding:'15px'}} key={`subTest-${subTestCase.id}`}>
                        <Grid item style={{flexDirection:'column'}}>
                            <Typography>
                            Sub Test Case
                            </Typography>
                            <Typography style={{fontWeight:'bold', marginTop:'5px'}}>
                                {subTestCase.subTestTitle}
                            </Typography>
                        </Grid>
                        <Grid item style={{flexDirection:'column'}}>
                            <Typography>
                            Parameter
                            </Typography>
                            <Typography style={{fontWeight:'bold', marginTop:'5px'}}>
                                {getSensorNameByCode(subTestCase.sensorCode)}
                            </Typography>
                        </Grid>
                        <Grid item style={{flexDirection:'column'}}>
                            <Typography>
                            Success Criteria
                            </Typography>
                            <Typography style={{fontWeight:'bold', marginTop:'5px'}}>
                                {formatSuccessCriteria(subTestCase.successCriteria)}
                            </Typography>
                            <Typography style={{fontWeight:'bold', marginTop:'2px'}}>
                            {subTestCase.successCriteria.includes('between') 
                                        ? `${subTestCase.minVal} - ${subTestCase.maxVal}`
                                        : subTestCase.minVal
                                    }
                            </Typography>
                            
                        </Grid>
                    </Grid>
                    )}
                </Box>
          </DialogContent>
        </Dialog>
    )
}

export const CreatePopup = ({open,handleWindowClose, selectedBatch, createSubTestCase}) => {
    const classes = useStyles();
    const [parameters, setParameters] = useState([]);
    const [subTestCase, setSubTestCase] = useState({
        subTestTitle: '',
        sensorCode: '',
        successCriteria: '',
        criteriaValue: '',
        minValue: '',
        maxValue: ''
    });
    const [errors, setErrors] = useState({
        subTestTitleError: null,
        sensorCodeError: null,
        successCriteriaError: null,
        criteriaValueError: null,
        minValueError: null,
        maxValueError: null
    });
    const [successCriterias, setSuccessCriterias] = useState([
        "No of Data",
        "Greater than",
        "Greater than or equal to",
        "Less than",
        "Less than or equal to",
        "Is equal to",
        "Is not equal to",
        "Is between",
        "Is not between",
        "Average",
        "Increasing",
        "Decreasing"
    ]);

    useEffect(() => {
        const getParameters = async () => {
          try {
            const parameters = await matchSensorNames(selectedBatch);
            setParameters(parameters);
          } catch (err) {
            console.error('Error fetching parameters:', err);
          }
        };
        if (selectedBatch) {
          getParameters();
        }
    }, [selectedBatch]);

    const parameterOptions = parameters.map(param => ({
        label: param.name,
        value: param.code   
    }));

    const successCriteriaMap = {
        "No of Data": "NO_OF_DATA",
        "Greater than": "GREATER_THAN",
        "Greater than or equal to": "GREATER_THAN_OR_EQUAL_TO",
        "Less than": "LESS_THAN",
        "Less than or equal to": "LESS_THAN_OR_EQUAL_TO",
        "Is equal to": "IS_EQUAL_TO",
        "Is not equal to": "IS_NOT_EQUAL_TO",
        "Is between": "IS_BETWEEN",
        "Is not between": "IS_NOT_BETWEEN",
        "Average": "AVERAGE",
        "Increasing": "IS_INCREASING",
        "Decreasing": "IS_DECREASING",
    };

    const validationForm = () => {
        let error: any = {};
    
        if (subTestCase.subTestTitle === '') {
            error.subTestTitleError = 'Sub Test Case Title is required';
        } 
        if (subTestCase.sensorCode === '') {
            error.sensorCodeError = 'Parameter is required';
        }
        if (subTestCase.successCriteria === '') {
            error.successCriteriaError = 'Success scenario is required';
        }
        if (["No of Data", "Greater than", "Greater than or equal to", 
            "Less than", "Less than or equal to", "Is equal to", "Is not equal to", 
            "Average"].includes(subTestCase.successCriteria) && subTestCase.criteriaValue === '') {
            error.criteriaValueError = 'Value is required';
        } 
        else if (["No of Data", "Greater than", "Greater than or equal to", 
                  "Less than", "Less than or equal to", "Is equal to", "Is not equal to", 
                  "Average"].includes(subTestCase.successCriteria) && isNaN(Number(subTestCase.criteriaValue))) {
            error.criteriaValueError = 'Only Numeric Characters are Allowed';
        }
        if (["Is between", "Is not between", "Increasing", "Decreasing"]
            .includes(subTestCase.successCriteria)) {
            if (subTestCase.minValue === '') {
                error.minValueError = 'Min Value is required';
            } else if (isNaN(Number(subTestCase.minValue))) {
                error.minValueError = 'Only Numeric Characters are Allowed';
            }
            if (subTestCase.maxValue === '') {
                error.maxValueError = 'Max Value is required';
            } else if (isNaN(Number(subTestCase.maxValue))) {
                error.maxValueError = 'Only Numeric Characters are Allowed';
            }
        }
    
        setErrors(error);
        return error;
    };
    

    const resetFormFields = () =>  {
        setSubTestCase({
          subTestTitle: '',
          successCriteria: '',
          sensorCode: '',
          criteriaValue: '',
          minValue: '',
          maxValue: ''
        });
        setErrors({
            subTestTitleError: null,
            sensorCodeError: null,
            successCriteriaError: null,
            criteriaValueError: null,
            minValueError: null,
            maxValueError: null
        });
    }

    const handleSubTestCaseChange = ({ target: { name, value } }) => {
        setSubTestCase(prev => ({
            ...prev,
            [name]: value
        }));
        setErrors(prev => ({
            ...prev,
            [`${name}Error`]: null 
        }));
    };

    const handleParameterChange = (selectedSensor) => {
        setSubTestCase(prev => ({
            ...prev,
            sensorCode: selectedSensor
        }));
        setErrors(prev => ({
            ...prev,
            sensorCodeError: null
        }));
    };

    const handleSubmit = async () => {
        const validationError = validationForm();
        if (Object.keys(validationError).length > 0) {
            return;
        }
    
        let minVal = subTestCase.minValue;
        let maxVal = subTestCase.maxValue;
    
        if (["No of Data", "Greater than", "Greater than or equal to", 
            "Is equal to", "Is not equal to", "Average"].includes(subTestCase.successCriteria)) {
            minVal = subTestCase.criteriaValue;
            maxVal = subTestCase.criteriaValue;
        } else if (["Less than", "Less than or equal to"].includes(subTestCase.successCriteria)) {
            maxVal = subTestCase.criteriaValue;
        }
    
        const newSubTestCase = {
            subTestTitle: subTestCase.subTestTitle,
            sensorCode: subTestCase.sensorCode,
            successCriteria: successCriteriaMap[subTestCase.successCriteria],
            minVal: minVal,
            maxVal: maxVal
        };
    
        try {
            await createSubTestCase(newSubTestCase);
            resetFormFields();
        } catch (error) {
            console.error('Error creating sub test case:', error);
        }
    };
    



    return(
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span>Add Sub Test Case</span>
              <IconButton
                onClick={() => {
                    handleWindowClose();
                    resetFormFields();
                  }}
                style={{ paddingRight: '0px' }}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
          <Box sx={{minHeight:'370px'}}>
            <Grid container spacing={3} style={{ padding: '10px' }}>
                <Grid item xs={12} style={{ flexDirection: 'column', gap: '5px' }}>
                    <Typography>
                        Sub Test Case
                        <span style={{ color: 'red' }}> * </span>
                        :
                    </Typography>
                    <InputBase
                        className={classes.formInput}
                        type="text"
                        placeholder="Enter Sub Test Case Name Here"
                        name="subTestTitle"
                        value={subTestCase.subTestTitle}
                        onChange={handleSubTestCaseChange}
                    />
                    {errors.subTestTitleError && (
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                                {errors.subTestTitleError}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} style={{ flexDirection: 'column' }}>
                    <Typography>
                        Parameter
                        <span style={{ color: 'red' }}> * </span>
                        :
                    </Typography>
                    <ParameterDropDown
                        options={parameterOptions}
                        type='parameter'
                        emptyTag='Select Parameter'
                        setSelectOption={handleParameterChange}
                        value={subTestCase.sensorCode}
                        isSingle={true}
                    />
                    {errors.sensorCodeError && (
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                                {errors.sensorCodeError}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} style={{ flexDirection: 'column' }}>
                    <Typography>
                        Success Criteria
                        <span style={{ color: 'red' }}> * </span>
                        :
                    </Typography>
                    <DropDown
                        options={successCriterias}
                        type='successCriteria'
                        emptyTag='Select Success Criteria'
                        setSelectOption={(value) =>
                            handleSubTestCaseChange({ target: { name: 'successCriteria', value } })
                        }
                        value={subTestCase.successCriteria}
                        isSingle={true}
                    />
                    {errors.successCriteriaError && (
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                                {errors.successCriteriaError}
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                    {["No of Data", "Greater than", "Greater than or equal to", "Less than", "Less than or equal to", "Is equal to", "Is not equal to", "Average"].includes(subTestCase.successCriteria) && (
                        <Grid item xs={12}>
                            <Typography>
                                Criteria Value
                                <span style={{ color: 'red' }}> * </span>
                                :
                        </Typography>
                            <InputBase
                                className={classes.formInput}
                                type="text"
                                placeholder="Enter Value Here"
                                name="criteriaValue"
                                value={subTestCase.criteriaValue}
                                onChange={handleSubTestCaseChange}
                            />
                            {errors.criteriaValueError && (
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.errorText}>
                                        {errors.criteriaValueError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    )}

                    {["Is between", "Is not between", "Increasing", "Decreasing"].includes(subTestCase.successCriteria) && (
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <Typography>
                                    Min Value
                                    <span style={{ color: 'red' }}> * </span>
                                    :
                                </Typography>
                                <InputBase
                                    className={classes.formInput}
                                    type="text"
                                    placeholder="Min Value"
                                    name="minValue"
                                    value={subTestCase.minValue}
                                    onChange={handleSubTestCaseChange}
                                />
                                {errors.minValueError && (
                                    <Grid container justifyContent="flex-end" alignItems="center">
                                        <Typography className={classes.errorText}>
                                            {errors.minValueError}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    Max Value
                                    <span style={{ color: 'red' }}> * </span>
                                    :
                                </Typography>
                                    <InputBase
                                        className={classes.formInput}
                                        type="text"
                                        placeholder="Max Value"
                                        name="maxValue"
                                        value={subTestCase.maxValue}
                                        onChange={handleSubTestCaseChange}
                                    />
                                    {errors.maxValueError && (
                                        <Grid container justifyContent="flex-end" alignItems="center">
                                            <Typography className={classes.errorText}>
                                                {errors.maxValueError}
                                            </Typography>
                                        </Grid>
                                    )}
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={2} style={{ marginTop: "20px" }} justifyContent="flex-end">
                        <Grid item>
                        <Button
                            variant="contained"
                            className={`${classes.backButton} ${classes.button}`}
                            onClick={() => {
                                handleWindowClose();
                                resetFormFields();
                            }}
                        >
                            Back
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button
                            variant="contained"
                            className={`${classes.getStarted} ${classes.button}`}
                            onClick={handleSubmit}
                        >
                            Add 
                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
          </DialogContent>
        </Dialog>
    )
}
