import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import * as React from 'react'

interface SelectAModelComponentProps {
    open: boolean;
    handleCloseWindow: any;
  }

export const DescriptionComponent :React.FC<SelectAModelComponentProps> = ({ open,handleCloseWindow }) => {

    return (
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <span>Description</span>
                    <IconButton
                        onClick={handleCloseWindow}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent style = {{color:'#5E5C5C'}}>
                <Typography  style={{padding:'20px 0'}}>
                    Lorem ipsum dolor sit amet consectetur. Fames fermentum pulvinar quam lorem ullamcorper.
                    Massa sit nisl vulputate enim eleifend aliquet. Curabitur faucibus diam vulputate molestie velit mi amet ultricies quis. Aliquet rutrum ac fames diam donec in volutpat eget consectetur.
                    Adipiscing et porta felis sed arcu. Sagittis elit vitae est neque phasellus turpis ut pulvinar in.
                    Lorem ipsum dolor sit amet consectetur. Fames fermentum pulvinar quam lorem ullamcorper.
                </Typography>
            </DialogContent>
        </Dialog>
    )
}
