import * as React from "react";
import { Button, createTheme, Grid, Snackbar, ThemeProvider, Typography } from "@material-ui/core";
import useStyles from "../assets/styles";
import { useState, useEffect } from "react";
import {useHistory} from 'react-router-dom';
import { lightGray, primaryBlue, primaryGray } from "src/components/colors";
import { DropDown } from "./DropDown";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { Step3 } from "./step3";
import axios from "axios";
import { environment } from "src/environments/environment";
import { Alert } from "@material-ui/lab";


const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const STEPS = [
  {
    text: "Configure Devices",
    activate: true,
  },
  {
    text: "Network & Communication",
    activate: false,
  },
  {
    text: "Topic Format, Message, Format & Interval",
    activate: false,
  },
];

export function RemoteManagerComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [steps, setSteps] = useState(STEPS);
  const [step, setStep] = useState(0);
  const [selectedVersion,setSelectedVersion]=useState('');
  const [selectedDeviceIds,setSelectedDeviceIds]=useState([]);
  const [selectedProductType,setSelectedProductType]=useState({
    productName:'',
    versionNum:'',
    deviceParameterConfiguration:{remoteConfigurations:[]}
  });
  const [detailsArray,setDetailsArray]=useState([]);
  const [message, setMessage] = useState('');
const [toaster, setToaster] = useState(false);
const [msgSuccess, setMsgSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getRemoteConfiguration=async()=>{
      if (step === 1){
        try{
        if (selectedDeviceIds.length==1){
          
         await setDeviceConfig(selectedDeviceIds[0]);

        }
        else{
          if(selectedProductType.deviceParameterConfiguration.remoteConfigurations){
          setProductTypeConfigparams(selectedProductType.deviceParameterConfiguration.remoteConfigurations);}
          else{
            setToaster(true);
            setMessage("cannot find remote configuration");
            setMsgSuccess(false);
            setTimeout(() => {
              setToaster(false);
            }, 4000);

          }
        }}
        catch(err){}
          finally{}

        }
    }
    getRemoteConfiguration();
    updateStepActivation(step);
  }, [step]);

  function updateStepActivation(currentStep) {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      activate: index === currentStep,
    }));
    setSteps(updatedSteps);
  }

  const handleCloseToaster = () => {
    setToaster(false);
  };


  async function setDeviceConfig(deviceId){
   await  axios.get(`${environment.host}/core/device/deviceParameterConfiguration`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content !== undefined) {
            response.data.content.forEach((device) => {
            if (device.id==deviceId){
              let config=device.deviceParameterConfiguration.remoteConfigurations;
              setDetailsArray(config);
            }
          })
        }
      })
      .catch((err) => {
        console.error('Failed to fetch device Parameter configuration:', err);
        setToaster(true);
        setMessage("Failed to fetch device Parameter configuration");
        setMsgSuccess(false);
        setTimeout(() => {
          setToaster(false);
        }, 2000);
      });
   return [];
  }
  function setProductTypeConfigparams(productTypeRemoteConfig){
   setDetailsArray(productTypeRemoteConfig.map(({...item}) => ({
    ...item,
    defaultValue:''
  })),)
  }
  function renderStepsComponent() {
 
      if (step === 1) {
          return <Step2 detailsArray={detailsArray} setDetailsArray={setDetailsArray} />;
        } else if(step === 2) {
          return <Step3 detailsArray={detailsArray} setDetailsArray={setDetailsArray} />;
        } else{
          return <Step1 selectedVersion={selectedVersion} 
                        setSelectedVersion={setSelectedVersion} 
                        selectedDeviceIds={selectedDeviceIds} 
                        setSelectedDeviceIds={setSelectedDeviceIds}
                        setSelectedProductType={setSelectedProductType} 
                        selectedProductType={selectedProductType}
                        setproductTypeRemoteConfigs={setProductTypeConfigparams}/>;}
      
    }
  

  function increaseStep() {
    setStep(prevStep => 
      Math.min(prevStep + 1, STEPS.length - 1)
    );
  }

  function decreaseStep() {
    if (step === 0) {
      // resetFormFields();
      history.push(`/`)
    } else {
      setStep(prevStep => 
        Math.max(prevStep - 1, 0)
      );
    }
  }

  function handleSubmit() {
    const {versionNum} =selectedProductType;
    const productType=selectedProductType.productName;
    const uId = localStorage.getItem("USERNAME");



    if (selectedDeviceIds.length==1){
    const data = {
      productType,
      versionNum,
      remoteConfigurations: detailsArray.filter(item => item.checkUpdate)  
      .map(({ checkUpdate, ...item }) => ({
        ...item,
        defaultValue: item.defaultValue,
      })),
    }
    setLoading(true);
     
    axios.put(`${environment.host}/core/device/deviceParameterConfiguration/${selectedDeviceIds[0]}/user/${uId}`, data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if ( response.data.message==="Success"  && response.data.content!='Failure'){
          setToaster(true);
          setMessage(response ? response.data.content :'');
          setMsgSuccess(true);
          setTimeout(() => {
            setToaster(false);
            history.push('/')
          }, 2000);
        }
        else {
          setToaster(true);
          setMessage('Error configuring devices');
          setMsgSuccess(false);
          setTimeout(() => {
            setToaster(false);
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });}
      else{
        const dataBulk =[];
         selectedDeviceIds.forEach(device=>{
          dataBulk.push({device,
                    productType,
                    versionNum,
                    "remoteConfigurations": detailsArray
                    .filter(item => item.checkUpdate)  
                    .map(({ checkUpdate, ...item }) => ({
                      ...item,
                      defaultValue: item.defaultValue,
                    })),})
          })
         
        axios.put(`${environment.host}/core/device/deviceParameterConfiguration/bulk/user/${uId}`, dataBulk,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
            },
          })
          .then((response) => {
            setLoading(false);
            
            if ( response.data.message==="Success" && response.data.content!='Failure'){
              setToaster(true);
              setMessage(response ? response.data.content :'');
              setMsgSuccess(true);
              setTimeout(() => {
                setToaster(false);
                history.push('/')
              }, 2000);
            }
            else {
              setToaster(true);
              setMessage('Error configuring devices');
              setMsgSuccess(false);
              setTimeout(() => {
                setToaster(false);
              })
            }
          }
            
      )
          .catch((error) => {
            console.error(error);
          });
      }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <Grid container alignItems="center" >
          <Grid item xs={12} md={12} style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "40px" }}>
            <h4>Remote Manager</h4>
          </Grid>
        </Grid>

        <Grid container style={{textAlign: "center",paddingRight: "80px"}}>
        <Grid item xs={1} md={4}></Grid>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2} md={1}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Circle number={index + 1} active={step.activate}/>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "12px",
                      paddingTop: "15px",
                      fontFamily: "Poppins, sans-serif",
                      color: step.activate ? primaryBlue : primaryGray,
                    }}
                  >
                    {step.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {index !== steps.length - 1 && (
              <Grid item xs={2} md={1}>
                <hr
                  style={{
                    marginTop: "32px",
                    opacity: steps[index + 1].activate ? "1" : "0.12",
                  }}
                  color={
                    steps[index + 1].activate ? primaryBlue : "rgba(112, 112, 112, .12)"
                  }
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid item xs={12} md={12} style={{ marginTop: "20px" }}>
        {renderStepsComponent()}
      </Grid>
        
      <Grid container className={classes.root} justifyContent="flex-end" spacing={2} 
        style={{padding:"0px 440px", margin: "30px 0px" 
        }}>
              {step !== 3 && (
                <Button
                  onClick={decreaseStep}
                  variant="contained"
                  className={classes.button}
                  style={{ backgroundColor: lightGray, marginRight: '12px' }}
                >
                  {step === 1 || step === 2 ? "Back" : "Cancel"}
                </Button>
              )}
              <Button
                onClick={step === 2 ? handleSubmit : increaseStep}
                variant="contained"
                className={`${classes.getStarted} ${classes.button}`}
              >
                {step === 0 || step === 1 ? "Next" : "Done"}
              </Button>
        </Grid>
        <Snackbar
        open={toaster}
        autoHideDuration={6000}
        onClose={handleCloseToaster}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          onClose={handleCloseToaster}
          severity={msgSuccess === true ? "success" : "error"}
        >
          {message}
        </Alert>
      </Snackbar>
      </div>
    </ThemeProvider>
  );
}

const Circle = (props) => {
  const paddedNumber = (props.number).toString().padStart(2, '0');
  return (
    <div>
      <div
        style={{
          borderRadius: "50%",
          width: "65px",
          height: "65px",
          backgroundColor: "white",
          border: props.active ? "2px solid #2A7CED" : "0.25px solid #8F8F8F",
          textAlign: "center",
          paddingTop: "10px",
          boxShadow: "0px 4px 8px #0000001F",
        }}
      >
        <h5 style={{
          paddingTop: "10px",
          fontSize: "16px",
          color: props.active ? primaryBlue : primaryGray
        }}>
          {paddedNumber}
        </h5>
      </div>
    </div>
  );
};