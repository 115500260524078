import * as React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Tab, Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Tabs, Typography,TabClassKey, Paper} from '@material-ui/core';
import { useEffect, useState } from 'react';
import useStyles from '../../assets/styles';
import { ActionIcon } from 'src/components/Icons';
import { getSensorReading, getSuccessCriteria } from '../components/apiHelper';
import {DateAndSummeryReport} from './SummaryGraph'
import { Loader } from '../components/Loader';


// Define the type for the data objects
interface TestDataEntry {
  StartTime: string;
  EndTime: string;
  deviceId: string;
  [key: string]: any; 
}

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const dataRestructure = (testSummaryData, mainTestTitle) => {
  return testSummaryData.map(deviceData => {
    const result = {};
    for (const deviceId in deviceData) {
        result[deviceId] = { [mainTestTitle]: {} };
      for (const caseId in deviceData[deviceId]) {
        for (const testName in deviceData[deviceId][caseId]) {
          if (!result[deviceId][mainTestTitle][testName]) {
            result[deviceId][mainTestTitle][testName] = [];
          }
          result[deviceId][mainTestTitle][testName].push(...deviceData[deviceId][caseId][testName]);
        }
      }
    }
    return result;
  });
}

export const DataTable = ({ 
  selectedMainTestCaseTitle, 
  testData,
  selectedTestCaseType,
  loadingClose,
  testCaseType,
  selectedMainTestCaseId
}) => {
  const classes = useStyles();
  const [tableHeadData, setTableHeadData] = useState<string[]>([]);
  const [deviceData, setDeviceData] = useState<TestDataEntry[]>([]);
  const [isActionClick,setIsActionClick] = useState(false);
  const [subTasks,setSubTasks] = useState([])
  const [loading,setLoading] = useState(false)
  const [sensorReadingSummary,setSensorReadingSummary] = useState([])
  
  
  const [hasData, setHasData] = useState<boolean>(false);

  const tableStructure = () => {
    var deviceDataMap = {};
    var testSummary = dataRestructure(testData, selectedMainTestCaseTitle);

    Object.entries(testSummary).forEach(([device, mainTestCase]) => {
      Object.entries(mainTestCase).forEach(([deviceId, mainTestArr]) => {
        deviceDataMap[deviceId] = [];
        Object.entries(mainTestArr).forEach(([main, subArr]) => {
          var result = [];

          Object.keys(subArr).forEach((key) => {
            Object.keys(subArr[key]).forEach((testKey) => {
              var index = result.findIndex(
                (obj) =>
                  obj.StartTime === subArr[key][testKey]["subTestCaseStartTime"] &&
                  obj.EndTime === subArr[key][testKey]["subTestCaseEndTime"] &&
                  main === subArr[key][testKey]["mainTestTitle"]
              );
              let failureCount = subArr[key][testKey]["failedCount"];
              if (index !== -1) {
                if(failureCount && failureCount == -1) {
                  result[index][subArr[key][testKey]["subTestTitle"]] = 'No sensor reading'
                }else{
                  result[index][subArr[key][testKey]["subTestTitle"]] =
                subArr[key][testKey]["testResult"] == 'FAILURE' ? subArr[key][testKey]["testResult"] + `(${failureCount})` : subArr[key][testKey]["testResult"];
                }
                
              } else {
                var temp: TestDataEntry = {
                  StartTime: subArr[key][testKey]["subTestCaseStartTime"],
                  EndTime: subArr[key][testKey]["subTestCaseEndTime"],
                  deviceId: subArr[key][testKey]["deviceId"]
                };
                if(failureCount && failureCount == -1) {
                  temp[subArr[key][testKey]["subTestTitle"]] = 'No sensor reading'
                }else{
                  temp[subArr[key][testKey]["subTestTitle"]] =
                  subArr[key][testKey]["testResult"] == 'FAILURE' ? subArr[key][testKey]["testResult"] + `(${failureCount})` : subArr[key][testKey]["testResult"];
                }
                result.push(temp);
              }
            });
          });

          deviceDataMap[deviceId][main] = result;
        });
      });
    });

    return deviceDataMap;
  };

  const handleActionButton = async (id,startTime,endTime) =>{
    try{
      setLoading(true)
      const data ={
        "deviceId":id ,  
        "startDate" : startTime , 
        "endDate" : endTime , 
        "testCaseType" : testCaseType , 
        "mainTestCaseTitle" : selectedMainTestCaseTitle
      }
      await getSensorsReading(data)
      setIsActionClick(true)
    }catch(error){
      console.log("Error fetching sensor readings:", error)
    }
    
  }

  const handleCloseWindow = () =>{
    setIsActionClick(false)
  }

  
    const getSensorsReading = async (data) =>{
      try{

        //Get Sensors Reading Summary
        const sensorReading = await getSensorReading(data)
        let sensorSummary = {"subTestCase":"","sensorReadings":[],"dateTime":[]}
        let sensorReadings = []

        sensorReading.map(sensors =>{
          let sensorReadingValue = []
          let dateAndTime = []

          sensors.sensorReadings.forEach(value => {
            sensorReadingValue.push(value.value);
            dateAndTime.push(value.time);
          });

          sensorSummary = {
            subTestCase: sensors.subTestCaseTitle,
            sensorReadings: sensorReadingValue,
            dateTime: dateAndTime
          };
          sensorReadings.push({...sensorSummary});
        })

        //Get Min Max value of Sensor
        const response = await getSuccessCriteria(selectedMainTestCaseId);
        let sensorRange = []
        response.subTestCases.map(test => {
          let value = {
            maxVal: test.maxVal,
            minVal: test.minVal,
            title: test.subTestTitle,
            successCriteria: test.successCriteria
          };
          sensorRange.push(value);
        });
        let combinedData: any[] = [];

        if (response && sensorRange.length !== 0 && sensorReadings.length !== 0) {
          sensorReadings.forEach(sensorReading => {
            let matchingTest = sensorRange.find(test => test.title === sensorReading.subTestCase);

            if (matchingTest) {
              // Combine the data from both sensorReadings and sensorRange
              combinedData.push({
                ...sensorReading,
                maxVal: matchingTest.maxVal,
                minVal: matchingTest.minVal,
                successCriteria: matchingTest.successCriteria
              });
            }
          });
        }
        combinedData.sort((a, b) => {
          if (a.subTestCase.toLowerCase() < b.subTestCase.toLowerCase()) return -1;
          if (a.subTestCase.toLowerCase() > b.subTestCase.toLowerCase()) return 1;
          return 0;
        });
        setSensorReadingSummary(combinedData)
        
      }catch (error){
        console.log('Error',error)
      };
      setLoading(false)
    }

    useEffect(() => {
    if (selectedMainTestCaseTitle !== "") {
      const reorganizedData = dataRestructure(testData, selectedMainTestCaseTitle);
      const deviceSet = tableStructure();

      let dataArray: TestDataEntry[] = [];
      reorganizedData.forEach((device) => {
        const deviceId = Object.keys(device)[0];
        const dataObj = device[deviceId][selectedMainTestCaseTitle];
        if (dataObj) {
          dataObj["deviceId"] = deviceId;
        }
        dataArray.push(dataObj);
      });

      var temp: TestDataEntry[] = [];
      Object.values(deviceSet).forEach((device) => {
        Object.values(device).forEach((test) => {
          test.forEach((entry: TestDataEntry) => {
            temp.push(entry);
          });
        });
      });

      // Sort temp array by EndTime in descending order
      temp.sort((a: TestDataEntry, b: TestDataEntry) => {
        const endTimeA = new Date(a.EndTime);
        const endTimeB = new Date(b.EndTime);
        return endTimeB.getTime() - endTimeA.getTime();
      });

      if (dataArray[0]) {
        var tableHeaders = Object.keys(dataArray[0]).filter(header => header !== 'deviceId').sort();
        setSubTasks(Object.keys(dataArray[0]).filter(header => header !== 'deviceId').sort())
        tableHeaders.unshift("deviceId");
        tableHeaders.push("Action");

        if (selectedTestCaseType !== "Continuous Testing") {
          tableHeaders.push("StartTime");
          tableHeaders.push("EndTime");
        }

        setTableHeadData(tableHeaders);
        setDeviceData(temp);
        setHasData(temp.length > 0);
      } else {
        setHasData(false);
      }
    } else {
      setHasData(false);
    }
    loadingClose();
  }, [testData, selectedMainTestCaseTitle, selectedTestCaseType]);

  return (
    <div>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table" >
          {hasData && tableHeadData.length > 0 && (
            <TableHead>
              <TableRow>
                {tableHeadData.map((topic, i) => (
                  <TableCell 
                    align={topic === "deviceId" ? "left" : "center"}
                    key={i}
                  >
                    <Typography className={classes.topicText}>
                      {topic === "deviceId" ? "Device Id" : topic}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {hasData ? (
              deviceData.map((test, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "#ECEDEF" : "white",
                  }}
                >
                  {tableHeadData.map((head, i) => (
                    <TableCell
                      align={head === "deviceId" ? "left" : "center"}
                      key={i}
                      style={{
                        color:
                          test[head] === "SUCCESS"
                            ? "#22C674"
                            : typeof test[head] === "string" && (test[head].includes("FAILURE") || test[head] == 'No sensor reading')
                              ? "#FA5246"
                              : "black",
                      }}
                    >
                      {/* {head == "Action" && <ActionGraphIcon />} */}
                      <Typography className={classes.contentText}>
                        {head === "Action" ? (
                          <IconButton
                            onClick={() => handleActionButton(test.deviceId,test.StartTime,test.EndTime)}
                            size="small"
                          >
                            <ActionIcon />
                          </IconButton>
                        ) : (
                          test[head] || (head === "deviceId" ? test["deviceId"] : "NOT_APPLICABLE")
                        )}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={tableHeadData.length}>
                  <Typography className={classes.contentText}>
                    No Data Available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isActionClick &&
          <DateAndSummeryReport 
          isClick={isActionClick} 
          handleWindowClose={handleCloseWindow} 
          headers={subTasks} 
          data={sensorReadingSummary}
        />
      }
      <Loader loading={loading} />
    </div>
  );
};
