export const environment = {
  production: false,
  client_id: 'senzagro-client',
  client_secret: 'senzagro-secret',
  autoMl:"",
  enableUserLog: true,
  host: 'https://agro.senzmate.com/dev',
  testHost: 'http://polar.senzmatica.com/service',

  firebase: {
    apiKey: 'AIzaSyA9uKFEIqgW63abTdvYTqb3gS6r-60FRpI',
    authDomain: 'senzagro-c3d33.firebaseapp.com',
    databaseURL: 'https://senzagro-c3d33.firebaseio.com',
    projectId: 'senzagro-c3d33',
    storageBucket: 'senzagro-c3d33.appspot.com',
    messagingSenderId: '283658892245',
    appId: '1:283658892245:web:e206dc51d4a2719e75c35c',
    measurementId: 'G-E5NKHDMS7N'
  }
};
