import * as React from 'react';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Grid, InputBase,Button,FormGroup, FormControlLabel, Checkbox, Typography} from '@material-ui/core';
import {lightGray} from 'src/components/colors';
import {ThemeProvider, createTheme} from '@material-ui/core/styles';
import { DropDown} from '../components/DropDown';
import useStyles from '../../assets/styles';
import { SubTestCases } from './SubTestCases';
import { Loader } from '../components/Loader';
import { ToasterComponent } from '../components/Toaster';
import { 
  fetchBatchNumbers,
  defineTestCase 
} from '../components/apiHelper'; 

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function DefineTestCasesComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [selectedStreamingMethod, setSelectedStreamingMethod] = useState('');
  const [streamingMethods, setStreamingMethods] = useState([
    "SenzMatica", "MQTT", "AWS", "Rest API", "Azure IoT"
  ]);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [mainTestCase, setMainTestCase] = useState('');
  const [description, setDescription] = useState('');
  const [subTestCases, setSubTestCases] = useState([{
    subTestCaseName: '',
    successCriteria: '',
    parameter: '',
    criteriaValue: '',
    minValue: '',
    maxValue: ''
  }]);
  const [addAnotherTestCase, setAddAnotherTestCase] = useState(false);
  const [subTestCaseErrors, setSubTestCaseErrors] = useState([]);

  //Toaster
  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  //handel errors
  const [errors, setErrors] = useState({
    streamNameError: null,
    batchNoError: null,
    mainTestNameError: null,
    subTestCasesError: null,
    subTestCaseNameError: null,
    duplicateNameError:null,
    parameterError: null,
    successCriteriaError: null,
    criteriaValueError: null,
    minValueError: null,
    maxValueError: null,
  })

  useEffect(() => {
    const getBatchNumbers = async () => {
      if (selectedStreamingMethod) {
        try {
          const batchNumbers = await fetchBatchNumbers();
          setBatchNumbers(batchNumbers);

          if(batchNumbers.length > 0){
            setSelectedBatch(batchNumbers[0]);
            handleSelectBatchNumber(batchNumbers[0]);
          }
        } catch (err) {
          console.error('Error fetching batch numbers:', err);
        }
      }
    };
    getBatchNumbers();
  }, [selectedStreamingMethod]); 

  const streamingMethodsMap = {
    "SenzMatica":"SENZMATE", 
    "MQTT":"MQTT",
    "AWS":"AWS", 
    "Rest API":"RESTAPI", 
    "Azure IoT":"AZUREIOT"
  };
  
  const successCriteriaMap = {
    "No of Data": "NO_OF_DATA",
    "Greater than": "GREATER_THAN",
    "Greater than or equal to": "GREATER_THAN_OR_EQUAL_TO",
    "Less than": "LESS_THAN",
    "Less than or equal to": "LESS_THAN_OR_EQUAL_TO",
    "Is equal to": "IS_EQUAL_TO",
    "Is not equal to": "IS_NOT_EQUAL_TO",
    "Is between": "IS_BETWEEN",
    "Is not between": "IS_NOT_BETWEEN",
    "Average": "AVERAGE",
    "Increasing": "IS_INCREASING",
    "Decreasing": "IS_DECREASING",
  };

  const validationForm = () => {
    let error: any = {}
    if (selectedStreamingMethod== "") {
      error.streamNameError = "Data Streaming Method is required"
    }
    if (selectedBatch == "") {
      error.batchNoError = "Batch is required"
    }
    if (mainTestCase == "") {
      error.mainTestNameError = "Main Test Case Name is required"
    }

    {/*To do - duplicate name validation*/}
    const subTestCaseNames = subTestCases.map(testCase => testCase.subTestCaseName.trim());
    const duplicateIndices = subTestCaseNames
      .map((name, index) => subTestCaseNames.indexOf(name) !== index && name !== '' ? index : null)
      .filter(index => index !== null);
  
    const newSubTestCaseErrors = subTestCases.map((subTestCase, index) => {
      if (subTestCase.subTestCaseName === "") {
        error.subTestCaseNameError = "Sub Test Case Name is required";
      } 
      else if (duplicateIndices.includes(index)) {
        error.subTestCaseNameError = "Sub-test case name is already in use. Please enter another name.";
      }

      if (subTestCase.parameter === "") {
        error.parameterError = "Parameter is Required";
      }
      if (subTestCase.successCriteria === "") {
       error.successCriteriaError = "Success scenario is required";
      }
      if ([ "No of Data","Greater than", "Greater than or equal to", 
            "Less than", "Less than or equal to", 
            "Is equal to", "Is not equal to", 
            "Average"
      ].includes(subTestCase.successCriteria) && subTestCase.criteriaValue === "") {
        error.criteriaValueError = "Value is required";
      } 
      else if (["No of Data","Greater than", 
                "Greater than or equal to", 
                "Less than", "Less than or equal to", 
                "Is equal to", "Is not equal to", 
                "Average"
      ].includes(subTestCase.successCriteria) && isNaN(Number(subTestCase.criteriaValue))) {
        error.criteriaValueError = "Only Numeric Characters are Allowed";
      }
      if (["Is between", "Is not between", 
        "Increasing", "Decreasing"
      ].includes(subTestCase.successCriteria)) {
        if (subTestCase.minValue === "") {
          error.minValueError = "Min Value is required";
        } else if (isNaN(Number(subTestCase.minValue))) {
          error.minValueError = "Only Numeric Characters are Allowed";
        }
        if (subTestCase.maxValue === "") {
          error.maxValueError = "Max Value is required";
        } else if (isNaN(Number(subTestCase.maxValue))) {
          error.maxValueError = "Only Numeric Characters are Allowed";
        }
      }
      return error;
    });
  
    setSubTestCaseErrors(newSubTestCaseErrors);  
    return error;
  }  

  const handleError = (name) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  }

  const onBatchDropClick = () => {
    if (selectedStreamingMethod === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        streamNameError: "Data Streaming Method is Required"
      }));
    }
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    
    if (name === 'mainTestCaseName') {
        if (selectedBatch === '') {
          setErrors(prevErrors => ({
            ...prevErrors,
            batchNoError: "Batch is required"
          }));
          return;
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          batchNoError: null
        }));
      setMainTestCase(value);
      handleError('mainTestNameError')
    } 

    if (name === 'description') {
      setDescription(event.target.value);
    }     
  };
  
  const handleSelectStreamingMethod = (value) => {
    setSelectedStreamingMethod(value);
    handleError('streamNameError');
  };

  const handleSelectBatchNumber = async (value) => {
    if (selectedStreamingMethod) {
      setErrors(prevErrors => ({
        ...prevErrors,
        streamNameError: null
      }));
    }
    setSelectedBatch(value);
    handleError('batchNoError');
  };

  const handleCheckboxChange = (event) => {
    setAddAnotherTestCase(event.target.checked);
  };

  const resetFormFeilds = () =>  {
    setSelectedStreamingMethod('');
    setSelectedBatch('');
    setMainTestCase('');
    setDescription('');
    setAddAnotherTestCase(false);
    setSubTestCases([{
      subTestCaseName: '',
      successCriteria: '',
      parameter: '',
      criteriaValue: '',
      minValue: '',
      maxValue: ''
    }]);
  }

  const handleBack = () => {
    resetFormFeilds();
    history.push(`/TestAutomation`);
  }

  const handleSubmit = async () => {
    const validationError = validationForm()
    setErrors(validationError)
    if (Object.keys(validationError).length > 0) {
      return
    }
    const testCase = {
      batchNumber: selectedBatch,
      mainTestTitle: mainTestCase.trim(),
      subTestCases: subTestCases.map(subTestCase => {
        let minVal = subTestCase.minValue;
        let maxVal = subTestCase.maxValue;
  
        if (["No of Data","Greater than", "Greater than or equal to", "Is equal to", "Is not equal to", "Average"]
          .includes(subTestCase.successCriteria)) {
              minVal = subTestCase.criteriaValue;
              maxVal = subTestCase.criteriaValue;
        } 
        else if (["Less than", "Less than or equal to"]
          .includes(subTestCase.successCriteria)) {
              maxVal = subTestCase.criteriaValue;
        }
        return {
          subTestTitle: subTestCase.subTestCaseName.trim(),
          minVal,
          maxVal,
          successCriteria: successCriteriaMap[subTestCase.successCriteria],
          sensorCode: subTestCase.parameter,
        };
      }),
      dataStreamingMethod: streamingMethodsMap[selectedStreamingMethod],
      description: description,
    };
    try {
      await defineTestCase(
          testCase, 
          setLoading, 
          setIsToasterOpen, 
          setToasterMessage, 
          setReqSuccess,
          history,
          addAnotherTestCase
      );
    } catch (error) {
      console.error('Error submitting test case:', error);

    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="testing">
        <div className="page-heading" style={{ marginBottom: "50px" }}>
          Step 02
        </div>
  
        <Grid container alignItems="center">
          <Grid item xs={12} md={12} style={{ textAlign: "center", paddingBottom: "40px" }}>
            <h4>Define Test Cases</h4>
          </Grid>
        </Grid>
  
        <Grid container className={classes.root}>
          <Grid item md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Grid container className={classes.formContent}>
  
              {/* Data Streaming Method */}
              <Grid item container xs={12} md={6} alignItems="center" spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.typo}>
                    Data Streaming Method: 
                    <span style={{ color: 'red' }}>*</span>
                  </Typography> 
                </Grid>
                <Grid item xs={12} md={6}>
                  <DropDown
                    options={streamingMethods}
                    type="streamingMethod"
                    emptyTag="-Select-"
                    setSelectOption={handleSelectStreamingMethod}
                    value={selectedStreamingMethod}
                    handleError={handleError}
                    isSingle={true}
                  />
                </Grid>
                {errors.streamNameError && (
                  <Grid item container xs={12} md={12} justifyContent="flex-end" alignItems="center" style={{ padding: "0px 8px" }}>
                    <Typography className={classes.errorText}>
                      {errors.streamNameError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
  
              {/* New Batch */}
              <Grid item container xs={12} md={6} alignItems="center" spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item md={2} style={{ padding: "0px" }}></Grid>
                <Grid item xs={12} md={3}>
                  <Typography className={classes.typo}>
                    Test Batch: 
                    <span style={{ color: 'red' }}>*</span>
                  </Typography> 
                </Grid>
                <Grid item xs={12} md={7}>
                  <DropDown
                    options={batchNumbers.map(batch => batch)}
                    type="batchNumber"
                    emptyTag="-Select-"
                    setSelectOption={handleSelectBatchNumber}
                    value={selectedBatch}
                    onBatchDropClick={onBatchDropClick}
                    isSingle={true}
                  />
                </Grid>
                {errors.batchNoError && (
                  <Grid item container xs={12} md={12} justifyContent="flex-end" alignItems="center" style={{ padding: "0px 8px" }}>
                    <Typography className={classes.errorText}>
                      {errors.batchNoError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
  
              {/* Main Test Case Name */}
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography className={classes.typo}>
                    Main Test Case Name: 
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>  
                </Grid>
                <Grid item xs={12} md={9}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    placeholder="Enter Main Test Case Name Here"
                    name="mainTestCaseName"
                    value={mainTestCase} 
                    onChange={handleInputChange}
                  />
                </Grid>
                {errors.mainTestNameError && (
                  <Grid item container justifyContent="flex-end" alignItems="center" style={{ padding: "0px 8px" }}>
                    <Typography className={classes.errorText}>
                      {errors.mainTestNameError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
  
              {/* Sub Test Cases */}
              <SubTestCases
                subTestCases={subTestCases}
                setSubTestCases={setSubTestCases}
                subTestCaseErrors={subTestCaseErrors}
                selectedBatch={selectedBatch}
              />
              {errors.subTestCasesError && (
                <Grid container justifyContent="flex-end" alignItems="center" style={{ marginTop: "12px" }}>
                  <Typography className={classes.errorText}>
                    {errors.subTestCasesError}
                  </Typography>
                </Grid>
              )}
  
              {/* Description */}
              <Grid container alignItems="flex-start" spacing={2} style={{ marginTop: "20px" }}>
                <Grid item xs={12} md={3} className={classes.label}>
                  <Typography className={classes.typo}>
                    Description: 
                  </Typography> 
                </Grid>
                <Grid item xs={12} md={9}>
                  <InputBase
                    className={classes.formInput}
                    style={{ height: "100px" }}
                    type="text"
                    placeholder="Enter Description Here"
                    name="description"
                    value={description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid> 
  
              {/* Add Another Test Case */}
              <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{ marginTop: "20px" }}>
                <Grid item>
                  <FormGroup>
                    <FormControlLabel
                      label={
                        <Typography className={classes.typo}>
                          Add Another Test Case
                        </Typography> 
                      }
                      control={
                        <Checkbox
                          className={classes.radio}
                          checked={addAnotherTestCase}
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              </Grid>
  
              {/* Buttons */}
              <Grid container spacing={2} style={{ marginTop: "20px" }} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${classes.getStarted} ${classes.button}`}
                    style={{ backgroundColor: lightGray, color: 'black'}}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${classes.getStarted} ${classes.button}`}
                    onClick={handleSubmit}
                  >
                    Submit 
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
  
        <Loader loading={loading} />
        <ToasterComponent
          toaster={isToasterOpen}
          message={toasterMessage}
          reqSuccess={reqSuccess}
        />
      </div>
    </ThemeProvider>
  );
}
