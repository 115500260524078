import * as React from "react";
import { useCallback, useEffect, useState} from "react";
import { Box, Button, createTheme, Grid, ThemeProvider, Typography } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import useStyles from "../assets/styles";
import { primaryBlue, primaryGray } from "src/components/colors";
import Step1ConnectDevices from "./steps/Step1ConnectDevices";
import { environment } from "src/environments/environment";
import Step2SelectDevices from "./steps/Step2SelectDevices";
import Step3DataPreview from "./steps/Step3DataPreview";
import { Loader } from "../test-automation/components/Loader";
import { ToasterComponent } from "../test-automation/components/Toaster";
import axios from "axios";

const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
});

const STEPS = [
    {
      text: "Connect Devices",
      activate: true,
    },
    {
      text: "Select Test Devices",
      activate: false,
    },
    {
      text: "Data Preview",
      activate: false,
    },
];

export function ConnectDataStreamComponent() {
    const [step, setStep] = useState(0);
    const [steps, setSteps] = useState(STEPS);
    const classes = useStyles();
    const history = useHistory();
    const [apiKey, setApiKey] = useState("");
    const [baseUrl, setBaseUrl] = useState("");
    const [batchNumber, setBatchNumber] = useState("");
    const [connectDeviceId, setConnectDeviceId] = useState([]);
    const [devices, setDevices] = useState([]);
    const [connectedDevices, setConnectedDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);
    const [message, setMessage] = useState("");
    const [isExistingBatchNumber, setIsExistingBatchNumber] = useState(false);
    const [newBatchNumber, setNewBatchNumber] = useState("");
    const [selectedDeviceFinal, setSelectedDeviceFinal] = useState([]);
    const [inputValue, setInputValue] = useState(batchNumber);
    const [isBack, setIsBack] = useState(false);

    //Errors
    const [baseUrlError, setBaseUrlError] = useState(false);
    const [batchNumberError, setBatchNumberError] = useState(false);
    const [apiKeyError, setApiKeyError] = useState(false);
    const [selectedDeviceError, setSelectedDeviceError] = useState(false);

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        baseUrl: "",
        batchNumber: "",
        apiKey: ""
    });
    console.log(step);

    const validationForm = async () => {
      const baseUrlError = baseUrl.trim() === "";
      const batchNumberError = batchNumber.trim() === "";
      const apiKeyError = apiKey.trim() === "";
    
      setBaseUrlError(baseUrlError);
      setBatchNumberError(batchNumberError);
      setApiKeyError(apiKeyError);
    
      if (baseUrlError || batchNumberError || apiKeyError) {
        return false;
      }
    
      return true;
    };

    const validateForm = () => {
      const selectDeviceError = selectedDeviceFinal.length === 0;

      setSelectedDeviceError(selectDeviceError);

      return !selectDeviceError;
    }

    const connectDevices = async () => {
      localStorage.removeItem('inputValue');
      localStorage.removeItem('fetchedDevices');
      localStorage.removeItem('selectedDevices');
      setLoading(true)
      try{
        await axios.post(`${environment.host}/core/connect-device`,{
          baseUrl: formData.baseUrl,
          batchNumber: formData.batchNumber,
          apiKey: formData.apiKey,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        })
        .then((response) => {
          const connectDeviceId = [response.data.content.id];
          setConnectDeviceId(connectDeviceId);
          setStep(step + 1);
          const updatedSteps = steps.map((s, i) => ({
            ...s,
            activate: i <= step + 1,
          }));
          setSteps(updatedSteps);
        })
        .catch((error) => {
          const errorData = error.response.data;
          if (error.response.status === 422) {
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage(errorData.message);
            setTimeout(() => {
              setIsToasterOpen(false);
            }, 5000);
          } else {
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage("Something went wrong.");
            setTimeout(() => {
              setIsToasterOpen(false);
            }, 5000);
          }
          throw new Error(errorData.message);
        })
      }catch(err){
        console.log('Error for connect device')
      }finally{
        setLoading(false)
      }

    }    
    const fetchConnectDevices = async (connectDeviceId) => {
      setLoading(true);
        try {
        const response = await axios.get(`${environment.host}/core/connect-device/${connectDeviceId}/devices`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        });
        console.log('Full response data:', response.data);
        if (response.data && response.data.content) {
          const devices = response.data.content;
          console.log('Fetched devices content:', devices);
          setConnectedDevices(devices);
          setLoading(false);
          return devices;
        } else {
          console.error('No devices found in the response');
          setLoading(false);
          return [];
        }
      } catch (error) {
        setLoading(false);
        let errorData = error.response ? error.response.data : error;
        console.log('Error fetching devices:', errorData);
        throw new Error(errorData.message || 'An error occurred');
      }
    };

    const selectDevices = async () => {
      setLoading(true);
      const batch = inputValue;
          await axios.post(`${environment.host}/core/connect-device/${connectDeviceId}/devices/${batch}`, selectedDeviceFinal, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then((response) => {
          setSelectedDevice(selectedDeviceFinal);
          setSelectedDeviceFinal([]);
          setStep(step + 1);
          const updatedSteps = steps.map((s, i) => ({
            ...s,
            activate: i <= step + 1,
          }));
          setSteps(updatedSteps);
        })
        .catch((error) => {
          const errorData = error.response.data;
          if (error.response.status === 422) {
            if(errorData.message === 'Batch number already exist') {
              setMessage(errorData.message);
              setIsExistingBatchNumber(true);
            } else {
              setIsToasterOpen(true);
              setReqSuccess(false);
              setToasterMessage(errorData.message);
              setTimeout(() => {
                setIsToasterOpen(false);
              }, 5000);
            }
          }
          throw new Error(errorData.message);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const fetchDevices = async (selectedDeviceIds = selectedDevice) => {
      const deviceIdsParam = selectedDeviceIds.join(',');
      const url = `${environment.host}/core/device${deviceIdsParam ? `?deviceIds=${deviceIdsParam}` : ''}`;
      setLoading(true);
  
      try {
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        });
        const data = response.data;
        setDevices(data.content);
      } catch (error) {
        const errorData = error.response.data;
        throw new Error(errorData.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    const updateType = async (connectDeviceId) => {
      try {
        const response = await axios.put(`${environment.host}/core/connectDevice/${connectDeviceId}`, null, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        });
    
        console.log(response.data);
      } catch (error) {
        console.error('Error updating Connect Device:', error.message);
        if (axios.isAxiosError(error)) {
          const responseData = error.response && error.response.data;
          if (responseData) {
            console.error('Response data:', responseData);
          } else {
            console.error('No response data available');
          }
        }
      }
    };
    

    const reloadData = async () => {
      await fetchDevices();
    }


    const increaseStep = async () => {
      if(step === 0){
        const isValid = await validationForm();
        if (!isValid) {
          return;
        } else {
          connectDevices();
        }
      } else if(step === 1){
        const isValid = await validateForm();
        if(!isValid){
          return;
        } else {
          selectDevices();
        }
      } else {
        setStep(step + 1);
        const updatedSteps = steps.map((s, i) => ({
          ...s,
          activate: i <= step + 1,
        }));
        setSteps(updatedSteps);
      }
    }

    const decreaseStep = () => {
      if(step === 0){
        history.push('/DataStream');
      } else if(step === 2){
        setIsBack(true);
        const savedInputValue = localStorage.getItem('inputValue');
        const savedSelectedDevices = localStorage.getItem('selectedDevices');

        if (savedInputValue) setInputValue(savedInputValue);
        if (savedSelectedDevices) setSelectedDeviceFinal(JSON.parse(savedSelectedDevices));
        setStep(step - 1);
        const updatedSteps = steps.map((s, i) => ({
          ...s,
          activate: i <= step - 1,
        }));
        setSteps(updatedSteps);
      } else {
        clearStoredData();
        setSelectedDeviceFinal([]);
        setStep(step - 1);
        const updatedSteps = steps.map((s, i) => ({
          ...s,
          activate: i <= step - 1,
        }));
        setSteps(updatedSteps);
      }
    }

    const clearStoredData = () => {
      localStorage.removeItem('inputValue');
      localStorage.removeItem('fetchedDevices');
      localStorage.removeItem('selectedDevices');
    };

    async function onClickDone() {
      try {
        await updateType(connectDeviceId);
        
        setStep(0);
        setSteps(STEPS);
        clearStoredData();
        
        history.push({
          pathname: '/TestAutomation',
          state: { connectDeviceId }
        });
      } catch (error) {
        console.error('Error during the update process:', error.message);
      }
    }

    function renderStepsComponent() {
      if(step === 1) {
        return <Step2SelectDevices
          loading={loading}
          connectedDevices={connectedDevices}
          isBack={isBack}
          setIsBack={setIsBack}
          connectDeviceId={connectDeviceId}
          selectedDeviceFinal={selectedDeviceFinal}
          setSelectedDeviceFinal={setSelectedDeviceFinal}
          fetchConnectDevices={fetchConnectDevices}
          message={message}
          setMessage={setMessage}
          inputValue={inputValue}
          setInputValue={setInputValue}
          newBatchNumber={newBatchNumber}
          setNewBatchNumber={setNewBatchNumber}
          isExistingBatchNumber={isExistingBatchNumber}
          batchNumber={batchNumber}
          selectedDeviceError={selectedDeviceError}
          setSelectedDeviceError={setSelectedDeviceError}
           />;
      } else if(step === 2) {
          return <Step3DataPreview 
            loading={loading}
            fetchDevices={fetchDevices}
            connectDeviceId={connectDeviceId} 
            devices={devices}
            setDevices={setDevices}
            reloadData={reloadData}
            selectedDevice={selectedDevice} />;
      } else {
          return <Step1ConnectDevices 
            formData={formData}
            setFormData={setFormData}
            apiKey={apiKey}
            batchNumber={batchNumber}
            baseUrl={baseUrl}
            setBaseUrl={setBaseUrl}
            setbatchNumber={setBatchNumber}
            setApiKey={setApiKey}
            baseUrlError={baseUrlError}
            batchNumberError={batchNumberError}
            apiKeyError={apiKeyError}
            setBaseUrlError={setBaseUrlError}
            setBatchNumberError={setBatchNumberError}
            setApiKeyError={setApiKeyError} />;
      } 
    }

    return (
        <ThemeProvider theme={theme}>
          <div className={`testing`}>
            <div className="page-heading" style={{ marginBottom: "50px" }}>
              Step-01
            </div>
    
            <Grid container alignItems="center">
              <Grid item xs={12} md={12}
                  style={{
                      textAlign: "center",
                      paddingBottom: "40px"
                  }}
              ><h2 style={{color:"#324054"}}>SenzMatica</h2>
              </Grid>
            </Grid>

            <Grid container
                style={{
                    textAlign: "center",
                    marginTop: "20px",
                    paddingRight: "80px"
                }}>
                <Grid item xs={1} md={4}></Grid>
                {steps.map((stepItem, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={2} md={1}>
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                            <Circle number={index + 1} active={index < step} current={index === step}/>
                            </Grid>
                            <Grid item>
                            <Typography
                                style={{
                                fontSize: "17px",
                                paddingTop: "15px",
                                fontFamily: "Poppins, sans-serif",
                                color: index <= step ? "#2A7CED" : "#8F8F8F",
                                }}
                            >
                                {stepItem.text}
                            </Typography>
                            </Grid>
                        </Grid>
                        </Grid>
                        {index !== steps.length - 1 && (
                        <Grid item xs={2} md={1}>
                            <hr
                            style={{
                                marginTop: "32px",
                                opacity: index < step ? "1" : "0.12",
                            }}
                            color={index < step ? "#2A7CED" : "rgba(112, 112, 112, .12)"}
                            />
                        </Grid>
                        )}
                    </React.Fragment>
                ))}
                <Grid item xs={1} md={3}></Grid>
            </Grid>

            <Grid item xs={12} md={12}
                    style={{
                    marginTop: "20px",
                    justifyContent: "center",
                    alignContent: "center",
                    }}>
                {renderStepsComponent()}
            </Grid>

            
                  {step === 0 ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={1} md={1}></Grid>
                        <Grid item xs={12} md={9} style={{
                          display: "flex",
                          justifyContent: "flex-end"
                      }}>
                            <Grid item className={classes.buttonContainer} style={{marginBottom:"30px", marginRight:"20px"}}>
                                <Button onClick={decreaseStep} className={classes.backButton}>
                                    Back
                                </Button>
                            </Grid>
                            <Grid item className={classes.buttonContainer} style={{marginBottom:"30px"}}>
                                <Button onClick={increaseStep} className={classes.submitButton}>
                                    Connect
                                </Button>
                            </Grid>
                          </Grid>
                      </Grid> 
                    </>
                  ) : ( 
                    <>
                      {step === 1 ? (
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={10}
                            style={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                              <Grid item className={classes.buttonContainer} style={{marginBottom:"30px", marginRight:"20px"}}>
                                <Button onClick={decreaseStep} className={classes.backButton}>
                                    Back
                                </Button>
                              </Grid>
                              <Grid item className={classes.buttonContainer} style={{marginBottom:"30px"}}>
                                  <Button onClick={increaseStep} className={classes.submitButton}>
                                      Submit
                                  </Button>
                              </Grid> 
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={11}
                            style={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                <Grid item className={classes.buttonContainer} style={{marginBottom:"30px", marginRight:"20px"}}>
                                  <Button onClick={decreaseStep} className={classes.backButton}>
                                      Back
                                  </Button>
                              </Grid>
                              <Grid item className={classes.buttonContainer} style={{marginBottom:"30px"}}>
                                  <Button onClick={onClickDone} className={classes.submitButton}>
                                      Done
                                  </Button>
                              </Grid>
                            </Grid> 
                          </Grid>
                        )
                      }
                    </>
                  )}
                <Loader loading={loading}/>
                <ToasterComponent
                  toaster={isToasterOpen}
                  message={toasterMessage}
                  reqSuccess={reqSuccess}
                />
          </div>
        </ThemeProvider>
    );
};

const Circle = (props) => {
    const paddedNumber = (props.number).toString().padStart(2, '0');
    return (
      <div>
        <div
          style={{
            borderRadius: "50%",
            width: "65px",
            height: "65px",
            backgroundColor: props.current ?"white" : props.active ? "#2A7CED" : "white",
            border: props.current ? "2px solid #2A7CED" : props.active ? "2px solid #2A7CED" : "0.25px solid #8F8F8F",
            textAlign: "center",
            paddingTop: "10px",
            boxShadow: "0px 4px 8px #0000001F",
          }}
        >
          <h5 style={{
            paddingTop: "10px",
            fontSize: "16px",
            color: props.current ? primaryBlue : props.active ? "white" : primaryGray
          }}>
            {paddedNumber}
          </h5>
        </div>
      </div>
    );
  };