import { createTheme, Grid, ThemeProvider, Typography } from '@material-ui/core'
import * as React from 'react'
import useStyles from '../../assets/styles'
import { DropDown } from '../components/DropDown'
import { FormField } from './FormFiled'
import { useState } from 'react'

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
})

export const ApiConfigurationComponent = () => {
    const classes = useStyles()
    const [dataPoints, setDataPoints] = useState(["points 1", "points 2", "points 3"])
    const [selectedDataPoint, setSelectedDataPoints] = useState('')

    const handleSelectDataPoints = (value) => {
        setSelectedDataPoints(value)
    }

    const onChange = (event) => {
        let { value } = event.target
        let trimValue = value.trim()
    }

    return (
        <ThemeProvider theme={theme} >
            <div className='testing'>
                <Grid container className={`${classes.root} ${classes.formContent}`}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                <FormField
                                    fieldTitle="API URL"
                                    inputName="api"
                                    placeholder="Enter API URL Here"
                                    isRequired={false}
                                    onChange={(event) => onChange(event)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    fieldTitle="Username"
                                    inputName="username"
                                    placeholder="Enter Username Here"
                                    isRequired={false}
                                    onChange={(event) => onChange(event)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    fieldTitle="Password"
                                    inputName="password"
                                    placeholder="Enter Password Here"
                                    isRequired={false}
                                    onChange={(event) => onChange(event)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.typo}>
                                    Data Points
                                </Typography>
                                <DropDown
                                    options={dataPoints}
                                    type='dataPoints'
                                    emptyTag='Select Data Points'
                                    setSelectOption={setDataPoints}
                                    value={selectedDataPoint}
                                    onClick={handleSelectDataPoints}
                                    isSingle={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    )
}