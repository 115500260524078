import axios from "axios";
import {environment} from "src/environments/environment";

// Fetch batch numbers from the API endpoint
export const fetchBatchNumbers = async () => {
    return axios.get(`${environment.host}/core/deviceTest/batchNumbers`, {
      headers: {
        "Content-Type": "application/json", 
        Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN")
      },
    })
      .then((response) => {
        return response.data.content;
      })
      .catch((err) => {
        console.error('Error fetching batch numbers:', err);
        return;
      });
};

// Get Parameters from batch number API endpoint
export const fetchParameter = async (batchNo) => {
  return axios.get(`${environment.host}/core/sensorsCodesOfBatch/${batchNo}`, {
      headers: {
        "Content-Type": "application/json", 
        Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN")
      },
    })
      .then((response) => {
        return response.data.content;
      })
      .catch((err) => {
        console.error('Error fetching metadata:', err);
        return;
      });
}

// Fetch MetaData from the API endpoint
export const fetchMetaData = async () => {
    return axios.get(`${environment.host}/core/meta-data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN")
        },
      })
        .then((response) => {
          // return response.data.content.sensors.sort((a, b) => (a.name < b.name ? -1 : 1));
          return response.data.content.sensors;
        })
        .catch((err) => {
          console.error('Error fetching metadata:', err);
          return;
        });
}

export const matchSensorNames = async (batchNo) => {
  try {
    const sensorCodes = await fetchParameter(batchNo);
    const sensorsMetaData = await fetchMetaData();

    const sensorMap = sensorsMetaData.reduce((acc, sensor) => {
      acc[sensor.code] = sensor.name;
      return acc;
    }, {});

    // Mapping sensor codes to their corresponding names
    const sensorNames = sensorCodes.map((code) => ({
      code,
      name: sensorMap[code],
    }));
    
    return sensorNames;

  } catch (err) {
    console.error("Error matching sensor names:", err);
    return [];
  }
};

// Submit test case data to the API endpoint
export const defineTestCase = async ( 
  testCase, 
  setLoading, 
  setIsToasterOpen, 
  setToasterMessage, 
  setReqSuccess,
  history,
  addAnotherTestCase
) => {
    setLoading(true);
    await axios.post(`${environment.host}/core/deviceTest/defineTestCases`, testCase, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })   
    .then((response) => {
      setIsToasterOpen(true);
      setToasterMessage("Test Case Defined Successfully");
      setReqSuccess(true);
      setTimeout(() => {
        setIsToasterOpen(false);
        if (addAnotherTestCase) {
          window.location.reload();
        } else {
          history.push(`/TestAutomation`);
        }
      }, 1000);
    }) 
    .catch((error) => {
      console.error('Error submitting test case:', error);
      setLoading(false);
      setIsToasterOpen(true);
      setToasterMessage(error.response.data.message);
      setReqSuccess(false);
      setTimeout(() => {
        setIsToasterOpen(false);
      }, 4000);
      throw new Error(error.response.data.message);
    })
    .finally(() => {
      setLoading(false);
    });
};

// Get Main Test cases from the API endpoint
export const fetchMainTestCases = async (batchNo) => {
  try {
    const response = await axios.get(
      `${environment.host}/core/deviceTest/testcase/batch/${batchNo}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      }
    );
    const allData = response.data;
    const data = allData.content.filter((d) => d.default === true);
    
    const mainTestTitles = data.map(testCase => testCase.mainTestTitle);
    const uniqueDevices = [...new Set(data.flatMap(testCase => testCase.devices))];
    
    return {
      mainTestTitles,
      content: data,
      uniqueDevices
    };
  } 
  catch (err) {
    console.error('Error fetching main test cases:', err);
  }
};

// Submit data to start testing API endpoint
export const startTesting = async ( 
  payload, 
  setLoading, 
  setIsToasterOpen, 
  setToasterMessage, 
  setReqSuccess, 
  selectedTestingMethod,
  timeIntervalInHours,
  history
) => {
    setLoading(true);

    const endpoint = selectedTestingMethod === "Continuous Testing" 
      ? `${environment.host}/core/deviceTest/startContinuousTesting?hours=${timeIntervalInHours}`
      : `${environment.host}/core/deviceTest/startTesting`;

    const response = await axios.post(endpoint, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then((response) => {
      setIsToasterOpen(true);
      setToasterMessage("Test scheduled successfully");
      setReqSuccess(true);
      setTimeout(() => {
        setIsToasterOpen(false);   
        history.push(`/TestAutomation`); 
      }, 2000);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error submitting test case:', error);
      setLoading(false);
      setIsToasterOpen(true);
      setToasterMessage(error.response.data.message);
      setReqSuccess(false);
      setTimeout(() => {
        setIsToasterOpen(false);
      }, 4000);
      throw new Error(`HTTP error! status: ${error.response.status}`);
    });
};

// Get Test Summary Data from the API endpoint
export const getDeviceTestSummery = async (
  batchNo, 
  mainTitle, 
  testCaseType, 
  continuousMainTestId, 
  timeShedule
) => {
  try {
    const endpoint = testCaseType === "Continuous Testing"
      ? `${environment.host}/core/continuousTestSummary/${continuousMainTestId}/${timeShedule}`
      : `${environment.host}/core/deviceTest/testReportTable/${batchNo}?testCaseTitle=${mainTitle}`;

    const response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
    }});

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.data.content;
  } catch (err) {
    console.error('Error fetching device test summary:', err);
  }
};


// Get Devices from Batch API endpoint
export const fetchDevices = async (batchNo) => {
  try {
    const response = await axios.get(`${environment.host}/core/device`, {
      params: { batchNumber: batchNo },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`Error fetching devices: ${response.statusText}`);
    }
    const data = response.data;
    const deviceIds = data.content.map(device => device.id);
    return deviceIds;

  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while fetching devices");
  }
};


// Get schedule times for the main test case API endpoint
export const getScheduleTimes = async (mainTestCaseId) => {
  return axios
    .get(`${environment.host}/core/getScheduleTimes/${mainTestCaseId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
    }})
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      console.error('Error fetching schedule times:', err);
      return;
    });
};

// Get Continuous Test from the API endpoint
export const getContinuousTestCase = async (batchNo,mainName) => {
  try {
    const response = await axios.get(
      `${environment.host}/core/deviceTest/testcase/batch/${batchNo}`, 
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      }
    );

    const allData = response.data;
    const continuousTest = allData.content.find(
      (d) => d.default === false && 
             d.testCaseType && 
             d.mainTestTitle === mainName &&
             d.testCaseType.toUpperCase() === "CONTINUOUS"
    );
    return continuousTest ? continuousTest.id : null;
  } catch (err) {
    console.error('Error fetching continuous test cases:', err);
  }
};

// Get schedule times for the main test case API endpoint
export const getSuccessCriteria = async (mainTestId) => {
  return axios
    .get(`${environment.host}/core/mainTestCase/${mainTestId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
    }})
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      console.error('Error fetching schedule times:', err);
      return;
    });
};

// Get test automation sensors reading
export const getSensorReading = (data) =>{
  return axios
    .post(`${environment.host}/core/testAutomation/sensorReadings`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
      }})
      .then((response) => {
        return response.data.content;
      })
      .catch((err) => {
        console.error('Error fetching schedule times:', err);
        return;
      });
}

export const rootCauseConfiguration = (data) =>{
  return axios
    .post(`${environment.host}/core/rootCauseConfigure`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
      }})
      .then((response) => {
        return response.data.content;
      })
      .catch((err) => {
        console.error('Error fetching schedule times:', err);
        return;
      });
}
