import * as React from 'react';
import {useEffect, useState,useRef} from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import {primaryBlue, primaryGray} from 'src/components/colors';
import {CloseIcon, CloseIconColor} from '../../../components/Icons';
import {SearchBar} from 'src/components/sub-components/SearchBar';
import axios from "axios";
import {toast} from "react-toastify";
import {environment} from 'src/environments/environment';


export function ConnectDevice(props) {

  const classes = useStyles();
  const [selectDecoder, setSelectDecoder] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [deviceDropDown, setDeviceDropDown] = useState(false)
  const [selectedDeicesId, setSelectedDevicesId] = useState([])
  const [searchDeviceArray, setSearchDeviceArray] = useState([])
  const [selectedDevice, setSelectedDevice] = useState([])
  const [decoderError, setDecoderError] = useState(false)
  const [deviceError, setDeviceError] = useState(false)
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null)

  const toggleDropdown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const toggleDropdownDevice = () => {
    setDeviceDropDown(!deviceDropDown);
    if (props.type == 'device-connect') {
      setSearchDeviceArray(props.devicesArray)
    } else {
      setSearchDeviceArray(props.devicesArrayAll)
    }

  };

  const selectAllDevices = () => {
    const arrayLength = searchDeviceArray.length === selectedDevice.length
    if (arrayLength) {
      setSelectedDevicesId([])
      setSelectedDevice([])
    } else {
      setSelectedDevicesId(searchDeviceArray.map((device) => device.id))
      setSelectedDevice(searchDeviceArray)
    }

  }

  const deviceSelection = (option) => {
    if (selectedDeicesId.includes(option.id)) {
      setSelectedDevicesId(selectedDeicesId.filter((id) => id != option.id))
      setSelectedDevice(selectedDevice.filter((device) => device != option))
    } else {
      setSelectedDevicesId([...selectedDeicesId, option.id])
      setSelectedDevice([...selectedDevice, option])
      setDeviceError(false)
    }
  }

  const searchDevices = (e) => {
    if (props.type === 'device-connect') {
      if (e.target.value == '') {
        setSearchDeviceArray(props.devicesArray)
      } else {
        setSearchDeviceArray(props.devicesArray.filter((device) => device.name.toLowerCase().includes(e.target.value.toLowerCase())))
        setDeviceError(false)
      }
    } else {
      if (e.target.value == '') {
        setSearchDeviceArray(props.devicesArrayAll)
      } else {
        setSearchDeviceArray(props.devicesArrayAll.filter((device) => device.name.toLowerCase().includes(e.target.value.trim().toLowerCase())))
        setDeviceError(false)
      }
    }
  }

  useEffect(() => {
    if (props.type === 'device-connect') {
      // props.getDeviceData()

    } else {
      // getDeviceDataAll()
    }
  }, [props.type])

  const findDecoderId = () => {
    const updateId = props.decoders.filter((d) =>
      d.codecName === selectDecoder).map((d) => d.id)
    // console.log(updateId)
    return updateId;
  };

  const connectDecoder = () => {
    const id = findDecoderId()
    console.log(id)
    const url =
      props.type === 'device-connect'
        ? `${environment.host}/core/codec/${props.decoderIdView}/device`
        : `${environment.host}/core/codec/${id}/device`;

    setLoading(true)
    closePopup()
    axios.post(url, selectedDeicesId,
      {
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')},
      })
      .then((res) => {
        const newDevice = res.data.content
        if (res.status == 500) {
          props.setReqSuccess(false)
          props.setErrorMessage('Something went wrong.')
        }
        // props.setDeviceData(prevDevices =>[...prevDevices,...newDevice])
        props.type === 'device-connect' ? props.getParticularDecoder() : props.getAllCodec()

        setLoading(false)

      }).then((data) => {
      props.setToast(true)
      props.setReqSuccess(true)
      if (selectedDevice.length == 1) {
        props.setErrorMessage('Device connected to the codec successfully.')
      } else {
        props.setErrorMessage('Devices connected to the codec successfully.')
      }
      props.type === 'device-connect' ? props.getDeviceData() : props.getDeviceDataAll()

    })
      .catch((error) => {
        console.log(error)
        props.setReqSuccess(false)
        setLoading(false)
        props.setErrorMessage(error.message)

      })
  }

  const updateDeviceConnectWithDecoder = () => {
    setLoading(true)
    const updateId = props.decoders.filter((d) =>
      d.codecName.toLowerCase().includes(selectDecoder.toLowerCase())).map((d) => d.id)
    axios.put(
      `${environment.host}/core/codec/${props.decoderId}/device/${props.deviceId}?updateCodecId=${updateId[0]}`
    ).then((res) => {
        props.onclose()
        props.getParticularDecoder()
        props.getDeviceData()
        setLoading(false)
        props.setToast(true)
        props.setReqSuccess(true)
        props.setErrorMessage('Assigned Codec updated successfully.')
      }
    ).catch((err) => {
        props.setReqSuccess(false)
        setLoading(false)
        props.onclose()
        props.setErrorMessage(err.message)
      }
    )
  }

  const getDecoder = (e) => {
    setSelectDecoder(e.target.value)
    setDecoderError(false)
    setIsDropDownOpen(!isDropDownOpen)
  }

  const closePopup = () => {
    props.onclose()
    setSelectedDevicesId([])
    setSelectedDevice([])
    setSelectDecoder('')
    setDeviceDropDown(false);
    setIsDropDownOpen(false);
    setDecoderError(false);
    setDeviceError(false)
  }

  const checkValidation = () => {
    let error;
    if (((selectDecoder == '' && props.type != 'device-connect') || (props.type !== 'edit-device' && selectedDevice.length == 0))) {
      error = true
      if (selectDecoder == '') {
        setDecoderError(true)
      }
      if (selectedDevice.length == 0) {
        setDeviceError(true)
      }

      if (error == true) {
        return
      }
    } else {
      error = false
      props.type === 'edit-device' ? updateDeviceConnectWithDecoder() : connectDecoder()

    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDownOpen(false)
      }
      if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
        setDeviceDropDown(false)
      }
    };


    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div>
      <Dialog open={props.open} maxWidth="md" style={{height: props.type === 'edit-device' ? '300px' : '610px'}}
              classes={{paperScrollPaper: classes.dialogBox}}>
        <Grid container>
          <Grid item xs={10} md={10}>
            <DialogTitle>{props.type === 'edit-device' ? 'Edit Device Codec' : 'Connect Devices with Codec'}</DialogTitle>
          </Grid>
          <Grid item xs={2} md={2} style={{textAlign: "right"}}>
            <IconButton onClick={() =>
              closePopup()
            }
                        style={{paddingTop: "20px"}}>
              <CloseIconColor/>
            </IconButton>
          </Grid>
        </Grid>

        {/* <Divider /> */}
        {
          <DialogContent style={{margin: "0", overflow: 'hidden', height: '100px'}}>
            <Grid container>
              <Grid style={{textAlign: "left", paddingBottom: "20px", margin: "20px 0 0 0", overflow: 'hidden'}} ref={dropdownRef}>
                <Typography
                  className={classes.label}>{props.type === 'edit-device' ? 'Select the New Codec Method' : props.type == 'device-connect' ? 'Codec Method' : ' Select the Codec Method'}</Typography>
                  {props.type == 'device-connect' ?
                    <input value={props.codecName}
                          style={{border: 'none', width: '680px', color: '#464646', fontSize: '16px', fontWeight: '300'}}
                          readOnly={true}/>
                    : <div style={{
                      display: 'flex',
                      position: 'relative',
                      borderColor: decoderError === true ? 'red' : '#C7C4C4'
                      }}
                      className={classes.input}
                      onClick={toggleDropdown}>
                        <input value={selectDecoder} style={{
                          border: 'none',
                          width: '680px',
                          cursor: 'pointer',
                          color: '#707070',
                          fontSize: '14px',
                          fontWeight: '300'
                        }} placeholder='Select the Codec' readOnly={true}/>

                    {isDropDownOpen ?
                      <button className={classes.dropDown}>
                        <i className="fa fa-angle-up"></i>
                      </button>
                      :
                      <button className={classes.dropDown}>
                        <i className="fa fa-angle-down"></i>
                      </button>
                    }

                  </div>}
                {isDropDownOpen && <div className={classes.option} onClick={e => {getDecoder(e)}}
                  style={{width: '717px'}}>
                  {props.decoders.map((decoder, i) => (
                    <option value={decoder.codecName} key={i} className={classes.optionValue}>{decoder.codecName}</option>
                  ))
                  }
                </div>}
                {decoderError === true && props.type != 'device-connect' &&
                  <FormHelperText className={classes.helperText}>Pick a Codec *</FormHelperText>}
              </Grid>

            </Grid>

          </DialogContent>

        }
        {props.type !== 'edit-device' &&
          <DialogContent style={{margin: "0", overflow: 'hidden', position: 'relative'}}>
            <Grid container>
              <Grid style={{textAlign: "left", margin: "0"}}>
                <Typography className={classes.label}>Choose Devices</Typography>
                <Grid container ref={dropdownRef1}>
                  <Grid item style={{display: "flex", width: '400px', minWidth: '0%',paddingLeft: '0px'}}
                    onClick={toggleDropdownDevice} className={classes.input}>
                    <input style={{
                      border: 'none',
                      width:'100%',
                      cursor: 'pointer',
                      color: '#707070',
                      fontSize: '14px',
                      fontWeight: '300',
                      paddingLeft: '10px',
                    }} placeholder='Choose Devices' readOnly={true}/>
                    {deviceDropDown ?
                      <button className={classes.dropDown}>
                        <i className="fa fa-angle-up"></i>
                      </button>
                      :
                      <button className={classes.dropDown}>
                        <i className="fa fa-angle-down"></i>
                      </button>
                    }
                    {deviceDropDown && (
                      <div className={classes.option} style={{width: '400px', margin: '0px', marginTop: '40px'}}>
                        <SearchBar onChange={(e) => {
                          searchDevices(e)
                        }} InputProps={{style: {height: '30px'}}} search="Search for device name"/>
                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                            className={classes.optionValue} onClick={() => selectAllDevices()}>
                          <Checkbox style={{color: '#0000008A', padding: '5px 2px'}}
                                    checked={searchDeviceArray.length === selectedDevice.length && selectedDevice.length != 0}
                                    onClick={() => selectAllDevices()}/>
                          <label style={{cursor: 'pointer'}}>
                            Select All
                          </label>
                        </div>
                        {searchDeviceArray.map((device, i) => (
                          <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                              className={classes.optionValue} key={i} onClick={() => deviceSelection(device)}>
                            <Checkbox value={device.name} style={{color: '#0000008A', padding: '5px 2px'}}
                                      id={i + device.name}
                                      checked={selectedDevice.includes(device)}
                                      onClick={() => deviceSelection(device)}/>
                            <label style={{cursor: 'pointer'}}>
                              {device.name}
                            </label>
                          </div>
                        ))}
                      </div>
                  )}
                  </Grid>

                </Grid>
                  <FormControl className={classes.input} style={{
                    height: "220px",
                    marginTop: '10px',
                    backgroundColor: '#F8F8F8',
                    borderColor: deviceError ? 'red' : '#C7C4C4'
                  }}>
                    <Grid container style={{
                      padding: "10px",
                      margin: "0px",
                      overflow: "auto",
                      justifyContent: selectedDevice.length == 0 ? 'center' : 'start'
                    }}>
                      {selectedDevice.length == 0 ? <p style={{paddingTop: '90px'}}>There are no selected devices</p>
                        : (
                          selectedDevice
                            .map((device, i) => {
                              return (
                                (
                                  <Grid item key={i} className={classes.displayDevices}>
                                    {device.name}
                                    <IconButton onClick={() => deviceSelection(device)}
                                                style={{padding: "0 0 0 5px", color: primaryBlue}}
                                    >
                                      <CloseIcon/>
                                    </IconButton>
                                  </Grid>
                                )

                              );
                            })
                        )}

                    </Grid>
                  </FormControl>
              </Grid>

            </Grid>
            {deviceError === true &&
              <FormHelperText className={classes.helperText}>Please pick at least one device*</FormHelperText>}

          </DialogContent>

        }

        {
          <DialogActions style={{width: "100%"}} hidden>
            <Button variant='contained' className={classes.cancel}
                    onClick={() => closePopup()}>
              Cancel
            </Button>
            <Button variant='contained' className={classes.connect} onClick={() => {
              checkValidation()
            }}
            >
              {props.type === 'edit-device' ? 'Save' : 'Connect'}
            </Button>
          </DialogActions>
        }

      </Dialog>

      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>

      }
    </div>
  )
}

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    connect: {
      backgroundColor: primaryBlue,
      color: "white",
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    cancel: {
      backgroundColor: "white",
      color: primaryGray,
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    label: {
      display: 'flex',
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
      textAlign: "left"
    },
    input: {
      minWidth: "100%",
      maxWidth: "100%",
      height: "44px",
      backgroundColor: "white",
      width: "717px",
      border: "solid 1px #C7C4C4",
      color: '#707070',
      paddingLeft: '8px',
      borderRadius: '4px',
      "&:after": {
        borderBottom: 'solid 1px #C7C4C4'
      }
    },
    chip: {
      borderRadius: "4px",
      backgroundColor: "#C7C4C436",
      marginLeft: "5px"
    },
    title: {
      fontSize: "18px",
      paddingRight: "0px"
    },
    deletePopup: {
      width: "520px",
      height: "220px"
    },
    displayDevices: {
      backgroundColor: "#E6F1FF",
      color: primaryBlue,
      padding: "10px",
      margin: "5px 10px 5px 0",
      display: "flex",
      alignItems: "center"
    },
    dialogBox: {
      height: '610px',
      maxHeight: '100%'
    },
    dropDown: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "none",
      color: "#7A869A",
      opacity: 1,
      cursor: 'pointer',
      paddingRight:'10px'
    },
    option: {
      border: "0.4699999988079071px solid #CECACA",
      overflow: 'scroll',
      maxHeight: '190px',
      paddingLeft: "10px",
      cursor: 'pointer',
      color: '#707071',
      position: 'absolute',
      zIndex: 1,
      backgroundColor: '#FFFFFF',
      borderRadius: '4px'
    },
    optionValue: {
      padding: '0px',
      "&:hover": {
        backgroundColor: "#C7C4C4"
      },
      overflowX: 'hidden'
    },
    helperText: {
      color: "red"
    }
  }),
);
