export class MagmaResponse<T> {
  statusCode: string;
  statusDescription: string;
  content: T;
}

export class MagmaUser {
  client_id: string;
  client_secret: string;
  username: string;
  password: string;
  grant_type: string;
  scope: string;

  constructor(client_id, client_secret, grant_type, scope) {
    this.client_id = client_id;
    this.client_secret = client_secret;
    this.grant_type = grant_type;
    this.scope = scope;
  }
}

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  reference: string;
  role: number;
}

export interface AlertLimit {
  id?: string;
  kitId?: string;
  propertyNumber?: number;
  code?: string;
  level: number;
  low: number;
  high: number;
  currentLevelPeriod?;
  nextLevelPeriod?;
  persistence?;
  time?: string;
  status?: string;
}

export interface AlertHistory {
  user: string;
  maintain: boolean;
  alert: boolean;
  time: string;
  level: number;
}

export interface AlertStatus {
  enable: boolean;
  modes: Modes;
}

export interface Modes {
  SMS: boolean;
  EMAIL: boolean;
}

export interface Battery {
  low: number;
  high: number;
  reading: number;
}

export interface Model {
  id: string;
  name: string;
  noOfProperties: number;
  properties?: (string)[] | null;
  type: string;
  gpsEnabled: boolean;
  lbsEnabled: boolean;
  batteryEnabled: boolean;
}

export interface Device {
  favourite?: any;
  referenceName?: any;
  id: string;
  name: string;
  noOfSensors: number;
  sensorCodes: String[];
  actuatorCodes: String[];
  noOfActuators: number;
  devices: String[];
  interval: number;
  persistence: boolean;
  connectivity: string;
  protocol: string;
  status: string;
  modifiedDate: string;
  properties?: (PropertiesEntity)[] | null;
  sensors?: (PropertiesEntity)[] | null;
  alerts: any;
  shiftMap: any;
  alertLevel: number;
  alertStatus?: AlertStatus;
  model: Model;
  battery: Battery;
  maintain: boolean;
  lastSeen: string;
  metaData: any;
  createdBy: any;
  modifiedBy: any;
}

export interface FormKitRadioFields {
  maintain: boolean;
  persistence: boolean;
}

export interface KitModel {
  id: String;
  name: String;
  noOfSensors: number;
  noOfProperties: number;
  sensors: Sensor [];
  actuators: Actuator [];
  properties: string [];
  operations: String [];
  actions: String [];
  lbsEnabled: boolean;
  gpsEnabled: boolean;
  batteryEnabled: boolean;
  type: String;
}

export interface Kit {
  id: string;
  name: string;
  devices: String[];
  alertLevel: number;
  interval: number;
  persistence: boolean;
  maintain: boolean;
  model: KitModel;
  metaData: any;
  shiftMap: any;
  properties: any[];
  inputMethod: String;
  createdBy: any;
  modifiedBy: any;
}

export interface GraphEntity {
  time: string;
  value: number;
}

export class DeviceGraph {
  graphDate: any[];
  graphValue: any[];
  graphData: any[];
  entityName: string;
  propertyDisplayName: string;
}

export interface Application {
  id: string;
  name: string;
  password: string;
  dataUrl: string;
}

export interface PropertiesEntity {
  number: number;
  code: string;
  time: string;
  alert: boolean;
  error: boolean;
  showThreshold: boolean;
  displayValue: string;
  displayName: string;
}

export interface Sensor {
  name: string;
  code: string;
}

export interface Actuator {
  name: string;
  code: string;
}

export interface KitType {
  name: string;
  value: string;
}


export interface MetaData {
  sensors: Sensor[];
  actuators: Actuator[];
  kitTypes: KitType[];
}

export interface Configuration {
  imageURL: string;
  aboutUrl: string;
  aboutTitle: string;
  hasFooter: string;
  hasKitUser: string;
}

export interface Geo {
  type: string;
  time: string;
  lat: number;
  lng: number;
}

export enum FORM_TYPE {
  ADD = 0,
  EDIT = 1,
}

export enum PROPERTY_TYPE {
  T = 'Temperature',
  H = 'Humidity',
  M = 'Moisture',
  B = 'Battery',
}

export enum ACTUATORS {
  SNC = 'Solenoid'
}

export enum PROCESS_TYPE {
  R = 'REAL_TIME',
  B = 'BULK',
}

export enum ACCESS_TYPE {
  READ = 'WRITE',
  WRITE = 'WRITE',
  CLONE = 'CLONE',
  ADD = 'ADD',
  VIEW = 'VIEW',
  RUN = 'RUN',
}

export enum SECTIONS {
  D_K_KM = 'DEVICE_KIT_KITMODEL',
  D = 'DEVICE',
  K = 'KIT',
  KM = 'KITMODEL',
}