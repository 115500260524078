import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {environment} from 'src/environments/environment';
import {primaryBlue, secondaryTextColor, white} from '../../components/colors';
import {SearchBar} from '../../components/sub-components/SearchBar';
import {AddNewDecoder} from './add-new-decoder/NewDecoder';
import 'react-toastify/dist/ReactToastify.css';
import {DeleteDecoder} from './delete-decoder/DeleteDecoder';
import {ConnectDevice} from './connect-devices/ConnectDevice';
import {Alert} from "@material-ui/lab";
import {Link} from 'react-router-dom';
import axios from "axios";
import {toast} from "react-toastify";
import {DeleteIconLarge, EditIconLarge, ViewIcon} from '../../components/Icons';

export function DecodersComponents(props) {
  const classes = useStyles();
  const [decoders, setDecoders] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newDecoder, setNewDecoder] = useState(false);
  const [decoderId, setDecoderId] = useState('');
  const [deleteDecoder, setDeleteDecoder] = useState(false);
  const [type, setType] = useState('');
  const [connectDevice, setConnectDevice] = useState(false);
  const [decoderNames, setDecoderNames] = useState([]);
  const [connectDecoderWithDevice, setConnectDecoderWithDevice] = useState([]);
  const [toaster, setToaster] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [reqSuccess, setReqSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentDecoderFileName, setCurrentDecoderFileName] = useState('');
  const [decoderScript, setDecoderScript] = useState()
  const [currentEncoderFileName, setCurrentEncoderFileName] = useState('');
  const [encoderScript, setEncoderScript] = useState()
  const [currentCodecDetails, setCurrentCodecDetails] = useState();
  const [codecName, setCodecName] = useState('')
  const [scriptFormat, setScriptFormat] = useState('')
  const [devices, setDevices] = useState([])
  const [devicesArrayAll, setDevicesArrayAll] = useState([])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const setID = (decoder) => {
    setDecoderId(decoder.id);
    setEncoderScript(decoder.encoderFileContent)
    setCurrentEncoderFileName(decoder.encoderFileName)
    setDecoderScript(decoder.decoderFileContent)
    setCurrentDecoderFileName(decoder.decoderFileName)
    setCodecName(decoder.codecName)
    setScriptFormat(decoder.scriptFormat)

  }
  const closePopup = () => {
    setNewDecoder(false);
    setDeleteDecoder(false);
    setConnectDevice(false);
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      getDeviceDataAll(),
      getAllCodec()
    ]).then(() => {
      setLoading(false); // Hide loading indicator when all API calls complete
    }).catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false); // Ensure loading indicator is hidden on error
    });
  }, [])

  const getAllCodec = () => {
    return new Promise<void>((resolve, reject) => {
      axios.get(
        environment.host + `/core/codec`,
        {
          headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')}
        })
        .then((response) => {
          setDecoders(response.data.content);
          resolve();
        })
      .catch((err) => {
        reject(err);
      });
    })
  }

  const getDeviceDataAll = () => {
    return new Promise<void>((resolve, reject) => {
      axios.get(
        environment.host + '/core/device',
        {
          headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')},
        }
      ).then((res) => {
        setDevices(res.data.content)
        const devicesFiler = (res.data.content.filter((dev) => {
          return dev.magmaCodecId === null || !dev.hasOwnProperty('magmaCodecId')
        }))
        setDevicesArrayAll(devicesFiler)
        resolve();
      }).catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went Wrong, Please try again later");
        }
        reject(error)
      })
    })

  }


  const updateDecoders = (decoderId, newName, updateStatus) => {
    const updatedDecoders = decoders.map(decoder => {
      if (decoder.id === decoderId) {
        return {...decoder, name: newName, status: updateStatus};
      }
      return decoder;
    });
    setDecoders(updatedDecoders);
  };
  return (
    <div>
      <div>
        <section style={{width: "100%", transition: "0.55s", textAlign: "center"}} id="title"
        >
          <div className="page-heading">
            Transcoding
          </div>

        </section>
      </div>
      <div className={`testing`}>
        {
          <Grid container className={classes.container}>
            <Grid item xs={12} md={12} style={{display: "flex", height: "40px"}}>
              <Grid item style={{textAlign: "left", width: "350px"}}>
                <SearchBar onChange={(e) => setSearch(e.target.value)} search="Search for codec name"/>
              </Grid>
              <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
                <Grid item style={{textAlign: "right"}}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setConnectDevice(true), setType('connect')
                    }}
                    className={classes.connectDecoder}
                    style={{textTransform: "none"}}
                  >Connect Devices</Button>
                </Grid>
                <Grid item style={{textAlign: "right", paddingLeft: "10px"}}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setNewDecoder(true), setType("addNew")
                    }}
                    className={classes.addDecoder}
                    style={{textTransform: "none"}}
                  >Create Codec</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{
              textAlign: 'left',
              alignItems: 'center',
              display: 'flex',
              paddingLeft: '0px',
              justifyContent: 'flex-end'
            }} item xs={12} md={12}>

              <TablePagination style={{marginRight: '8px', marginTop: '10px'}}
                               rowsPerPageOptions={[]}
                               component="div"
                               count={decoders !== undefined && decoders.filter((dec) => dec.codecName.toLowerCase().includes(search.trim().toLowerCase())).length}
                               rowsPerPage={rowsPerPage}
                               page={page}
                               onPageChange={handleChangePage}
                               onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{marginTop: "20px"}}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                        width:'50%'
                      }}>
                        <Typography display="inline" style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>Encoder/Decoder</Typography>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "center",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}>
                        <Typography style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>Actions</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {decoders !== undefined && decoders.filter((obj) => {
                      if (search === "") {
                        return obj
                      } else if (obj.codecName.toLowerCase().includes(search.trim().toLowerCase())) {
                        return obj
                      }
                    }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((decoder, i) => (

                      <TableRow key={i} style={{height:'50px'}}>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                          width: '200px'
                        }}>
                          <Typography display='inline' className={classes.tableText}
                                      style={{paddingLeft: "5px"}}>{decoder.codecName}</Typography>
                        </TableCell>
                        <TableCell style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                        }}>
                          <Link to={{
                            pathname: `/${decoder.id}/codec`,
                            state: {
                              data: decoder,
                              decoderName: decoderNames,
                              decoders: decoders,
                              devices:devicesArrayAll
                            }
                          }
                          }
                          >
                            <IconButton style={{padding: "0px"}} onClick={() => setType('view')}>
                              <ViewIcon/>
                            </IconButton>
                          </Link>
                          <IconButton style={{padding: "0px", paddingLeft: "10px"}} onClick={() => {
                            setType('update'), setNewDecoder(true), setID(decoder), setCurrentCodecDetails(decoder)

                          }}>
                            <EditIconLarge/>
                          </IconButton>
                          <IconButton style={{padding: "0px", paddingLeft: "10px"}} onClick={() => {
                            setID(decoder), setDeleteDecoder(true)
                          }}>
                            <DeleteIconLarge/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        }
      </div>
      <AddNewDecoder
        open={newDecoder}
        onClose={() => closePopup()}
        setDecoders={setDecoders}
        type={type}
        decoderId={decoderId}
        updateDecoders={updateDecoders}
        setDecoderName={setDecoderNames}
        decoderNames={decoderNames}
        getAllCodec={() => getAllCodec()}
        setLoading={setLoading}
        setToast={setToaster}
        setReqSuccess={setReqSuccess}
        setErrorMessage={setErrorMessage}
        scriptFormat={scriptFormat}
        codecName={codecName}
        currentEncoderFileName={currentEncoderFileName}
        currentDecoderFileName={currentDecoderFileName}
        decoderScript={decoderScript}
        encoderScript={encoderScript}
        decoders={decoders}

      />
      <DeleteDecoder open={deleteDecoder} onClose={() => closePopup()} decoderId={decoderId} decoders={decoders}
                     setDecoders={setDecoders} setDecoderName={setDecoderNames} setLoading={setLoading}
                     getAllCodec={() => getAllCodec()} setToast={setToaster}
                     setReqSuccess={setReqSuccess}
                     setErrorMessage={setErrorMessage}
                     getDeviceDataAll={() => getDeviceDataAll()}
      />
      <ConnectDevice open={connectDevice} onclose={() => closePopup()} decoderId={decoderId} decoders={decoders}
                     setDecoders={setDecoders} 
                     decodersName={decoderNames}
                     setConnectDecoderWithDevice={setConnectDecoderWithDevice} type={type} setLoading={setLoading}
                     setToast={setToaster}
                     setReqSuccess={setReqSuccess}
                     setErrorMessage={setErrorMessage}
                     getAllCodec={() => getAllCodec()}
                     devicesArrayAll={devicesArrayAll}
                     getDeviceDataAll={() => getDeviceDataAll()}/>
      <Snackbar
        open={toaster}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          severity={reqSuccess === true ? "success" : "warning"}
        >
          {/* <h3 style={{ textAlign: "left" }}>
                        {reqSuccess === true ? "Success" : "Error"}
                    </h3> */}
          {
            errorMessage.length > 0
              ? errorMessage
              : "Something went wrong"
          }
        </Alert>
      </Snackbar>
      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>
      }
    </div>

    // </Provider>

    // </BrowserRouter>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
    paddingRight: "50px",
    paddingLeft: "50px",
  },
  filter: {
    backgroundColor: white,
    color: secondaryTextColor,
    boxShadow: "none",
  },
  addDecoder: {
    backgroundColor: '#427AE5',
    color: white,
    boxShadow: "none",
    fontFamily: "poppins",
    height: "40px",
    width: "200px",
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#427AE5',
      color: white,
      boxShadow: "none"
    },
    // [theme.breakpoints.down('md')]:{
    //     margin:"10px",
    // }
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#FC4141",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px"
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px"
  },
  connectDecoder: {
    backgroundColor: white,
    color: primaryBlue,
    boxShadow: "none",
    height: "40px",
    width: "200px",
    borderColor: primaryBlue,
    border: "2px solid",
    '&:hover': {
      backgroundColor: white,
      color: primaryBlue,
      boxShadow: "none"
    },
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins'
  },
  statusText: {
    width: "62px",
    borderRadius: "10px",
    fontSize: "14px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  status: {
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    height: "45px"
  },
  pageHeading: {
    zIndex: 98,
    // float: top,
    position: "relative",
    margin: "-12px auto",
    borderRadius: "6px",
    width: "200px",
    top: "30px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 8px #0000000A",
    border: "0.48px solid #EDEDED",
    padding: "10px 20px 4px",
    color: "#324054",
  }

}));


export const TOGGLE = {
  DECODE: 'decode',
  DEVICE: 'device'
};
