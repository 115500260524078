import * as React from 'react';
import {useState} from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import {primaryBlue, white} from 'src/components/colors';
import {withStyles} from '@material-ui/core/styles'
import {DropDown} from '../components/DropDown';
import {KitModelDropDown} from '../components/KitModelDropDown';
import {CloseIcon, DownArrowIcon, EditIconSmall, EyeIcon, QuestionIconSmall, UpArrow} from 'src/components/Icons';
import {environment} from 'src/environments/environment';
import {EditKitModel} from '../EditKitModel';
import axios from 'axios';
import {ViewDeviceComponent} from './ViewDevice';


export function MappedKitModel(props) {
  const classes = useStyles();
  const [id, setId] = useState("");
  const [kitName, setKitName] = useState("")
  const [selectKitModel, setSelectKitModel] = useState("")
  const [alertLevel, setAlertLevel] = useState("")
  const [interval, setInterval] = useState("")
  const [isPersistance, setIsPersistance] = useState(false)
  const [devices, setDevices] = useState([])
  const [isMaintenance, setIsMaintenance] = useState(false)
  const [inputMethod, setInputMethod] = useState("")
  const [isAdvancedOption, setIsAdvancedOption] = useState(false)
  const [soilType, setSoilType] = useState("")
  const [isAnotherKit, setIsAnotherKit] = useState(false)
  const [shiftOperation, setShiftOperation] = useState("")
  const [allProperty, setAllProperty] = useState([])
  const [operationsArray, setOperationsArray] = useState([])
  const [isEyeIconClick, setIsEyeIconClick] = useState({kitModel: false, device: false})
  const [selectModel, setSelectModel] = useState()
  const [selectSensor, setSelectSensor] = useState([])
  const [selectActuator, setSelectActuator] = useState([])
  const [selectDevice, setSelectDevice] = useState({})
  const [sensors, setSensors] = useState([])
  let operations = {}

  //Error
  const [idError, setIdError] = useState({error: false, errorMessage: ''})
  const [nameError, setNameError] = useState({error: false, errorMessage: ''})
  const [modelError, setModelError] = useState({error: false, errorMessage: ''})
  const [alertError, setAlertError] = useState({error: false, errorMessage: ''})
  const [deviceError, setDeviceError] = useState({error: false, errorMessage: ''})
  const [intervalError, setIntervalError] = useState({error: false, errorMessage: ''})
  const [shiftErrors, setShiftErrors] = useState<{ [key: number]: string }>({});
  const [persistance, setPersistance] = useState('')

  const propertyCalMethod = ['ADD', 'SUB', 'MUL', 'DIV'];
  const inputMethods = [{name: "Device", value: 'DEVICE'}, {name: "Manual", value: 'MANUAL'}, {
    name: "Image",
    value: 'IMAGE'
  }];
  let user = localStorage.getItem("USER_ID")

  type Kit = {
    id: string;
    name: string;
    alertLevel: string;
    inputMethod: string;
    devices: number[];
    maintain: boolean;
    persistence: boolean;
    metaData?: { propertyNameMap: string; soilType: string };
    shiftMap?: { [key: number]: { operation: string; meta: { pivot: number } } };
    interval:string
  };

  const handleKitId = (event) => {
    const id = event.target.value.trim();
    setId(id);
    setIdError({error: false, errorMessage: ''})
  };

  const handleKitName = (e) => {
    setKitName(e.target.value);
    setNameError({error: false, errorMessage: ''})
  };

  const handleMaintenance = (e) => {
    setIsMaintenance(e.target.value)
    console.log(e.target.value)
  }

  const handlePersistence = (e) => {
    setIsPersistance(e.target.value)
    console.log(e.target.value)
  }

  const handleAlertLevel = (e) => {
    setAlertLevel(e.target.value)
    setAlertError({error: false, errorMessage: ''})
  }

  const handleInterval = (e) => {
    setInterval(e.target.value)
    setIntervalError({error: false, errorMessage: ''})
  }

  const handelEyeIcon = () => {
    setIsEyeIconClick(prevStatus => ({
      ...prevStatus,
      kitModel: true
    }))
    props.setKitModelFormType("Edit")
    let kitModel
    if (selectKitModel == "") {
      console.log(props.lastCreatedKitModel)
      kitModel = props.allKitModels.filter(model => model.name.toLowerCase() == props.lastCreatedKitModel.toLowerCase())

    } else {
      console.log(selectKitModel)
      kitModel = props.allKitModels.filter(model => model.name.toLowerCase() == selectKitModel.toLowerCase())
    }
    console.log(kitModel)
    setSelectModel(kitModel)
  }

  const handelAdvanceOption = () => {
    if (selectKitModel || props.lastCreatedKitModel) {
      const advanceOption = isAdvancedOption
      setIsAdvancedOption(!isAdvancedOption)
      if (advanceOption == false) {
        if (selectKitModel == "") {
          let prop = props.allKitModels
            .filter(model => model.name.toLowerCase() == props.lastCreatedKitModel.toLowerCase());
          setAllProperty(findSensorName(prop[0].properties))
        } else {
          let prop = props.allKitModels
            .filter(model => model.name.toLowerCase() == selectKitModel.toLowerCase());
          setAllProperty(findSensorName(prop[0].properties))
        }
      } else {
      }
    }
  }


  const findSensorName = (codes) => {
    let sensorNames = [];
    codes.forEach(code => {
      let sensor = props.allSensors.find(sensor => sensor.code === code);
      if (sensor) {
        sensorNames.push(sensor.name);
      }
    });

    return sensorNames;
  };

  const handleAnotherKit = () => {
    setIsAnotherKit(!isAnotherKit)
  }

  const removeDevice = (index) => {
    let filterArray = [...devices];
    filterArray.splice(index, 1);
    setDevices(filterArray);
    setDeviceError({error: false, errorMessage: ''})
  }

  const createKit = () => {
    let errors = []
    let newShiftErrors: { [key: number]: string } = {};

    let addError = (field, message) => {
      errors.push({field, message});
    };
    let kitModel = selectKitModel != "" ? props.allKitModels
        .find(model => model.name.toLowerCase() == selectKitModel.toLowerCase())
      : props.allKitModels
        .find(model => model.name.toLowerCase() == props.lastCreatedKitModel.toLowerCase())
    const method = inputMethod ? inputMethods.filter(method => method.name.includes(inputMethod)).map(method => method.value) : inputMethods[0].value

    let propertyMapName = {}
    let shiftMapValue = {}
    let shiftMapArray = []
    if (isAdvancedOption) {
      allProperty.forEach((property, index) => {
        let value = (document.getElementById(`property${index}`) as HTMLInputElement).value;
        let shiftValue = (document.getElementById(`shiftmap${index}`) as HTMLInputElement).value;
        shiftMapArray.push(shiftValue)
        if (value || (shiftMapArray.length != 0 && operationsArray.length != 0)) {
          propertyMapName[index] = `${value}`
          if(operationsArray[index] && parseInt(shiftValue)){
            shiftMapValue[index] = {operation: operationsArray[index], meta: {pivot: parseInt(shiftValue)}}
          }
        } else {
          propertyMapName[index] = ``
          shiftMapValue = {}
        }

      })
    }

    let filterDevice = props.allDevices.filter(device => devices.includes(device.name))
    let kit: Kit = {
      id: id,
      name: kitName,
      alertLevel: alertLevel,
      inputMethod: method.toString(),
      devices: filterDevice.map(d => d.id),
      maintain: isMaintenance,
      persistence: isPersistance,
      interval:interval
    }
    if (isAdvancedOption) {
      kit = {
        ...kit,
        metaData: {propertyNameMap: JSON.stringify(propertyMapName), soilType: soilType},
        shiftMap: shiftMapValue
      }
    }

    if (id == "") {
      addError("id", "Kit ID is Required")
    }
    if (/[,.#$\[\]]/g.test(id)) {
      addError("id", "Please Enter a Valid Id");
    }

    if (kitName == "") {
      addError("name", "Kit Name is Required")
    }
    if (alertLevel == "") {
      addError("alert", "Alert Level is Required")
    }
    if (isNaN(Number(alertLevel)) && alertLevel !== "") {
      addError("alert", "Alert Level Should Contain Only Numbers")
    }
    if (isNaN(Number(interval)) && interval !== "") {
      addError("interval", "interval Should Contain Only Numbers")
    }
    if (selectKitModel == "" && props.lastCreatedKitModel == "") {
      addError("kitModel", "Selecting a Kit Model is Required")
    }
    if (devices.length == 0) {
      addError("device", "Please Select Atleast One Device")
    }
    if (isAdvancedOption) {
      allProperty.forEach((property, index) => {
        let shiftValue = (document.getElementById(`shiftmap${index}`) as HTMLInputElement).value;
        if (shiftValue) {
          if (!operationsArray[index]) {
            newShiftErrors[index] = "Please Select Atleast One Operation"
          } else {
            delete newShiftErrors[index];
          }
        }
      });
    } else {
      newShiftErrors = {};
    }

    setShiftErrors(newShiftErrors);


    let noOfSensorsInDevice = 0;
    let allSensor = []
    filterDevice.map(device => {
      noOfSensorsInDevice = noOfSensorsInDevice + device.noOfSensors;
      device.sensorCodes.map(sensor => allSensor.push(sensor))
    })
    if (kitModel.noOfSensors < noOfSensorsInDevice) {
      addError("device", "Number of Sensors in device exceeds Number of Sensors in Kit")
    }
    for (let x = 0; x < kitModel.noOfSensors; x++) {
      if (kitModel.sensors[x] != allSensor[x] && devices.length != 0) {
        addError("device", "The sensors on the device do not match the sensors defined in the kit model.")
      }
    }
    if (errors.length > 0 || Object.keys(newShiftErrors).length > 0) {
      errors.forEach((error) => {
        const setError = error.field === "id" ? setIdError : error.field === "name" ? setNameError : error.field == 'device' ? setDeviceError : error.field == 'kitModel' ? setModelError : error.field == 'alert' ? setAlertError : setIntervalError;
        setError({ error: true, errorMessage: error.message });
      });

      return;
    }
    props.setLoading(true)
    axios.post(`${environment.host}/core/user/${user}/kit-model/${kitModel.id}/kit`, kit, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
      },
    })
      .then((response) => {
        console.log(response.data.content)
        if (response.data.message === 'Success') {
          props.setIsToasterOpen(true)
          props.setToasterMessage("Kit Created Successfully")
          props.setReqSuccess(true)
          props.getKitModels()
          setTimeout(() => {
            props.setIsToasterOpen(false);
          }, 4000);
          if (isAnotherKit) {
            setId("")
            setKitName("")
            setSelectKitModel("")
            setDevices([])
            setAlertLevel("")
            setInterval("")
            setIsMaintenance(false)
            setIsPersistance(false)
            setSoilType("")
            setInputMethod("")
            setIsAdvancedOption(false)
            setIsAnotherKit(false)
          } else {
            props.increaseStep()
            console.log(response.data.content.id)
            props.setLastKitId(response.data.content.id)
          }
          props.setLoading(false)
        }
      })
      .catch((error) => {
        props.getKitModels()
        props.setIsToasterOpen(true)
        props.setToasterMessage(error.response.data.message)
        props.setReqSuccess(false)
        props.setLoading(false)
        if (error.response.data.errorCode === 'E4091'){
          props.decreaseStep()
          props.setIsSelectStep3(true)
        }
      })
      .finally(() => {
        setTimeout(() => {
          props.setIsToasterOpen(false);
        }, 4000);
      });

  }

  const handleDropDownChange = () => {
    console.log(shiftOperation)
  }

  const handleCancelButton = () => {
    const isSelectKitModel = props.isSelectKitModel
    const isExistKitModel = props.isExistKitModel
    if (isSelectKitModel) {
      props.setIsApiClick(false)
      props.setIsSelectKitModel(false)
    } else if (!isExistKitModel) {
      props.setIsExistKitModel(true)
      props.setIsSelectStep3(true)
      props.setIsApiClick(false)
    } else {
      props.setSteps([
        {
          text: "Define Kit Model",
          activate: true,
        },
        {
          text: "Mapped Device",
          activate: false,
        },
        {
          text: "Swagger",
          activate: false,
        },
      ])
      props.setIsExistKitModel(true)
    }

  }

  const clearShiftError = (index: number) => {
    setShiftErrors(prevErrors => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index];
      return updatedErrors;
    });
  };

  const viewDevice = (id) => {
    props.setLoading(true)
    const deviceId = props.allDevices.find(device => device.name == id).id
    console.log(deviceId)
    axios({
      url: `${environment.host}/core/device/${deviceId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
      },
    })
      .then(res => {
        const response = res.data.content
        setSelectDevice(response)
        setPersistance(response.persistence.toString())
        setIsEyeIconClick(prevState => ({
          ...prevState,
          device: true
        }))
        if (response.sensorCodes) {
          const sensor = response.sensorCodes.map(val => {
            return props.allSensors.filter(s => s.code === val)[0];
          });
          setSelectSensor(sensor)
        }
        if (response.actuatorCodes) {
          const sensor = response.actuatorCodes.map(val => {
            return props.allActuators.filter(s => s.code === val)[0];
          });
          setSelectActuator(sensor)
        }
        props.setLoading(false);
      })
      .catch(error => {
        props.setLoading(false);
        console.log('Get Device Error', error.message)
      })
  }

  const handleSoilType = (e) => {
    setSoilType(e)
  }

  let device = []
  const handleDevices = (e) => {
    const sensors = props.allDevices.find(device => device.name == e).sensorCodes
    device.push(...devices, e)
    setDevices(device)
    if (device.length != 0) {
      setDeviceError({error: false, errorMessage: ''})
    }
    sensors.map(s => setSensors([...(props.sensors || []), s]));

  }

  const handleInputMethod = (e) => {
    setInputMethod(e)
  }


  return (
    <div className={`testing`} style={{margin: '0', paddingTop: "50px"}}>
      <Grid container spacing={5}>
        <Grid item xs={5} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={1} md={2}>
              <Typography className={classes.text}>
                Kit ID
                <span className={classes.required}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}></Grid>
            <Grid item xs={4} md={8}>
              <FormControl className={classes.input}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder="Enter Kit ID"
                  onChange={(e) => handleKitId(e)}
                  value={id}
                />
              </FormControl>
            </Grid>
          </Grid>
          {idError.error ?
            <Grid container>
              <Grid item xs={5} md={3}></Grid>
              <Grid item xs={4} md={8}>
                <Grid container justifyContent='flex-end'>
                  <Typography className={classes.errorText}>{idError.errorMessage}</Typography>
                </Grid>

              </Grid>
            </Grid>
            :
            <></>
          }
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={5} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={1} md={2}>
              <Typography className={classes.text}>
                Kit Name
                <span className={classes.required}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}></Grid>
            <Grid item xs={4} md={8}>
              <FormControl className={classes.input}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder="Enter Kit Name"
                  onChange={(e) => handleKitName(e)}
                  value={kitName}
                />
              </FormControl>
            </Grid>
          </Grid>
          {nameError.error ?
            <Grid container>
              <Grid item xs={5} md={3}></Grid>
              <Grid item xs={4} md={8}>
                <Grid container justifyContent='flex-end'>
                  <Typography className={classes.errorText}>{nameError.errorMessage}</Typography>
                </Grid>

              </Grid>
            </Grid>
            :
            <></>
          }
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={5} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={1} md={2}>
              <Typography className={classes.text}>
                Kit Model
                <span className={classes.required}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}></Grid>
            <Grid item xs={4} md={8}>
              <FormControl className={classes.input}>
                <input
                  type="text"
                  value={props.lastCreatedKitModel}
                  className={classes.formInput}
                  readOnly={true}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container style={{marginTop: '5px'}}>
            <Grid item xs={3} md={3}></Grid>
            <Grid item xs={4} md={8} style={{
              pointerEvents: props.lastCreatedKitModel || selectKitModel ? 'auto' : 'none',
              opacity: props.lastCreatedKitModel || selectKitModel ? 1 : 0.5,
              cursor: props.lastCreatedKitModel || selectKitModel ? 'pointer' : 'default'
            }}>
              <Grid container justifyContent='flex-end' onClick={() => handelEyeIcon()}>
                <EyeIcon color={"black"} width={"22"} height={"20"}/>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={5} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={1} md={2}>
              <Typography className={classes.text}>
                Alert Level
                <span className={classes.required}>*</span>
                <Tooltip
                  title="Max no of alerts that the kit model can have"
                  classes={{tooltip: classes.Tooltip}}
                >
                  <IconButton style={{padding: "2px"}}>
                    <QuestionIconSmall/>
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}></Grid>
            <Grid item xs={4} md={8}>
              <FormControl className={classes.input}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder="Enter ALert Level"
                  onChange={(e) => handleAlertLevel(e)}
                  value={alertLevel}
                />
              </FormControl>
            </Grid>
          </Grid>
          {alertError.error ?
            <Grid container>
              <Grid item xs={5} md={3}></Grid>
              <Grid item xs={4} md={8}>
                <Grid container justifyContent='flex-end'>
                  <Typography className={classes.errorText}>{alertError.errorMessage}</Typography>
                </Grid>

              </Grid>
            </Grid>
            :
            <></>
          }
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={5} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={1} md={2}>
              <Typography className={classes.text}>
                interval
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}></Grid>
            <Grid item xs={4} md={8}>
              <FormControl className={classes.input}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder="Enter interval"
                  onChange={(e) => handleInterval(e)}
                  value={interval}
                />
              </FormControl>
            </Grid>
          </Grid>
          {intervalError.error ?
            <Grid container>
              <Grid item xs={5} md={3}></Grid>
              <Grid item xs={4} md={8}>
                <Grid container justifyContent='flex-end'>
                  <Typography className={classes.errorText}>{intervalError.errorMessage}</Typography>
                </Grid>

              </Grid>
            </Grid>
            :
            <></>
          }
        </Grid>
      </Grid>
      <Grid container spacing={5} style={{height: '160px'}}>
        <Grid item xs={5} md={12}>
          <Grid container>
            <Grid item xs={1} md={2}>
              <Typography className={classes.text}>
                Devices
                <span className={classes.required}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}></Grid>
            <Grid item xs={4} md={8}>
              <FormControl className={classes.input}>
                <Grid container>
                  {devices.length == 0 ?
                    <Grid container>
                      <input
                        className={classes.formInput}
                        type="text"
                        placeholder={devices.length != 0 ? "" : "No Devices Added"}
                        style={{
                          height: '80px',
                          marginBottom: '10px',
                          width: '100%',
                          textAlign: 'center',
                          border: '0.5px solid  #5E5C5C',
                          borderRadius: '5px'
                        }}
                        // value={sensorValue}
                        disabled={true}
                      />
                    </Grid>
                    :
                    <Grid container style={{border: '0.5px solid', height: '80px', padding: '10px', overflowY: 'auto'}}>
                      {devices.map((sensor, index) => {
                        return (
                          <Grid container key={index} style={{
                            border: '0.5px solid',
                            width: 'auto',
                            borderRadius: '5px',
                            padding: '2px 6px',
                            alignItems: 'center',
                            margin: '0 5px 2px 0',
                            height: '30px'
                          }}>
                            <Grid item style={{padding: '2px 5px'}}>{index + 1}</Grid>
                            <Grid item style={{borderLeft: '0.5px solid #2A7BEC', height: '28px'}}></Grid>
                            <Grid item style={{padding: '2px 5px'}}>{sensor}</Grid>
                            <Grid onClick={() => viewDevice(sensor)} style={{marginRight: '5px', paddingTop: '5px'}}>
                              <EyeIcon color={"black"} width={"18"} height={"15"}/>
                            </Grid>
                            <Grid item onClick={() => removeDevice(index)}><CloseIcon/></Grid>
                          </Grid>
                        )

                      })

                      }
                    </Grid>
                  }
                </Grid>
                {props.allDevices && <DropDown
                  options={props.allDevices.map(device => device.name)}
                  type='devices'
                  emptyTag='Add Devices'
                  isAddRequired={false}
                  setDevices={setDevices}
                  devices={devices}
                  setDeviceError={setDeviceError}
                  kitModelFormType={props.kitModelFormType}
                  allDevices={props.allDevices}
                  setSensors={setSensors}
                  sensors={sensors}
                  onClick = {(event) => handleDevices(event)}
                /> }
                
              </FormControl>
            </Grid>
          </Grid>
          {deviceError.error ?
            <Grid container>
              <Grid item xs={5} md={3}></Grid>
              <Grid item xs={4} md={8}>
                <Grid container justifyContent='flex-end'>
                  <Typography className={classes.errorText}>{deviceError.errorMessage}</Typography>
                </Grid>

              </Grid>
            </Grid>
            :
            <></>
          }
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={5} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={1} md={2}>
              <Typography className={classes.text}>
                Persistence
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}></Grid>
            <Grid item xs={4} md={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}>
              <FormControl>
                <RadioGroup row defaultValue='true' onChange={(e) => handlePersistence(e)}>
                  <FormControlLabel
                    value="true"
                    control={<BlueRadio/>}
                    label="True"
                  />
                  <FormControlLabel
                    value="false"
                    control={<BlueRadio/>}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={5} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={1} md={2}>
              <Typography className={classes.text}>
                Maintenance
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}></Grid>
            <Grid item xs={4} md={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}>
              <FormControl>
                <RadioGroup row defaultValue='false' onChange={(e) => handleMaintenance(e)}>
                  <FormControlLabel
                    value="true"
                    control={<BlueRadio/>}
                    label="True"
                  />
                  <FormControlLabel
                    value="false"
                    control={<BlueRadio/>}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={5} style={{height: '80px'}}>
        <Grid item xs={5} md={12}>
          <Grid container>
            <Grid item xs={1} md={2}>
              <Typography className={classes.text}>
                Input Method
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}></Grid>
            <Grid item xs={4} md={8}>
              <FormControl className={classes.input}>
                <DropDown options={inputMethods.map(method => method.name)} type='option'
                          placeholder={inputMethods[0].name} setInputMethod={setInputMethod} isAddRequired={false}
                          isSingle={true} value={inputMethod}
                          onClick = {(event) => handleInputMethod(event)}/>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={5} style={{marginBottom: '20px'}}>
        <Grid item xs={1} md={3}>
          <Typography className={classes.text}>Advance Option</Typography>
        </Grid>
        <Grid item xs={5} md={8} style={{marginTop: '13px', marginLeft: '-30px'}}>
          <hr/>
        </Grid>
        <Grid item onClick={() => handelAdvanceOption()} style={{cursor: 'pointer', paddingLeft: '0px'}}>
          {isAdvancedOption ?
            <Grid item xs={1} md={1}>

              <UpArrow/>
            </Grid>

            :
            <Grid item xs={1} md={1}>
              <DownArrowIcon/>

            </Grid>
          }
        </Grid>
      </Grid>
      {isAdvancedOption ?
        <div style={{marginTop: '20px'}}>
          <Grid container spacing={5} style={{minHeight: '80px', overflowY: 'auto'}}>
            <Grid item xs={5} md={12}>
              <Grid container>
                <Grid item xs={1} md={2}>
                  <Typography className={classes.text}>
                    Property Mapping
                  </Typography>
                </Grid>
                <Grid item xs={4} md={1}></Grid>
                <Grid item xs={4} md={8}>
                  <Grid container
                        style={{border: '0.5px solid #55555580', borderRadius: '5px', backgroundColor: white}}>
                    {allProperty.map((property, index) => (
                      <Grid container key={index} style={{
                        borderBottom: '0.5px solid #55555580',
                        minHeight: '42px',
                        maxHeight: '60px',
                        paddingLeft: '5px'
                      }} alignItems='center'>
                        <Grid item style={{marginRight: '6px', textAlign: 'left'}}>
                          <Typography>{index + 1}</Typography>
                        </Grid>
                        <Grid item style={{borderLeft: '0.5px solid #2A7BEC', height: '32px'}}></Grid>
                        <Grid item xs={2} md={4} style={{textAlign: 'left', paddingLeft: '5px'}}>
                          <Typography>{property} {index + 1}</Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Grid container style={{border: '0.5px solid #55555580', borderRadius: '5px'}}>
                            <Grid item xs={7} md={11} style={{width: '100%', paddingLeft: '5px', textAlign: 'left'}}>
                              <input
                                type="text"
                                style={{border: 'none', width: '100%'}}
                                // onChange={(e)=>handlePropertyNameMap(e,index)}
                                id={`property${index}`}
                              />
                            </Grid>
                            <Grid item xs={2} md={1}>
                              <EditIconSmall/>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}


                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={5} style={{minHeight: '80px'}}>
            <Grid item xs={5} md={12}>
              <Grid container>
                <Grid item xs={1} md={2}>
                  <Typography className={classes.text}>
                    Shift Mapping
                  </Typography>
                </Grid>
                <Grid item xs={4} md={1}></Grid>
                <Grid item xs={4} md={8}>
                  <Grid container
                        style={{border: '0.5px solid #55555580', borderRadius: '5px', backgroundColor: white}}>
                    {allProperty.map((property, index) => (
                      <Grid container key={index} style={{
                        borderBottom: '0.5px solid #55555580',
                        minHeight: '32px',
                        maxHeight: '60px',
                        padding: '15px 0px 5px 5px',
                        position: 'relative'
                      }}>
                        <Grid item style={{marginRight: '6px', textAlign: 'left'}}>
                          <Typography>{index + 1}</Typography>
                        </Grid>
                        <Grid item style={{borderLeft: '0.5px solid #2A7BEC', height: '32px'}}></Grid>
                        <Grid item xs={2} md={4} style={{textAlign: 'left', paddingLeft: '5px'}}>
                          <Typography>{property} {index + 1}</Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Grid container>
                            <Grid item xs={4} md={4}></Grid>
                            <Grid item xs={4} md={4}>
                              <input
                                type="text"
                                placeholder='Enter Value'
                                style={{width: '90px'}}
                                className={classes.mappingDropDown}
                                id={`shiftmap${index}`}
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </Grid>
                            <Grid item xs={1} md={1}></Grid>
                            <Grid item xs={3} md={3} onChange={() => handleDropDownChange()}>
                              <KitModelDropDown
                                className={classes.mappingDropDown}
                                options={propertyCalMethod}
                                index={index}
                                // onSelect={(operation) => handleDropDownChange(operation, index)}
                                setShiftOperation={setShiftOperation}
                                setOperationsArray={setOperationsArray}
                                operationsArray={operationsArray}
                                operations={operations}
                                clearShiftError={clearShiftError}
                              />
                            </Grid>
                          </Grid>
                          {shiftErrors[index] ? (
                            <Grid container style={{ position: 'absolute', top: '35px', right: '0' }}>
                                <Grid item xs={5} md={3}></Grid>
                                <Grid item xs={4} md={12}>
                                    <Grid container justifyContent='flex-end'>
                                        <Typography className={classes.shiftErrorText}>{shiftErrors[index]}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            ) : null
                          }
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        : <></>
      }
      <Grid container spacing={5}>
        <Grid item xs={5} md={12}>
          <Grid container>
            <Grid item>
              <Typography className={classes.text}>
                Add Another Kit
              </Typography>
            </Grid>
            <Grid item xs={1} md={1} style={{marginLeft: '20px', display: 'flex'}}>
              <Grid container justifyContent='flex-start' alignItems='center'>
                <input type="checkbox" style={{display: 'inline'}} onChange={() => handleAnotherKit()}
                       checked={isAnotherKit}/>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={5} md={12}>
          <Grid container>
            <Grid item xs={5} md={3}></Grid>
            <Grid item xs={4} md={8}>
              <Grid container justifyContent='flex-end'>
                <Grid item style={{
                  border: '0.5px solid #555555',
                  borderRadius: '5px',
                  padding: '5px 30px',
                  marginRight: '10px',
                  cursor: 'pointer'
                }} onClick={() => handleCancelButton()}>Cancel</Grid>
                <Grid item style={{
                  borderRadius: '5px',
                  padding: '5px 30px',
                  color: white,
                  backgroundColor: primaryBlue,
                  cursor: 'pointer'
                }} onClick={() => createKit()}>Create Kit</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      {isEyeIconClick.kitModel && props.allTypes ?
        <EditKitModel
          setIsApiClick={props.setIsApiClick}
          increaseStep={props.increaseStep}
          decreaseStep={props.decreaseStep}
          allSensors={props.allSensors}
          allActuators={props.allActuators}
          allTypes={props.allTypes}
          allKitModelsName={props.allKitModels}
          setIsToasterOpen={props.setIsToasterOpen}
          setToasterMessage={props.setToasterMessage}
          setReqSuccess={props.setReqSuccess}
          getKitModels={props.getKitModels}
          setLastCreatedKitModel={props.setLastCreatedKitModel}
          setLoading={props.setLoading}
          setIsEyeIconClick={setIsEyeIconClick}
          kitModelFormType={props.kitModelFormType}
          selectModel={selectModel}
          setKitModelFormType={props.setKitModelFormType}
        />
        : isEyeIconClick.device ?
          <ViewDeviceComponent
            selectDevice={selectDevice}
            classes={classes}
            isEyeIconClick={setIsEyeIconClick}
            selectSensors={selectSensor}
            selectActuator={selectActuator}
            radio={<BlueRadio/>}
            persistance={persistance}
          />
          :
          <></>

      }
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
  },
  text: {
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif"
  },
  required: {
    color: "red",
  },
  label: {
    minWidth: 150,
    textAlign: 'left',
  },
  formInput: {
    height: "38px",
    borderRadius: "5px",
    padding: "10px 15px",
    fontFamily: "'Poppins', sans-serif",
    border: '0.5px solid #5E5C5C'
  },
  saveButton: {
    width: '100px',
    borderRadius: '8px',
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
  },
  toggleButton: {
    padding: '10px 20px',
    backgroundColor: '#dddddd',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
    borderRadius: '50%',
    width: '100px'
  },
  errorText: {
    color: '#FF4E4E',
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: 400
  },
  shiftErrorText:{
    color: '#FF4E4E',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    padding: '3px'
  },

  toggleButtonOn: {
    backgroundColor: primaryBlue,
    color: white
  },

  toggleButtonOff: {
    backgroundColor: '#D9D9D9',
    color: white
  },
  mappingDropDown: {
    height: '24px',
    backgroundColor: white,
    padding: '3px 10px',
    border: '0.5px solid #55555580',
    borderRadius: '5px',
    fontSize: '12px',
    fontFamily: 'poppins'
  },
  option: {
    paddingLeft: '10px',
    height: '33px',
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  Tooltip: {
    backgroundColor: "#334B5A",
    color: 'white',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '12px',
  }
}));


const BlueRadio = withStyles({
  root: {
    color: '#324054', // Change to your desired blue color
    '&$checked': {
      color: '#1976D2', // Change to your desired blue color
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);








