import * as React from 'react';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import { Grid, InputBase, Button, Typography, IconButton} from '@material-ui/core';
import { lightGray} from 'src/components/colors';
import { ThemeProvider, createTheme} from '@material-ui/core/styles';
import { DropDown } from '../components/DropDown';
import useStyles from '../../assets/styles';
import { TextBar } from 'src/components/sub-components/TextBar';
import { formatDateString } from 'src/app/testing-automation/api-helper/apiHelper';
import { 
  fetchBatchNumbers,
  fetchMainTestCases,
  startTesting
} from '../components/apiHelper'; 
import { Loader } from '../components/Loader';
import { ToasterComponent } from '../components/Toaster';
import DeviceConfig from './DeviceConfig';
import {DecreaseIcon, IncreaseIcon } from 'src/components/Icons';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function StartTestingComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [selectedStreamingMethod, setSelectedStreamingMethod] = useState('');
  const [selectedTestCaseType, setSelectedTestCaseType] = useState('');
  const [selectedRepeatPeriod, setSelectedRepeatPeriod] = useState('');
  const [streamingMethods, setStreamingMethods] = useState([
    "SenzMatica", "MQTT", "AWS", "Rest API", "Azure IoT"
  ]);
  const [testCaseTypes, setTestCaseTypes] = useState([
    "Production Feasibility Testing", "Continuous Testing"
  ]);
  const [repeatPeriod, setRepeatPeriod] = useState([
    "Hours","Days","Weeks","Months"
  ]);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [mainTestCasesTitles, setMainTestCasesTitles] = useState([]);
  const [mainTestCasesContent, setMainTestCasesContent] = useState([]);
  const [selectedMainTestCaseTitle, setSelectedMainTestCaseTitle] = useState('');
  const [selectedMainTestCaseId, setSelectedMainTestCaseId] = useState('');
  const [subTestCaseTitles, setSubTestCaseTitles] = useState([]);
  const [selectedSubTestCasesTitles, setSelectedSubTestCasesTitles] = useState([]);
  const [relatedSubTestCases, setRelatedSubTestCases] = useState([]);
  const [filteredSubTestCases, setFilteredSubTestCases] = useState([]);
  const [filteredSubTestCasesIds, setFilteredSubTestCasesIds] = useState([]);
  const [devices, setDevices] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [repeatValue, setRepeatValue] = useState(1);

  //Toaster
  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  //handel errors
  const [errors, setErrors] = useState({
    streamNameError: null,
    batchNoError: null,
    mainTestNameError: null,
    subTestCasesError: null,
    startDateError: null,
    endDateError: null,
    testingMethodError: null,
    repeatPeriodError: null
  })   
  
  const streamingMethodsMap = {
    "SenzMatica":"SENZMATE", 
    "MQTT":"MQTT",
    "AWS":"AWS", 
    "Rest API":"RESTAPI", 
    "Azure IoT":"AZUREIOT"
  };

  useEffect(() => {
    const getBatchNumbers = async () => {
      if (selectedStreamingMethod) {
        try {
          const batchNumbers = await fetchBatchNumbers();
          setBatchNumbers(batchNumbers);

          if(batchNumbers.length > 0){
            setSelectedBatch(batchNumbers[0]);
            handleSelectBatchNumber(batchNumbers[0]);
          }
        } catch (err) {
          console.error('Error fetching batch numbers:', err);
        }
      }
    };
    getBatchNumbers();
  }, [selectedStreamingMethod]);  
  
  const handleSelectStreamingMethod = (value) => {
    setSelectedStreamingMethod(value);
    handleError('streamNameError')
  };

  const handleSelectBatchNumber = async (value) => {
    if (selectedStreamingMethod) {
      setErrors(prevErrors => ({
        ...prevErrors,
        streamNameError: null
      }));
    }
    setSelectedBatch(value);
    handleError('batchNoError')
    try {
      const { mainTestTitles, content, uniqueDevices} = await fetchMainTestCases(value);
      const uniqueMainTestTitles = [...new Set(mainTestTitles)];

      setMainTestCasesTitles(uniqueMainTestTitles);
      setMainTestCasesContent(content);
      setDevices(uniqueDevices);
    } catch (error) {
      console.error('Error fetching main test cases:', error);
    }
  };

  const handleSelectTestingMethod = (value) => {
    setSelectedTestCaseType(value);
    handleError('testingMethodError')
  };

  const handleSelectRepeatPeriod = (value) => {
    setSelectedRepeatPeriod(value);
    handleError('repeatPeriodError')
  };

  const handleIncrease = () => {
    setRepeatValue(prevValue => prevValue + 1);
  };
  
  const handleDecrease = () => {
    if (repeatValue > 1) {
      setRepeatValue(prevValue => prevValue - 1);
    }
  };

  // Handle click on batch No dropdown
  const onBatchDropClick = () => {
    if (selectedStreamingMethod === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        streamNameError: "Data Streaming Method is Required"
      }));
    }
  };
  
  // Handle click on the main test case dropdown
  const onMainDropClick = () => {
    if (selectedBatch === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        batchNoError: "Batch is required"
      }));
    }
  };

  // Handle click on the sub test case dropdown
  const onSubsDropClick = () => {
    if (selectedMainTestCaseTitle === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        mainTestNameError: "Main Test Case Name is required"
      }));
    }
  };
  
  const handleSelectMainTestCase = (value) => {
    if (selectedBatch) {
      setErrors(prevErrors => ({
        ...prevErrors,
        batchNoError: null
      }));
    }
    setSelectedMainTestCaseTitle(value);
    handleError('mainTestNameError')

    const selectedMainTestCase = mainTestCasesContent.find(testCase => 
      testCase.mainTestTitle === value
    );
    if (selectedMainTestCase) {
      setSelectedMainTestCaseId(selectedMainTestCase.id);
      const subTestTitles = selectedMainTestCase.subTestCases.map(subTestCase => 
        subTestCase.subTestTitle
      );
      setSubTestCaseTitles(subTestTitles);
      setRelatedSubTestCases(selectedMainTestCase.subTestCases); 
    } 
    else {
      setSubTestCaseTitles([]);
      setRelatedSubTestCases([]); 
    }
  };


  const handleSelectSubTestCase = (selectedTitles) => {
    if (selectedMainTestCaseTitle) {
      setErrors(prevErrors => ({
        ...prevErrors,
        mainTestNameError:null
      }));
    }
    setSelectedSubTestCasesTitles(selectedTitles);
    handleError('subTestCasesError')

    const filteredCases = relatedSubTestCases.filter(subTestCase => 
      selectedTitles.includes(subTestCase.subTestTitle)
    );
    setFilteredSubTestCases(filteredCases);
  
    // Extract the IDs of the filtered subtest cases
    const filteredCaseIds = filteredCases.map(subTestCase => subTestCase.id);
    setFilteredSubTestCasesIds(filteredCaseIds);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = formatDateString(e.target.value);
    if (newStartDate > endDate) {
      setEndDate("");
    }
    setStartDate(newStartDate);
    handleError('startDateError')
  };
  
  const handleEndDateChange = (e) => {
    const newEndDate = formatDateString(e.target.value);
    if (newEndDate < startDate) {
      setStartDate("");
    }
    setEndDate(newEndDate);
    handleError('endDateError')
  };

  const convertToHours = (value: number, period: string): number => {
    switch (period) {
      case "Days":
        return value * 24;
      case "Weeks":
        return value * 24 * 7;
      case "Months":
        return value * 24 * 30;
      case "Hours":
      default:
        return value;
    }
  };
  
  const handleStartTesting = async () => {
    const validationError = validationForm()
    setErrors(validationError)
    if (Object.keys(validationError).length > 0) {
      return
    }

    const selectedMainTestCase = mainTestCasesContent.find(testCase => testCase.mainTestTitle === selectedMainTestCaseTitle);
    const convertedIntervalInHours = convertToHours(repeatValue, selectedRepeatPeriod);

    const basePayload = {
      batchNumber: selectedBatch,
      status: null,
      mainTestTitle: selectedMainTestCaseTitle,
      subTestCases: filteredSubTestCases,
      subTestCasesIdList: filteredSubTestCasesIds,
      dataStreamingMethod: streamingMethodsMap[selectedStreamingMethod],
      expectedInterval: 1,
      startDate,
      description: selectedMainTestCase.description,
    };
  
    const payload = selectedTestCaseType === "Production Feasibility Testing" 
      ? {
          ...basePayload,
          endDate,
        }
      : {
          ...basePayload,
          testCaseType: "CONTINUOUS",
          timeIntervalInHours: convertedIntervalInHours,
          continuousTestEndType: "NEVER"
        };

    try {
      await startTesting(
          payload, 
          setLoading, 
          setIsToasterOpen, 
          setToasterMessage, 
          setReqSuccess,
          selectedTestCaseType,
          convertedIntervalInHours,
          history
      );
    } catch (error) {
      console.error('Error starting test case:', error);
    }
  };

  const validationForm = () => {
    let error: any = {}
    if (selectedStreamingMethod== "") {
      error.streamNameError = "Data Streaming Method is Required"
    }
    if (selectedBatch == "") {
      error.batchNoError = "Batch is required"
    }
    if (selectedMainTestCaseTitle == "") {
      error.mainTestNameError = "Main Test Case Name is required"
    }
    if (selectedSubTestCasesTitles.length === 0) {
      error.subTestCasesError = "At least one sub-test case is required.";
    }
    if (selectedTestCaseType == "") {
      error.testingMethodError = "Testing Method is Required"
    }
    if (startDate== "") {
      error.startDateError = "Start Date and Time is required"
    }
    if (selectedTestCaseType === "Production Feasibility Testing" && endDate === "") {
      error.endDateError = "End Date and Time is required";
    }
    if (selectedTestCaseType === "Continuous Testing" && selectedRepeatPeriod == "") {
      error.repeatPeriodError = "Repeat period is required"
    }
    return error
  }

  const handleError = (name) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  }

  const resetFormFeilds = () =>  {
    setSelectedStreamingMethod('');
    setSelectedBatch('');
    setSelectedMainTestCaseTitle('');
    setSelectedSubTestCasesTitles([]);
    setInterval('');
    setStartDate('');
    setEndDate('');
  }

  const handleBack = () => {
    resetFormFeilds();
    history.push(`/TestAutomation`);
  }


  return (
    <ThemeProvider theme={theme}>
      <div className="testing">
        <div className="page-heading" style={{ marginBottom: "50px" }}>
          Step 03
        </div>
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            md={12}
            style={{
              textAlign: "center",
              paddingBottom: "40px",
            }}
          >
            <h4>Start Testing</h4>
          </Grid>
        </Grid>
  
        <Grid container className={classes.root}>
          <Grid item md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Grid container className={classes.formContent}>
              {/* Data Streaming Method */}
              <Grid
                item
                container
                xs={12}
                md={6}
                alignItems="center"
                spacing={2}
                style={{ marginBottom: "20px" }}
              >
                <Grid item xs={12} md={6}>
                  <Typography className={classes.typo}>
                    Data Streaming Method: <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DropDown
                    options={streamingMethods}
                    type='streamingMethod'
                    emptyTag='-Select-'
                    setSelectOption={handleSelectStreamingMethod}
                    value={selectedStreamingMethod}
                    handleError={handleError}
                    isSingle
                  />
                </Grid>
                {errors.streamNameError && (
                  <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ padding: "0px 8px" }}
                  >
                    <Typography className={classes.errorText}>
                      {errors.streamNameError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
  
              {/* New Batch */}
              <Grid
                item
                container
                xs={12}
                md={6}
                alignItems="center"
                spacing={2}
                style={{ marginBottom: "20px" }}
              >
                <Grid item md={2} style={{ padding: "0px" }}></Grid>
                <Grid item xs={12} md={3}>
                  <Typography className={classes.typo}>
                    Test Batch:
                    <span style={{ color: 'red' }}>*</span>
                  </Typography> 
                </Grid>
                <Grid item xs={12} md={7}>
                  <DropDown
                    options={batchNumbers.map(batch => batch)}
                    type='batchNumber'
                    emptyTag='-Select-'
                    setSelectOption={handleSelectBatchNumber}
                    value={selectedBatch}
                    onBatchDropClick={onBatchDropClick}
                    isSingle
                  />
                </Grid>
                {errors.batchNoError && (
                  <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ padding: "0px 8px" }}
                  >
                    <Typography className={classes.errorText}>
                      {errors.batchNoError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
  
              {/* Main Test Case Name */}
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography className={classes.typo}>
                    Main Test Case Name: 
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>  
                </Grid>
                <Grid item xs={12} md={9}>
                  <DropDown
                    options={mainTestCasesTitles}
                    type='mainTestCase'
                    emptyTag='-Select-'
                    setSelectOption={handleSelectMainTestCase}
                    value={selectedMainTestCaseTitle}
                    isSingle
                    onMainDropClick={onMainDropClick}
                  />
                  {errors.mainTestNameError && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <Typography className={classes.errorText}>
                        {errors.mainTestNameError}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
  
              {/* Sub Test Case Name */}
              <Grid
                container
                alignItems="center"
                spacing={2}
                style={{ marginTop: "20px" }}
              >
                <Grid item xs={12} md={3}>
                  <Typography className={classes.typo}>
                    Sub Test Case Name: 
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>  
                </Grid>
                <Grid item xs={12} md={9}>
                  <DropDown
                    options={subTestCaseTitles}
                    type='subTestCase'
                    emptyTag='-Select-'
                    setSelectOption={handleSelectSubTestCase}
                    value={selectedSubTestCasesTitles}
                    isSingle={false}
                    onSubsDropClick={onSubsDropClick}
                  />
                  {errors.subTestCasesError && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <Typography className={classes.errorText}>
                        {errors.subTestCasesError}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* Testing Method */}
              <Grid
                container
                alignItems="center"
                spacing={2}
                style={{ marginTop: "20px" }}
              >
                <Grid item xs={12} md={3}>
                  <Typography className={classes.typo}>
                    Testing Method: 
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>  
                </Grid>
                <Grid item xs={12} md={9}>
                  <DropDown
                    options={testCaseTypes}
                    type='testingMethod'
                    emptyTag='-Select-'
                    setSelectOption={handleSelectTestingMethod}
                    value={selectedTestCaseType}
                    handleError={handleError}
                    isSingle
                  />
                  {errors.testingMethodError && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <Typography className={classes.errorText}>
                        {errors.testingMethodError}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {selectedTestCaseType === "Production Feasibility Testing" && (
                <Grid container spacing={2} style={{ marginTop: "20px"}}>
                  <Grid item md={3}></Grid>
                  <Grid item xs={12} md={9} style={{borderRadius: "5px", backgroundColor: lightGray }}>
                    <Grid container alignItems="center" spacing={2} style={{ margin: "20px" }}>
                      
                      {/* Start Time */}
                      <Grid item xs={12} md={2}>
                        <Typography className={classes.typo}>
                          Start Time: <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={11} md={9}>
                        <TextBar
                          onChange={handleStartDateChange}
                          error={{error: false, errorMsg: ""}}
                          id="datetime-local"
                          label="Next appointment"
                          type="datetime-local"
                          defaultValue={startDate}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.startDateError && (
                          <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                              {errors.startDateError}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>

                      {/* End Time */}
                      <Grid item xs={12} md={2}>
                        <Typography className={classes.typo}>
                          End Time: <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={11} md={9}>
                        <TextBar
                          onChange={handleEndDateChange}
                          error={{error: false, errorMsg: ""}}
                          id="datetime-local"
                          label="Next appointment"
                          type="datetime-local"
                          defaultValue={endDate}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.endDateError && (
                          <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                              {errors.endDateError}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {selectedTestCaseType === "Continuous Testing" && (
                <Grid container spacing={2} style={{ marginTop: "20px"}}>
                  <Grid item md={3}></Grid>
                  <Grid item xs={12} md={9} style={{borderRadius: "5px", backgroundColor: lightGray }}>
                    <Grid container alignItems="center" spacing={2} style={{ margin: "20px" }}>

                      <Grid item xs={12} md={12}>
                        <Typography className={classes.typo}>
                          Report/Notification Frequency
                        </Typography>
                      </Grid>
                      
                      {/* Start Time */}
                      <Grid item xs={12} md={3}>
                        <Typography className={classes.typo}>
                          Start Time: <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={11} md={8}>
                        <TextBar
                          onChange={handleStartDateChange}
                          error={{error: false, errorMsg: ""}}
                          id="datetime-local"
                          label="Next appointment"
                          type="datetime-local"
                          defaultValue={startDate}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.startDateError && (
                          <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                              {errors.startDateError}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>

                      {/* Repeat*/}
                      <Grid item xs={12} md={3}>
                        <Typography className={classes.typo}>
                          Repeat Every: <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5} md={4}>
                        <Grid container alignItems="center" spacing={1} 
                          style={{ border: '1px solid #A9A9A9', borderRadius: '4px', marginLeft: '1px' }}
                        >
                          <Grid item xs={8} md={8} style={{ padding: '0px' }}>
                            <InputBase
                              className={classes.formInput}
                              name="repeatValue"
                              value={repeatValue}
                              readOnly
                            />
                          </Grid>
                          <Grid item xs={4} md={4} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '4px' }}>
                            <IconButton size="small" onClick={handleIncrease}>
                              <IncreaseIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleDecrease}>
                              <DecreaseIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={5} md={4}>
                        <DropDown
                          options={repeatPeriod}
                          type='repeatPeriod'
                          emptyTag='-Select-'
                          setSelectOption={handleSelectRepeatPeriod}
                          value={selectedRepeatPeriod}
                          handleError={handleError}
                          isSingle
                        />
                        {errors.repeatPeriodError && (
                          <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                              {errors.repeatPeriodError}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

  
              {/* Device Config */}
              {filteredSubTestCases.length !== 0 && (
                <Grid container style={{ marginTop: "40px" }}>
                  <Grid item xs={12}>
                    <DeviceConfig 
                      filteredSubTestCases={filteredSubTestCases}
                      selectedSubTestCasesTitles={selectedSubTestCasesTitles}
                      devices={devices}
                    />
                  </Grid>
                </Grid>
              )}
  
              {/* Buttons */}
              <Grid
                container
                spacing={2}
                style={{ marginTop: "20px" }}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${classes.getStarted} ${classes.button}`}
                    style={{backgroundColor: lightGray,color: "black"}}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${classes.getStarted} ${classes.button}`}
                    onClick={handleStartTesting}
                  >
                    Start
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
        <Loader loading={loading}/>
        <ToasterComponent
          toaster={isToasterOpen}
          message={toasterMessage}
          reqSuccess={reqSuccess}
        />
      </div>
    </ThemeProvider>
  );
}
