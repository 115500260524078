import * as React from "react";
import { Box, Button, createTheme, Grid, ThemeProvider, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import useStyles from "../assets/styles";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});


const steps = [
  { title: "Senzmatica", description: "Lorem ipsum dolor sit amet consectetur. Aliquet sit vitae id parturient tortor. Cursus amet lectus elit tellus scelerisque ipsum orci. ", pathname:"/ConnectDataStream" },
  { title: "Azure IoT", description: "Lorem ipsum dolor sit amet consectetur. Aliquet sit vitae id parturient tortor. Cursus amet lectus elit tellus scelerisque ipsum orci.", pathname:"/Azure-IoT"},
  { title: "AWS IoT", description: "Coming Soon...", pathname:"" },
  { title: "Rest API", description: "Lorem ipsum dolor sit amet consectetur. Aliquet sit vitae id parturient tortor. Cursus amet lectus elit tellus scelerisque ipsum orci. ", pathname:"" }
];

const StepCard = ({ title, description, pathname }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={11} md={11}>
        <Box className={classes.boxContainer}>
          <Typography className={classes.dataStreamTitleContainer}>{title}</Typography>
          <Typography className={classes.dataStreamDescription}>{description}</Typography>
          <div className={classes.buttonContainer} style={{justifyContent: "flex-end"}}>
            <Link to={pathname}>
              <Button
                variant="contained"
                className={classes.getStarted}
                style={{ textTransform: "none" }}
              >
                Get Started
              </Button>
            </Link>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export function DataStreamComponent() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <div className="page-heading" style={{ marginBottom: "50px" }}>
          Step-01
        </div>

        <Grid container alignItems="center">
          <Grid item xs={12} md={12}
              style={{
                  textAlign: "center",
                  paddingBottom: "40px"
              }}
          ><h4>Connect Data Stream</h4>
          </Grid>
        </Grid>

        <Grid container
          style={{
            padding: "0px 20px 30px",
            borderRadius: "0px",
          }}
        >
          {steps.map((step, index) => (
            <React.Fragment key={index}>
                {index < 3 && (
                    <Grid item xs={11} md={4}>
                        <StepCard
                            title={step.title}
                            description={step.description}
                            pathname={step.pathname}
                        />
                    </Grid>
                )}

                {index === 3 && (
                    <Grid container justifyContent="center" style={{marginTop:"25px"}} spacing={2}>
                        <Grid item xs={11} md={4}>
                            <StepCard
                                title={step.title}
                                description={step.description}
                                pathname={step.pathname}
                            />
                        </Grid>
                    </Grid>
                )}
            </React.Fragment>
            ))}
        </Grid>
        <div className={classes.buttonContainer} style={{marginBottom:"30px", justifyContent:"flex-end"}}>
          <Link to={{
            pathname: `/TestAutomation`,
          }}>
            <Button className={classes.backButton}>
              Back
            </Button>
          </Link>
        </div>
      </div>
    </ThemeProvider>
  );
}
