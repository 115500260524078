import { Button, createTheme, Grid, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import * as React from 'react';
import { ChatBotIcon } from 'src/components/Icons';
import ChatBot from 'react-simple-chatbot';
import { DropDown } from '../components/DropDown';
import useStyles from '../../assets/styles';
import { useEffect, useState } from 'react';
import { fetchBatchNumbers, fetchMainTestCases, getContinuousTestCase, getDeviceTestSummery, getScheduleTimes } from '../components/apiHelper';
import { lightGray } from 'src/components/colors';
import { DataTable } from '../step4/DataTable';
import { Link } from 'react-router-dom';
import { Loader } from '../components/Loader';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
})

const steps = [
    {
        id: '0',
        message: 'Hey Geek!',

        // This calls the next id
        // i.e. id 1 in this case
        trigger: '1',
    }, {
        id: '1',

        // This message appears in
        // the bot chat bubble
        message: 'Please write your username',
        trigger: '2'
    }, {
        id: '2',

        // Here we want the user
        // to enter input
        user: true,
        trigger: '3',
    }, {
        id: '3',
        message: " hi {previousValue}, how can I help you?",
        trigger: 4
    }, {
        id: '4',
        options: [

            // When we need to show a number of
            // options to choose we create alist
            // like this
            { value: 1, label: 'View Courses' },
            { value: 2, label: 'Read Articles' },

        ],
        end: true
    }
];

export const ResultDashboardComponent = () => {
    const classes = useStyles()
    const [selectedStreamingMethod, setSelectedStreamingMethod] = useState('');
    const [selectedTestCaseType, setSelectedTestCaseType] = useState('');
    const [streamingMethods, setStreamingMethods] = useState([
        "SenzMatica", "MQTT", "AWS", "Rest API", "Azure IoT"
    ]);
    const [testCaseTypes, setTestCaseTypes] = useState([
        "Production Feasibility Testing", "Continuous Testing"
    ]);
    const [selectedBatch, setSelectedBatch] = useState('');
    const [batchNumbers, setBatchNumbers] = useState([]);
    const [mainTestCasesTitles, setMainTestCasesTitles] = useState([]);
    const [selectedMainTestCaseTitle, setSelectedMainTestCaseTitle] = useState('');
    const config = {
        botAvatar: "img.png",
        floating: true,
    };
    const [selectedMainTestCaseId, setSelectedMainTestCaseId] = useState('');
    const [subTestCaseTitles, setSubTestCaseTitles] = useState([]);
    const [devices, setDevices] = useState([]);
    const [testData, setTestData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [continuousMainTestId, setContinuousMainTestId] = useState("");
    const [scheduleTimes, setScheduleTimes] = useState([]);
    const [selectedScheduleTime, setSelectedScheduleTime] = useState('');
    const [selectedFormattedTime, setSelectedFormattedTime] = useState('');
    const [testCaseType, setTestCaseType] = useState('')
    const [mainTestCasesContent, setMainTestCasesContent] = useState([]);
    //handel errors
    const [errors, setErrors] = useState({
        streamNameError: null,
        batchNoError: null,
        mainTestNameError: null
    })

    const handleError = (name) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    }

    const onBatchDropClick = () => {
        if (selectedStreamingMethod === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                streamNameError: "Data Streaming Method is Required"
            }));
        }
    };

    const onMainDropClick = () => {
        if (selectedBatch === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                batchNoError: "Batch is required"
            }));
        }
    };

    const onTestTypeDropClick = () => {
        if (selectedMainTestCaseTitle === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                mainTestNameError: "Main Test Case is required"
            }));
        }
    };

    useEffect(() => {
        const getBatchNumbers = async () => {
            if (selectedStreamingMethod) {
                try {
                    const batchNumbers = await fetchBatchNumbers();
                    setBatchNumbers(batchNumbers);

                    if (batchNumbers.length > 0) {
                        setSelectedBatch(batchNumbers[0]);
                        handleSelectBatchNumber(batchNumbers[0]);
                    }
                } catch (err) {
                    console.error('Error fetching batch numbers:', err);
                }
            }
        };
        getBatchNumbers();
    }, [selectedStreamingMethod]);


    // Get Continuous Test
    useEffect(() => {
        const fetchContinuousTestId = async () => {
            if (selectedTestCaseType === "Continuous Testing") {
                try {
                    const continuousTestId = await getContinuousTestCase(selectedBatch, selectedMainTestCaseTitle);
                    setContinuousMainTestId(continuousTestId);
                } catch (err) {
                    console.error('Error fetching continuous test case ID:', err);
                }
            } else {
                setContinuousMainTestId("");
            }
        };
        fetchContinuousTestId();
    }, [selectedTestCaseType, selectedBatch, selectedMainTestCaseTitle]);


    // Fetch test summary data
    useEffect(() => {
        const getTestSummaryData = async () => {
            if (!selectedBatch || !selectedMainTestCaseTitle || !selectedTestCaseType) {
                return;
            }
            if (selectedTestCaseType === "Continuous Testing") {
                if (!continuousMainTestId || !selectedScheduleTime) {
                    return;
                }
            }
            try {
                setLoading(true)
                const testSummaryData = await getDeviceTestSummery(
                    selectedBatch,
                    selectedMainTestCaseTitle,
                    selectedTestCaseType,
                    continuousMainTestId,
                    selectedScheduleTime
                );
                setTestData(testSummaryData);
            } catch (err) {
                console.error('Error fetching test summary data:', err);
            }
        };

        getTestSummaryData();
    }, [
        selectedBatch,
        selectedMainTestCaseTitle,
        selectedTestCaseType,
        continuousMainTestId,
        selectedScheduleTime,
        selectedFormattedTime
    ]);


    // Fetch schedule times
    useEffect(() => {
        const fetchTimeZones = async () => {
            if (selectedTestCaseType === "Continuous Testing" && continuousMainTestId) {
                try {
                    let scheduleTimeAndDate = await getScheduleTimes(continuousMainTestId);
                    scheduleTimeAndDate = scheduleTimeAndDate.sort((a, b) => b.localeCompare(a));
                    const dateAndTime = []
                    scheduleTimeAndDate.map(schedule => {
                        var [year, month, day, hour, minute] = schedule.split("_");
                        const dateFormate = new Date(year, month - 1, day, hour, minute);

                        let date = dateFormate.toLocaleDateString('en-us')
                        let time = dateFormate.toLocaleTimeString('en-us')

                        let dateTime = date.concat('   ', time)
                        const dateTimeObject = {
                            [schedule]: dateTime
                        };
                        dateAndTime.push(dateTimeObject)

                    })
                    setScheduleTimes(dateAndTime);
                    if (scheduleTimeAndDate && scheduleTimeAndDate.length > 0) {
                        setSelectedFormattedTime(Object.values(dateAndTime[0]).toString())
                        setSelectedScheduleTime(Object.keys(dateAndTime[0]).toString());
                    }

                } catch (err) {
                    console.error('Error fetching schedule times:', err);
                }
            }
        };
        fetchTimeZones();
    }, [selectedTestCaseType, continuousMainTestId]);


    const handleSelectStreamingMethod = (value) => {
        setSelectedStreamingMethod(value);
        handleError('streamNameError')
    };

    const handleSelectBatchNumber = async (value) => {
        if (selectedStreamingMethod) {
            setErrors(prevErrors => ({
                ...prevErrors,
                streamNameError: null
            }));
        }
        // Clear dependent dropdown states
        setSelectedMainTestCaseTitle('');
        setMainTestCasesTitles([]);
        setMainTestCasesContent([]);
        setSubTestCaseTitles([]);
        setSelectedTestCaseType('');
        setTestData([]);
        setSelectedScheduleTime('');
        setSelectedFormattedTime('')

        setSelectedBatch(value);
        handleError('batchNoError')
        try {
            const { mainTestTitles, content, uniqueDevices } = await fetchMainTestCases(value);
            const uniqueMainTestTitles = [...new Set(mainTestTitles)];

            setMainTestCasesTitles(uniqueMainTestTitles);
            setMainTestCasesContent(content);
            setDevices(uniqueDevices);
        } catch (error) {
            console.error('Error fetching main test cases:', error);
        }
    };

    const handleSelectMainTestCase = async (value) => {
        if (selectedBatch) {
            setErrors(prevErrors => ({
                ...prevErrors,
                batchNoError: null
            }));
        }

        const trimmedValue = value.trim();
        setSelectedMainTestCaseTitle(trimmedValue);
        handleError('mainTestNameError')

        const selectedMainTestCase = mainTestCasesContent.find(testCase =>
            testCase.mainTestTitle === value
        );
        if (selectedMainTestCase) {
            setSelectedMainTestCaseId(selectedMainTestCase.id);
            const subTestTitles = selectedMainTestCase.subTestCases.map(subTestCase =>
                subTestCase.subTestTitle
            );
            setSubTestCaseTitles(subTestTitles);
        } else {
            setSubTestCaseTitles([]);
        }
    };

    const handleSelectTestingMethod = (value) => {
        if (selectedMainTestCaseTitle) {
            setErrors(prevErrors => ({
                ...prevErrors,
                mainTestNameError: null
            }));
        }
        setSelectedTestCaseType(value);
        if (typeof value == "string" && value.includes('Continuous')) {
            setTestCaseType('CONTINUOUS')
        } else {
            setTestCaseType('INITIAL')
        }
    };

    const handleScheduleTime = (selectedValue) => {
        setSelectedFormattedTime(selectedValue.toString())
        const filterDate = scheduleTimes.filter(time => Object.values(time).includes(selectedValue.toString()));
        const dateAndTime = Object.keys(filterDate[0]).toString()
        console.log(dateAndTime)
        setSelectedScheduleTime(dateAndTime);
    };
    return (
        <ThemeProvider theme={theme}>
            <div className='testing'>
                <div className="page-heading" style={{ marginBottom: "50px" }}>
                    Step 07
                </div>

                <Grid container alignItems="center" >
                    <Grid item xs={12} md={12} style={{ textAlign: "center", paddingBottom: "40px" }}>
                        <h4>Result Dashboard</h4>
                    </Grid>
                </Grid>
                <Grid container className={classes.root} justifyContent='center'>
                    <Grid item xs={12} md={10} >
                        <Grid container className={classes.formContent}>
                            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                                {/* Data Streaming Method */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.typo}>
                                                Data Streaming Method: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DropDown
                                                options={streamingMethods}
                                                type='streamingMethod'
                                                emptyTag='-Select-'
                                                setSelectOption={handleSelectStreamingMethod}
                                                value={selectedStreamingMethod}
                                                handleError={handleError}
                                                isSingle={true}
                                            />
                                        </Grid>
                                        {errors.streamNameError && (
                                            <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                                <Typography className={classes.errorText}>
                                                    {errors.streamNameError}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* Batch */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.typo}>
                                                Test Batch: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DropDown
                                                options={batchNumbers.map(batch => batch)}
                                                type='batchNumber'
                                                emptyTag='-Select-'
                                                setSelectOption={handleSelectBatchNumber}
                                                value={selectedBatch}
                                                onBatchDropClick={onBatchDropClick}
                                                isSingle={true}
                                            />
                                        </Grid>
                                        {errors.batchNoError && (
                                            <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                                <Typography className={classes.errorText}>
                                                    {errors.batchNoError}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                                {/* Main Test Case Name */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.typo}>
                                                Main Test Case Name: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DropDown
                                                options={mainTestCasesTitles}
                                                type='mainTestCase'
                                                emptyTag='-Select-'
                                                setSelectOption={handleSelectMainTestCase}
                                                value={selectedMainTestCaseTitle}
                                                isSingle={true}
                                                onMainDropClick={onMainDropClick}
                                            />
                                        </Grid>
                                        {errors.mainTestNameError && (
                                            <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                                <Typography className={classes.errorText}>
                                                    {errors.mainTestNameError}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* Testing Methods */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.typo}>
                                                Testing Methods: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DropDown
                                                options={testCaseTypes}
                                                type='testingMethod'
                                                emptyTag='-Select-'
                                                setSelectOption={handleSelectTestingMethod}
                                                value={selectedTestCaseType}
                                                isSingle
                                                onTestTypeDropClick={onTestTypeDropClick}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Data Table */}
                            <Grid container alignItems="center" spacing={2} style={{ marginBottom: "20px" }}>
                                <Grid item xs={12} md={12}>
                                    <DataTable
                                        selectedMainTestCaseTitle={selectedMainTestCaseTitle}
                                        testData={testData}
                                        selectedTestCaseType={selectedTestCaseType}
                                        testCaseType={testCaseType}
                                        loadingClose={() => {
                                            setLoading(false);
                                        }}
                                        selectedMainTestCaseId={selectedMainTestCaseId}
                                    />
                                </Grid>
                            </Grid>

                            {/* Back Button */}
                            <Grid container justifyContent="flex-end" style={{ marginBottom: "20px" }}>
                                <Grid item xs={12} container justifyContent="flex-end">
                                    <div
                                        className={classes.buttonContainer}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%',
                                        }}
                                    >
                                        <Link
                                            to={{
                                                pathname: `/TestAutomation`,
                                                state: selectedMainTestCaseTitle && selectedTestCaseType ? { fromTestReport: true } : null,
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                className={classes.button}
                                                style={{
                                                    backgroundColor: lightGray,
                                                    marginRight: '12px',
                                                }}
                                            >
                                                Back
                                            </Button>
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Loader loading={loading} />
                </Grid>


                <IconButton style={{ background: 'linear-gradient(180deg, #2A7BEC 0%, #184686 100%)', borderRadius: '50%', position: 'fixed', right: '20px', bottom: '20px' }}>
                    <ChatBotIcon color={'white'} />
                </IconButton>
            </div>
        </ThemeProvider>
    )
}