import { createTheme, Grid, ThemeProvider, Typography } from '@material-ui/core'
import * as React from 'react'
import useStyles from '../../assets/styles'
import { DropDown } from '../components/DropDown'
import { FormField } from './FormFiled'
import { useState } from 'react'
import { apiConfiguration } from '../components/apiHelper'

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
})

export const ApiConfigurationComponent = ({setMetaApi,setSensorApi,setErrors,errors}) => {
    const classes = useStyles()

    const onChange = (event,name) => {
        let { value } = event.target
        if(name == "sensorApi"){
            setSensorApi(value)
            setErrors(prevError =>({
                ...prevError,
                sensorApiError:null
            }))
        }else if(name == 'metaApi'){
            setMetaApi(value)
            setErrors(prevError =>({
                ...prevError,
                metaApiError:null
            }))
        }
        
    }

    return (
        <ThemeProvider theme={theme} >
            <div className='testing'>
                <Grid container className={`${classes.root} ${classes.formContent}`}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                <FormField
                                    fieldTitle="Sensor Data API"
                                    inputName="sensorApi"
                                    placeholder="Enter Sensor Data API URL Here"
                                    isRequired={false}
                                    onChange={(event) => onChange(event,'sensorApi')}
                                    errors = {errors.sensorApiError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    fieldTitle="Meta Data API"
                                    inputName="metaDataApi"
                                    placeholder="Enter Meta Data API URL Here"
                                    isRequired={false}
                                    onChange={(event) => onChange(event,'metaApi')}
                                    errors={errors.metaApiError}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <FormField
                                    fieldTitle="Username"
                                    inputName="username"
                                    placeholder="Enter Username Here"
                                    isRequired={false}
                                    onChange={(event) => onChange(event)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    fieldTitle="Password"
                                    inputName="password"
                                    placeholder="Enter Password Here"
                                    isRequired={false}
                                    onChange={(event) => onChange(event)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.typo}>
                                    Data Points
                                </Typography>
                                <DropDown
                                    options={dataPoints}
                                    type='dataPoints'
                                    emptyTag='Select Data Points'
                                    setSelectOption={setDataPoints}
                                    value={selectedDataPoint}
                                    onClick={handleSelectDataPoints}
                                    isSingle={true}
                                />
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    )
}