import { Box, Button, createTheme, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputBase, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, Tooltip, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Arrow, DocumentIcon, EditIcon, NonSuccessCriteriaIcon, RootCauseIcon, TrashIcon, ViewIcon } from 'src/components/Icons';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Close, Description, Edit } from '@material-ui/icons';
import useStyles from '../maintenance/assets/styles';
import { DropDown } from '../maintenance/test-automation/components/DropDown';
import { formatSuccessCriteria } from '../utils';
import { DescriptionComponent } from '../maintenance/test-automation/step5/step2/Description';
import { batch } from 'react-redux';
import { SelectAModelComponent } from '../maintenance/test-automation/step5/step2/SelectAModelComponent';
import { getAvailableModels } from '../maintenance/test-automation/components/apiHelper';
import { Loader } from '../maintenance/test-automation/components/Loader';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';

export const RootCauseConfigComponent = () => {
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });

    const [loading, setLoading] = useState(false);
    const [rootCauses, setRootCauses] = useState([]);
    const [selectedRootCause, setSelectedRootCause] = useState(null);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
    const [formValue, setFormValue] = useState({
        batchNumber: '',
        analysisName: '',
        model: '',
        modelUrl: '',
    });

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [reqSuccess, setReqSuccess] = useState(false);

    const handleEditPopup = (rootCause) => {
        setSelectedRootCause(rootCause);
        setFormValue({
            batchNumber: rootCause.batchNumber || '',
            analysisName: rootCause.analysisName || '',
            model: rootCause.model || '',
            modelUrl: rootCause.modelUrl || '',
        });
        setIsEditPopupOpen(true);
    }

    const cancelEditPopup = () => {
        setIsEditPopupOpen(false);
    }

    const handleViewPopup = (rootCause) =>{
        setSelectedRootCause(rootCause);
        setIsViewPopupOpen(true)
    }

    const cancelViewPopup = () =>{
        setIsViewPopupOpen(false)
    }

    const fetchRootCauses = async () => {
        setLoading(true);
         try {
            const response = await axios.get(`${environment.host}/core/rootCause`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')
                }
            });
            const data = response.data;
            setRootCauses(data.content);
        } catch(err) {
            console.log('Error fetching root causes', err);
        } finally {
            setLoading(false);
        }
    };

    const updateRootCauses = async (rootCause) => {
        setLoading(true);
        try {
            const response = await axios.put(`${environment.host}/core/rootCause/${selectedRootCause.id}`, rootCause, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')
                }
            });
            console.log('Root Cause Updated Successfully', response.data);
            setIsToasterOpen(true);
            setToasterMessage('Root Cause Updated Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);
            await fetchRootCauses();
        } catch(err) {
            console.log('Error updating root cause', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRootCauses();
    }, []);

    return (
        <ThemeProvider theme={theme} >
            <Grid container style={{padding:'25px'}}>
                <Grid container style={{display: "flex",margin:'50px 0px',alignItems:'center',backgroundColor:'white',padding:'15px',width:'fit-content'}}>
                    <Grid item style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                        <NonSuccessCriteriaIcon />
                        <Link to={'/TestAutomation'} style={{paddingLeft: '10px', textDecoration: 'none'}}>
                        <Typography style={{color: '#858DAA'}}>Setup Device Pulse</Typography>
                        </Link>
                    </Grid>
                    <Arrow/>
                    <Grid item style={{color:'#2A7BEC',display:'flex',alignItems:'center'}}>
                        <RootCauseIcon />
                        <Typography style={{marginLeft:'10px'}}>Root Cause Config</Typography>
                    </Grid>      
                </Grid>
                <Grid container >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{width:'30%'}}>Batch Name</TableCell>
                                <TableCell align="left" style={{width:'50%'}}>Analysis Name</TableCell>
                                <TableCell style={{width:'20%'}} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rootCauses.length > 0 ? (
                                rootCauses.map((rootcause, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            <TableCell align="left">{rootcause.batchNumber}</TableCell>
                                            <TableCell align="left">{rootcause.analysisName}</TableCell>
                                            <TableCell style={{ display: 'flex', justifyContent: 'flex-end', paddingRight:'55px', alignItems:'center', gap:'10px'  }}>
                                                <IconButton size={'small'} onClick={() => handleViewPopup(rootcause)}>
                                                    <ViewIcon />
                                                </IconButton>
                                                <IconButton size={'small'} onClick={() => handleEditPopup(rootcause)}>
                                                    <EditIcon stroke={'#707070'} />
                                                </IconButton>
                                                <IconButton size={'small'}>
                                                    <TrashIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>  
                                    </React.Fragment>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                        No Root Causes Available
                                    </TableCell>
                                </TableRow>
                            )}     
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <ViewPopup 
                openView={isViewPopupOpen} 
                handleWindowClose={cancelViewPopup}
                rootCause={selectedRootCause}
            />
            <EditPopup 
                open={isEditPopupOpen} 
                handleWindowClose={cancelEditPopup} 
                rootCause={selectedRootCause} 
                updateRootCauses={updateRootCauses}
                formValue={formValue}
                setFormValue={setFormValue}
            />
            <Loader loading={loading} />
            <ToasterComponent 
                toaster={isToasterOpen}
                message={toasterMessage}
                success={reqSuccess}
            />
        </ThemeProvider>
    );
};

export const ViewPopup = ({openView,handleWindowClose, rootCause }) => {
    const [isShowDescription, setIsShowDescription] = useState(false);

    const handleShowDescription = () => {
        setIsShowDescription(true);
    };

    const cancelShowDescription = () => {
        setIsShowDescription(false);
    };

    return(
        <Dialog open={openView}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>View Root Cause Config</span>
                <IconButton onClick={handleWindowClose} style={{ paddingRight: '0px' }} size="small">
                    <Close />
                </IconButton>
                </Box>
            </DialogTitle>
  
            <DialogContent>
                <Box sx={{ minHeight: '135px' }}>
                    {rootCause && (
                        <Grid container spacing={3} style={{ padding: '15px' }} key={rootCause.id}>
                            <Grid item xs={4}>
                                <Typography>Batch Name</Typography>
                                <Typography style={{ fontWeight: 'bold', marginTop: '5px' }}>
                                    {rootCause.batchNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Analysis Name</Typography>
                                <Typography style={{ fontWeight: 'bold', marginTop: '5px' }}>
                                    {rootCause.analysisName}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Model</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                    <Typography style={{ fontWeight: 'bold' }}>
                                        {formatSuccessCriteria(rootCause.model)}
                                    </Typography>
                                    <Tooltip title="Show Description" >
                                        <IconButton size="small" onClick={handleShowDescription} style={{ marginLeft: '5px' }}>
                                            <DocumentIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </DialogContent>
            
            <DescriptionComponent open={isShowDescription} handleCloseWindow={cancelShowDescription} />
            </Dialog>

    )
}

export const EditPopup = ({open, handleWindowClose, rootCause, updateRootCauses, formValue, setFormValue}) => {
    const classes = useStyles();
    const [selectedMOdel,setSelectedModel] = useState("")
    const [showDescription,setShowDescription] = useState(false)

    const [models, setModels] = useState([])
    
    const showDescriptionDialog = () =>{
        setShowDescription(true); 
    }

    const handleCloseWindow = () =>{
        setShowDescription(false)
    }

    const [errors, setErrors] = useState({
        analysisName: '',
        model: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue({
            ...formValue,
            [name]: value,
        });
    };

    const handleSelectModel = (value) =>{
        setSelectedModel(value)
        setFormValue(prevValue =>({
          ...prevValue,
          "model":value,
          "modelUrl":models.filter((m)=>m.model_name===value)[0]["model_path"]
        }))
        setErrors(prevError => ({
          ...prevError,
          "model": null
        }))
    
    }

    useEffect(()=>{
        const getAvailableModel = async () => {
          try {
            const models = await getAvailableModels({
              "projectname": "RootCause"
            });
            if(models && models.status==="success"){
              let modelData = []
              let keys = Object.keys(models).filter((k)=>k!=="status")
              keys.map((k)=>{
                modelData.push(models[k])
              })
              setModels(modelData);
            }
          } catch (err) {
            console.error('Error fetching batch numbers:', err);
          }
      };
      getAvailableModel()
    },[]);

    const validateForm = () => {
        let error: any = {};

        if(formValue.analysisName === '') {
            error.analysisName = 'Analysis Name is required';
        }
        if(formValue.model === '') {
            error.model = 'Model is required';
        }
        setErrors(error);
        return error
    }

    const resetFormFields = () =>  {
        setFormValue({
            batchNumber: '',
            analysisName: '',
            model: '',
            modelUrl: '',
        });
        setErrors({
            analysisName: '',
            model: '',
        });
    }

    const handleSubmit = async () => {
        const validationError = validateForm();
        if(Object.keys(validationError).length > 0) {
            return;
        }
        try {
            await updateRootCauses(formValue);
            resetFormFields();
            handleWindowClose();
        } catch(err) {
            console.log('Error updating root cause', err);
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span>Edit Root Cause Config</span>
              <IconButton
                onClick={() => {
                    handleWindowClose();
                    resetFormFields();
                }}
                style={{ paddingRight: '0px' }}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
          <Box sx={{minHeight:'370px'}}>
            <Grid container spacing={3} style={{ padding: '10px' }}>
                <Grid item xs={6} style={{ border: '1px solid #2A7BEC', borderRadius: '5px', marginTop: '10px', marginLeft: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                        <Typography style={{width:'75%'}}>
                            Batch Name
                        </Typography>
                            <InputBase
                                style={{fontWeight: 'bold', color: '#2A7BEC', backgroundColor: '#F9F9FA', padding: '10px'}}
                                type="text"
                                name="batchNumber"
                                value={formValue.batchNumber}
                            />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ flexDirection: 'column' }}>
                    <Typography>
                        Analysis Name
                    </Typography>
                    <InputBase
                        className={classes.formInput}
                        type="text"
                        name="analysisName"
                        value={formValue.analysisName}
                        onChange={handleInputChange}
                    />
                    {errors.analysisName && (
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                                {errors.analysisName}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} style={{ flexDirection: 'column' }}>
                    <Typography className={classes.typo}>
                    Model
                    </Typography> 
                    <DropDown
                        options={models.map((m)=>m.model_name)}
                        type='model'
                        emptyTag='Select Model'
                        setSelectOption={setSelectedModel}
                        value={formValue.model}
                        // handleError={handleError}
                        onClick= {handleSelectModel}
                        isSingle={true}
                        icon ={<DocumentIcon />}
                        iconClick = {showDescriptionDialog}
                    />
                    {errors.model &&
                        <Grid item xs={12} style={{ padding: "0px 8px" }}>
                        <Typography className={classes.errorText}>
                            {errors.model}
                        </Typography>
                        </Grid>
                    }
                </Grid>
                {showDescription && 
                    <DescriptionComponent 
                    open={showDescription} 
                    handleCloseWindow={handleCloseWindow}
                    />
                }
                <Grid container spacing={2} style={{ marginTop: "20px" }} justifyContent="flex-end">
                        <Grid item>
                        <Button
                            variant="contained"
                            className={`${classes.backButton} ${classes.button}`}
                            onClick={() => {
                                handleWindowClose();
                                resetFormFields();
                            }}
                        >
                            Cancel
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button
                            variant="contained"
                            className={`${classes.getStarted} ${classes.button}`}
                            onClick={handleSubmit}
                        >
                            Update 
                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
          </DialogContent>
        </Dialog>
    );
};