import * as React from "react";
import { Box, Button, createTheme, Grid, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import useStyles from "../assets/styles";
import { useState } from "react";
import { lightGray, primaryBlue, primaryGray } from "src/components/colors";
import { DropDown } from "./DropDown";
import { TextBar } from "src/components/sub-components/TextBar";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function Step2(props) {
  const classes = useStyles();
  const updateDetailsArray = (prevState, index, value) => {
    return prevState.map((detail, i) =>
      i === index
        ? {...detail, defaultValue: value, checkUpdate: true}
        : detail
    );
  };
  
  const handleInputChange = (event, index) => {
    const { value } = event.target;
    const trimmedValue = value.trim();
    props.setDetailsArray((prevState) => {
      const updatedDetailsArray = updateDetailsArray(prevState, index, trimmedValue);
      return updatedDetailsArray;
    });
  };
  

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>

      <Grid container className={classes.root} style={{padding:"0px 400px"}}>
          <Grid container className={classes.formContent}>
          {props.detailsArray.map((item,id) => {
            if(item.parameterCategory=="Network & Communication"){
              return(
             <Grid container alignItems="center" spacing={2} style={{marginTop: "20px"}} key={ item.parameterId}>
             <Grid item xs={12} md={12}>
                 <Typography className={classes.typo}>
                   {item.parameter}
                   <span style={{ color: 'red' }}>*</span>
                 </Typography>  
                 <InputBase
                   className={classes.formInput}
                   type="text"
                   placeholder={`Enter ${item.parameter} Here`}
                   name={item.parameter}
                   value={item.defaultValue} 
                   onChange={(event) => handleInputChange(event, id)}
                 />
             </Grid>
           </Grid>) }}

          )}

          </Grid>
        </Grid>
          
        {/* <Loader loading={loading}/>
        <ToasterComponent
          toaster={isToasterOpen}
          message={toasterMessage}
          reqSuccess={reqSuccess}
        /> */}
      </div>
    </ThemeProvider>
  );
}
