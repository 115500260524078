import { Button, createTheme, Grid, IconButton, ThemeProvider, LinearProgress, Box } from '@material-ui/core'
import * as React from 'react'
import { DocumentIcon, PenIcon, TrashIcon, UploadIcon } from 'src/components/Icons';
import useStyles from '../../assets/styles';
import { DragDrop } from 'src/components/sub-components/DragAndDrop';
import { Upload, Icon, message, Progress, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { environment } from 'src/environments/environment';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
});

export const KnowledgeBasedUploadsComponents = ({setFiles,files,errors,setErrors}) => {
    const classes = useStyles();
    const Dragger = Upload.Dragger;
    const [fileName, setFileName] = useState("")
    const [fileSize, setFileSize] = useState("")
    const [fileList,setFileList] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            setFiles(prevFiles =>
                prevFiles.map(file => {
                    if (file.progress >= 100) return file; 
                    return { ...file, progress: file.progress + 10 }; 
                })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, [files]);

    const handleFileChange = (info) => {
        // Filter out any files that already exist in the file list
        const newFiles = info.fileList.filter(file => {
            const existingFile = files.find(f => f.name === file.name);
            return !existingFile; // Only include files that are not already in the state
        }).map(file => ({
            name: file.name,
            size: (file.size / 1024 / 1024).toFixed(2),
            progress: 0,
            file: file.originFileObj,
        }));
    
        if (newFiles.length > 0) {
            setFileList(prev => [...prev, ...newFiles]);
            setFiles(prev => [...prev, ...newFiles.map(f => f.file)]);
        }
    };


    const props = {
        name: 'file',
        multiple: false, 
        accept: '.pdf', 
        onChange: handleFileChange,
        beforeUpload: (file) => {
            const isPdf = file.type === 'application/pdf';
            const isLt5MB = file.size / 1024 / 1024 < 5;
            const { status, name, size } = file;
        
            if (!isPdf) {
              message.error('You can only upload PDF files!');
              return false; 
            }
            if (!isLt5MB) {
              message.error('File must be smaller than 5MB!');
              return false; 
            }
            setFiles(prevFiles => {
                const fileExists = prevFiles.find(file => file.name === name);
                if (!fileExists) {
                    return [...prevFiles, { name, size: (size / 1024 / 1024).toFixed(2), progress: 0 , file: file }];
                }
                return prevFiles;
            });
            return true; 
        },
        showUploadList: false
    };

    return (
        <ThemeProvider theme={theme} >
            <div className='testing'>
                <Grid container direction='column'>
                    {files.length != 0 &&
                        <Grid container>
                            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                <Upload {...props} accept='.pdf'>
                                    <Button
                                        variant="outlined"
                                        startIcon={<UploadIcon color='#2A7BEC' />}
                                        className={classes.button}
                                        style={{ borderColor: '#2A7BEC', color: '#2A7BEC' }}
                                    >
                                        Upload Files
                                    </Button>
                                </Upload>
                            </Grid>

                            <Grid container style={{ marginTop: '15px' ,maxHeight:'450px',overflowY:'auto'}}>
                                {files && (
                                    files.map((file, index) => (
                                        <Grid container key={index} direction='column' style={{border: '1px solid #CACACA',borderRadius:'4px',padding:'10px',marginTop:'15px'}}>
                                            <Grid item xs={12} md={12}>
                                                <Grid container justifyContent='space-between'>
                                                    <Grid item style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                                                        <DocumentIcon />
                                                        <span style={{ color: '#353535', textAlign: 'center', fontFamily: 'Poppins', margin: '0 10px' }}>
                                                            {file.name}
                                                        </span>
                                                        <PenIcon />
                                                    </Grid>
                                                    {/* <Grid item>
                                                        <IconButton size="small">
                                                            <TrashIcon />
                                                        </IconButton>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ color: '#B4B2B2', textAlign: 'center', fontFamily: 'Poppins', display: 'flex' }}>
                                                {`${file.size} MB`}
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                                                <progress value={file.progress} max={100} style={{ width: '100%', marginRight: '10px' }} />
                                                <span>{`${file.progress}%`}</span>
                                            </Grid>
                                        </Grid>

                                    ))
                                )}
                            </Grid>


                        </Grid>
                    }

                    {files.length == 0 &&
                        <Grid container>
                            <Grid item className={classes.dragAndDrop} style={{borderColor:errors.KnowledgedBase ? 'red':'#CBD0DC',width:'100%'}}>
                                <Dragger {...props} accept='.pdf' onChange={handleFileChange}>
                                    <DragDrop />
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-text" style={{ color: '#B4B2B2', textAlign: 'center' }}>maximum upload file size : 5MB</p>
                                </Dragger>
                            </Grid>
                            {errors.KnowledgedBase &&
                                <Grid item xs={12} style={{ padding: "0px 8px" ,textAlign:'left'}}>
                                    <Typography className={classes.errorText} >
                                        {errors.KnowledgedBase}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    }



                </Grid>
            </div>
        </ThemeProvider>
    )
}