import * as React from 'react'
import { Grid, InputBase } from "@material-ui/core"
import { Typography } from "antd"
import useStyles from "../../assets/styles"

export const FormField = ({ fieldTitle, inputName, placeholder, isRequired ,onChange}) => {

    const classes = useStyles()
    return (
        <Grid item xs={12} md={12}>
            <Grid item>
                <div>
                    <Typography className={classes.typo}>
                        {fieldTitle}
                    </Typography>
                    {isRequired && <span style={{ color: 'red' }}>*</span>}
                </div>
            </Grid>
            <Grid item xs={12} md={12}>
                <InputBase
                    className={classes.formInput}
                    type="text"
                    placeholder={placeholder}
                    name={inputName}
                    onChange={onChange}
                />
            </Grid>
        </Grid>
    )
}