import { red } from '@material-ui/core/colors';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { BorderColor } from '@material-ui/icons';
import { lightGray, primaryBlue, primaryGray, textBarColor, white } from "src/components/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "50px",
    textAlign: "left",
    borderRadius: "3px",
  },
  boxContainer: {
    position: "relative",
    padding: "20px",
    marginTop: "20px",
    background: "#FFFFFF",
    boxShadow: "0px 3px 8px #00000012",
    borderRadius: "16px",
    opacity: 1,
  },
  subBoxContainer: {
    marginTop: "20px",
    borderRadius: "5px",
    border: "1px solid #D9D9D9",
    padding: "35px"
  },
  statusContainer: {
    marginBottom: "20px",
    display: "flex"
  },
  chipContainer: {
    marginRight: "0px",
  },
  step: {
    borderRadius: "50px",
    backgroundColor: "#30335b",
    color: "white",
    fontSize: "14px",
    padding: "8px 20px",
    display: "inline-block",
    marginLeft: "0px"
  },
  titleContainer: {
    fontSize: "18px",
    color: "#000000",
    fontWeight: 600,
    marginLeft: "5px",
    marginRight: "80px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  dataStreamTitleContainer: {
    fontSize: "25px",
    color: "#5E5C5C",
    fontWeight: 600,
    marginLeft: "5px",
    marginRight: "80px",
    display: "flex",
    justifyContent: "center",
  },
  description: {
    fontWeight: 200,
    fontSize: "15px",
    paddingTop: "10px",
    margin: "0px 5px 30px 5px",
  },
  dataStreamDescription: {
    fontWeight: 200,
    fontSize: "15px",
    paddingTop: "10px",
    margin: "0px 10px 20px 10px",
    display: "flex",
    justifyContent: "center"
  },
  getStarted: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  option: {
    padding: '0px 10px',
    height: '30px',
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '13px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    paddingRight: '10px',
  },
  dropdown: {
    width: '100%',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    border: '1px solid #C7C4C4',
    borderRadius: '5px',
    backgroundColor: white,
    zIndex: 1,
    maxHeight: '160px',
    overflowY: 'auto',
    cursor: 'pointer',
  },
  inputBaseStyle: {
    width: '100%',
    height: "38px",
    borderRadius: "5px",
    borderColor: lightGray,
    borderStyle: "solid",
    borderWidth: "1px",
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  formContent: {
    padding: "40px",
    textAlign: "left",
    borderRadius: "5px",
    borderColor: lightGray,
    borderStyle: "solid",
    borderWidth: "1px",
  },
  formInput: {
    width: '100%',
    height: "38px",
    borderRadius: "5px",
    borderColor: lightGray,
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "10px 15px",
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  addNew: {
    backgroundColor: lightGray,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: lightGray,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    minWidth: '120px',
    borderRadius: '8px',
    textTransform: 'none',
  },
  backButton: {
    color: primaryGray,
    width: '160px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    boxShadow: "none",
    "&:hover": {
      backgroundColor:"transparent",
      color: primaryGray,
      boxShadow: "none",
      border: "1px solid #707070",
    },
  },
  refreshButton: {
    border: '1px solid #02C170',
    borderRadius: '4px',
    padding: '8px 20px 8px 20px',
    cursor: 'pointer',
    color: '#02C170',
    backgroundColor: '#FFFFFF'
  },
  submitButton: {
    borderRadius: '4px',
    padding: '8px 50px 8px 50px',
    cursor: 'pointer',
    color: '#FFFFFF',
    backgroundColor: '#2A7BEC'
  },
  radio: {
    '&.Mui-checked': {
      color: primaryBlue,
    },
    '&:hover': {
      backgroundColor: alpha(primaryBlue, 0.1),
    },
    '&.Mui-checked:hover': {
      backgroundColor: alpha(primaryBlue, 0.1),
    },
  },
  label: {
    alignSelf: 'flex-start',
    marginTop: '10px',
  },
  typo: {
    fontSize: "15px"
  },
  deleteButton: {
    color: 'red',
  },
  descriptionContainer: {
    fontWeight: 200,
    fontSize: "13px",
    margin: "20px 50px 30px 20px",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
  },
  chip: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    fontSize: '12px',
    borderRadius: '16px',
    padding: '4px 8px',
    '& .MuiChip-deleteIcon': {
      color: theme.palette.text.secondary,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  table: {
    minWidth: 650,
    border: '1px solid #ddd',
    borderCollapse: 'collapse',
  },
  contentText: {
    fontSize: '14px',
  },
  topicText: {
    fontSize: '14px',
    color: "#30335b",
    fontWeight: "bold",
  },
  deviceId: {
    fontSize: '14px',
    color: "#30335b",
  },
  input: {
    width: "100%",
    textAlign: "left",
  },
  cloneButtonHover: {
    backgroundColor: 'transparent',
    color: primaryBlue,
    boxShadow: 'none',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      boxShadow: 'none',
    },
  },
  subIconButtonDiv: {
    backgroundColor: "#F5F5F5",
    width: "25px",
    height: "25px",
    borderRadius: "100%",
    textAlign: "center",
  },
  iconButtonStyle: {
    backgroundColor: "ButtonShadow",
    borderRadius: "80%",
    boxShadow: "0px 3px 6px #00000029",
    padding: "3px",
  },
  addButton: {
    backgroundColor: white,
    color: primaryBlue,
    borderColor: primaryBlue,
  },
  inputDefaultValue: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  native: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '100%',
    fontSize: '16px',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 2,
    width: "100%",
  },
  add: {
    backgroundColor: primaryBlue,
    color: "white",
    marginBottom: "10px",
    marginRight: "10px",
    width: "20%",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(primaryBlue, 0.8),
      color: white,
      boxShadow: "none",
    },
  },
  cancel: {
    backgroundColor: 'transparent',
    color: primaryGray,
    marginBottom: "10px",
    marginRight: "10px",
    width: "20%",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      bord: "black",
      color: primaryGray,
      boxShadow: "none",
      border: "1px solid #707070",
    },
  },

  saveButton: {
    width: '160px',
    borderRadius: '4px',
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(primaryBlue, 0.8),
      color: white,
      boxShadow: "none",
    },
  },
  options: {
    // width: 'calc((100% - 40px ) / 3)',
    height: '300px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    minWidth: '200px',
    textAlign: 'center',
    lineHeight: '20px',
    color: '#5E5C5C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#2A7BEC',
      color: white
    },
    margin: 0,
    border: '0.6px solid #DDDDDD',
    boxShadow: '0px 4px 40px 0px #00000014'

  },
  dragAndDrop: {
    border: '4px dashed #CBD0DC',
    padding: '30px',
    borderRadius: '4px',
    marginTop: '15px',
    "&:hover": {
      borderColor: '#2A7BEC'
    }
  },
  accordion: {
    marginTop: '10px',
    padding: '0px',
    border: 'none', // Remove border
    boxShadow: 'none', // Remove box-shadow
    '& .MuiAccordionSummary-root': {
      border: 'none', // Remove border
      boxShadow: 'none', // Remove box-shadow
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px',
    },
  },
}));

export default useStyles;
