import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../authentication/auth.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SideNavService} from './sidenav/sidenav.service';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  error;
  selectedMenu;

  constructor(protected router: Router, private sideNavService: SideNavService, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectedMenu = this.router.url.split('/')[1];
      }
    });
  }

  ngOnInit() {
    // this.selectedMenu = this.router.url.split('/')[1];
    this.sideNavService.hideSideNav();
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  closeMenu() {
    const view = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(view)) {
      this.sideNavService.hideSideNav();
    }

  }

  isActive(menu: string): boolean {
    return this.selectedMenu === menu;
  }

  setActive(menu: string): void {
    this.selectedMenu = menu;
  }
}
