import { createTheme, ThemeProvider, Grid, Typography, InputBase, Accordion, AccordionSummary, AccordionDetails, Divider, makeStyles } from '@material-ui/core'
import * as React from 'react'
import useStyles from 'src/app/maintenance/assets/styles';
import { ExpandMoreIcon } from 'src/components/Icons';
import { DropDown } from '../components/DropDown';
import { useState } from 'react';
import {FormField} from './FormFiled'
import { CompileShallowModuleMetadata } from '@angular/compiler';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
});

export const ProjectClientConfigurationsComponent = () => {
    const classes = useStyles()
    const analyses = ["Analysis 1", "Analysis 2", "Analysis 3"]
    const [selectedAnalysis, setSelectedAnalysis] = useState("")

    const handleAnalysis = (value) => {
        setSelectedAnalysis(value)
    }

    const onChange = (event) =>{
        let {value} = event.target
        let trimValue = value.trim()
        console.log(trimValue)
    }

    return (
        <ThemeProvider theme={theme}>
            <div className='testing'>
                <Grid container className={`${classes.root} ${classes.formContent}`}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                    <Typography className={classes.typo}>
                                        Analysis Name <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                <DropDown
                                    options={analyses}
                                    type='analysis'
                                    emptyTag='Select Analysis'
                                    setSelectOption={setSelectedAnalysis}
                                    value={selectedAnalysis}
                                    onClick={handleAnalysis}
                                    isSingle={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion style={{ marginTop: '10px', padding: '0px',backgroundColor:'rgb(251,251,251)'}} className={classes.accordion}> 
                            <AccordionSummary style={{ padding: '0px' }} expandIcon={<ExpandMoreIcon />}>
                                <Grid container justifyContent='space-between' alignItems='center'>
                                    <Grid item>
                                        <Typography variant="body1">Client Details</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormField
                                            fieldTitle="Name"
                                            inputName="name"
                                            placeholder="Enter Name Here"
                                            isRequired={false}
                                            onChange={(event) =>onChange(event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormField
                                            fieldTitle="Email"
                                            inputName="email"
                                            placeholder="Enter Email Here"
                                            isRequired={false}
                                            onChange={(event) =>onChange(event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormField
                                            fieldTitle="Tel. No"
                                            inputName="telNo"
                                            placeholder="Enter Tel. No Here"
                                            isRequired={false}
                                            onChange={(event) =>onChange(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    )
}