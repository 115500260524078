import { ThemeProvider, Grid, Typography, createTheme, Button } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { lightGray, primaryBlue, primaryGray } from 'src/components/colors';
import { ProjectClientConfigurationsComponent } from './ProjectClientConfigurations';
import useStyles from 'src/app/maintenance/assets/styles';
import {useHistory} from 'react-router-dom';
import { KnowledgeBasedUploadsComponents } from './KnowledgeBasedUploads';
import { SaveIcon, TrashIcon, UploadFile, UploadIcon } from 'src/components/Icons';
import { ApiConfigurationComponent } from './ApiConfiguration';

const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
});

const STEPS = [
    {
      text: "Project / Client Configurations",
      activate: true,
    },
    {
      text: "Knowledge Based Uploads",
      activate: false,
    },
    {
      text: "API Configurations",
      activate: false,
    },
];

export const HumanoidNotificationSystemComponent = () =>{
    const [steps,setSteps] = useState(STEPS)
    const [step,setStep] = useState(0)
    const classes = useStyles()
    const history = useHistory();

    useEffect(() => {
        updateStepActivation(step);
      }, [step]);
    
      function updateStepActivation(currentStep) {
        const updatedSteps = steps.map((step, index) => ({
          ...step,
          activate: index === currentStep,
        }));
        setSteps(updatedSteps);
    }

    function decreaseStep() {
      if (step === 0) {
        // resetFormFields();
        history.push(`/TestAutomation`)
      } else {
        setStep(prevStep => 
          Math.max(prevStep - 1, 0)
        );
      }
    }

    function handleSubmit() {
      // Submit logic here
      if (step === 2) {
        // resetFormFields();
        history.push(`/TestAutomation`)
      }
    }

    function increaseStep() {
      console.log(step)
      if(step == 2){
        history.push(`/TestAutomation`)
      }else{
        setStep(prevStep => 
          Math.min(prevStep + 1, STEPS.length - 1)
        );
      }
    }

    function renderStepsComponent() {
      if (step === 1) {
        return <KnowledgeBasedUploadsComponents/>;
      }else if(step == 2){
        return <ApiConfigurationComponent />
      }else {
        return <ProjectClientConfigurationsComponent />;
      }
    }
    
    return(
      <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <div className="page-heading" style={{ marginBottom: "50px" }}>
          Step 06
        </div>

        <Grid container alignItems="center" >
          <Grid item xs={12} md={12} style={{ textAlign: "center", paddingBottom: "40px" }}>
            <h4>Humanoid Notification System</h4>
          </Grid>
        </Grid>

        <Grid container style={{textAlign: "center",paddingRight: "80px"}}>
        <Grid item xs={1} md={4}></Grid>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2} md={1}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Circle number={index + 1} active={step.activate}/>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "12px",
                      paddingTop: "15px",
                      fontFamily: "Poppins, sans-serif",
                      color: step.activate ? primaryBlue : primaryGray,
                    }}
                  >
                    {step.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {index !== steps.length - 1 && (
              <Grid item xs={2} md={1}>
                <hr
                  style={{
                    marginTop: "32px",
                    opacity: steps[index + 1].activate ? "1" : "0.12",
                  }}
                  color={
                    steps[index + 1].activate ? primaryBlue : "rgba(112, 112, 112, .12)"
                  }
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
      <Grid container style={{paddingTop:'20px',width:'60%'}} alignItems="center" justifyContent="center" direction="column">
        <Grid item style={{width:'100%',marginBottom:'15px'}}>
          {renderStepsComponent()}
        </Grid>
        
        <Grid item xs={12} md={12} className={classes.root}  style={{width:'100%'}}>
            <Grid container spacing={2} justifyContent="flex-end" className="testing">
                {step !== 3 && (
                    <Button
                      onClick={decreaseStep}
                      variant="contained"
                      className={classes.button}
                      style={{ backgroundColor: lightGray,margin:'10px'}}
                    >
                      {step === 1 || step === 2 ? "Back" : "Cancel"}
                    </Button>
                )}
                {(step == 1) && 
                    <Button
                      variant="outlined"
                      className={classes.button}
                      style={{margin:'10px',color:'#FF4343',borderColor:'#FF4343'}}
                      startIcon={<TrashIcon/>}
                    >
                      Remove All
                    </Button>
                }
                    <Button
                      onClick={increaseStep}
                      variant="contained"
                      className={`${classes.getStarted} ${classes.button}`}
                      style={{margin:'10px'}}
                      startIcon = {step === 1 ? <SaveIcon color='white'/> : null}
                    >
                      {step === 1 ? 'Save All' : "Submit"}
                    </Button>
              </Grid>
          </Grid>
      </Grid>
      
      </div>
      </ThemeProvider>
    )
} 

const Circle = (props) => {
    const paddedNumber = (props.number).toString().padStart(2, '0');
    return (
      <div>
        <div
          style={{
            borderRadius: "50%",
            width: "65px",
            height: "65px",
            backgroundColor: "white",
            border: props.active ? "2px solid #2A7CED" : "0.25px solid #8F8F8F",
            textAlign: "center",
            paddingTop: "10px",
            boxShadow: "0px 4px 8px #0000001F",
          }}
        >
          <h5 style={{
            paddingTop: "10px",
            fontSize: "16px",
            color: props.active ? primaryBlue : primaryGray
          }}>
            {paddedNumber}
          </h5>
        </div>
      </div>
    );
};