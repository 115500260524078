import React, { useState, useEffect } from 'react';
import {
  ThemeProvider,
  Grid,
  InputBase,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Checkbox,
  createTheme,
  TablePagination,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import useStyles from '../../assets/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export default function Step2SelectDevices({  connectedDevices,
                                              isBack,
                                              setIsBack,
                                              selectedDeviceFinal,
                                              setSelectedDeviceFinal,
                                              fetchConnectDevices,
                                              connectDeviceId,
                                              batchNumber,
                                              message,
                                              setMessage,
                                              isExistingBatchNumber,
                                              newBatchNumber,
                                              setNewBatchNumber,
                                              loading,
                                              inputValue, 
                                              setInputValue,
                                              selectedDeviceError,
                                              setSelectedDeviceError
                                          }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deviceList, setDeviceList] = useState([]);
  const [savedInputValue, setSavedInputValue] = useState('');
  
  useEffect(() => {
    const fetchData = async () => {
      if (connectDeviceId) {
        const devices = await fetchConnectDevices(connectDeviceId);
        setDeviceList(devices);
        localStorage.setItem('fetchedDevices', JSON.stringify(devices));
      }
    };

    const storedDevices = localStorage.getItem('fetchedDevices');
    if (storedDevices) {
      setDeviceList(JSON.parse(storedDevices));
    } else {
      fetchData();

    }
  }, [connectDeviceId]);

  useEffect(() => {
    setInputValue(batchNumber);
  }, [batchNumber, setInputValue]);

  useEffect(() => {
    if (inputValue) localStorage.setItem('inputValue', inputValue);
    if (selectedDeviceFinal && selectedDeviceFinal.length > 0) {
      localStorage.setItem('selectedDevices', JSON.stringify(selectedDeviceFinal));
    }
  }, [inputValue, selectedDeviceFinal]);
  
  useEffect(() => {
    const savedInputValue = localStorage.getItem('inputValue');
    const savedSelectedDevices = localStorage.getItem('selectedDevices');
    if(isBack){
      setSavedInputValue(savedInputValue);
    }
    
    if (savedSelectedDevices) setSelectedDeviceFinal(JSON.parse(savedSelectedDevices));
  }, [batchNumber, setInputValue]);

  const handleCheckBoxChange = (deviceId) => (event) => {
    console.log('Checkbox clicked for device:', deviceId);
    setSelectedDeviceFinal((prevSelected) => {
      if (event.target.checked) {
        console.log('Adding device:', deviceId);
        setSelectedDeviceError(false);
        return [...prevSelected, deviceId];
      } else {
        console.log('Removing device:', deviceId);
        return prevSelected.filter((id) => id !== deviceId);
      }
    });
  };

  useEffect(() => {
    console.log('Selected devices:', selectedDeviceFinal);
  }, [selectedDeviceFinal]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    if (name === 'newbatch') {
      setNewBatchNumber(trimmedValue);
      setSavedInputValue(trimmedValue);
      setInputValue(trimmedValue);
      setMessage('');
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems="center" justifyContent="center" style={{ marginBottom: "30px" }}>
        <Grid item xs={12} md={8}>
          <Grid container className={classes.subBoxContainer}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={3} className={classes.label}>
                  Test Batch: <span style={{ color: 'red' }}>*</span>
                </Grid>
                <Grid item xs={9}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    name="newbatch"
                    value={isBack ? savedInputValue : inputValue}
                    onChange={handleInputChange}
                  />
                  {
                    isExistingBatchNumber && (
                      <Typography className={classes.errorText}>
                        {message}
                      </Typography>
                    )
                  }
                </Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-end" style={{ marginTop: "30px", marginBottom: "15px" }}>
                <Grid item xs={9} md={11}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="center">Device ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell align="center" colSpan={4}>
                              <Typography>Loading devices...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : connectedDevices && connectedDevices.length > 0 ? (
                          connectedDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((device) => (
                            <TableRow key={device.id}>
                              <TableCell align="center" onClick={() => console.log('TableCell clicked')}>
                              <FormGroup>
                                <FormControlLabel
                                  label=""
                                  control={
                                    <Checkbox
                                      className={classes.radio}
                                      checked={selectedDeviceFinal.includes(device.id)}
                                      onChange={handleCheckBoxChange(device.id)}
                                    />
                                  }
                                />
                              </FormGroup>
                              </TableCell>
                              <TableCell align="center">
                                <Typography>{device.id}</Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={4}>
                              <Typography>No Devices Available</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {
                    selectedDeviceError && (
                      <Grid container justifyContent="flex-end" alignItems="center" style={{ marginTop: "5px" }}>
                        <Typography className={classes.errorText}>
                          Selecting At least One Device is Required.
                        </Typography>
                      </Grid>
                    )
                  }
                  <Grid container justifyContent='center' style={{marginRight:"20px"}}>
                    <Grid item xs={6} md={6} style={{marginTop:"10px"}}>
                      <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={connectedDevices !== undefined && connectedDevices.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}