import React, { useEffect, useRef, useState } from "react";
import { Box, TextField, Button, List, ListItem, Paper, Typography, Grid, InputAdornment, IconButton, InputBase, CircularProgress } from "@material-ui/core";
import { AttachIcon, ChatBotIcon, CloseIcon, CopyIcon, SentIcon, ShareIcon, SoundIcon } from "src/components/Icons";
import { Close } from "@material-ui/icons";
import useStyles from "../../assets/styles";
import { answerChat } from "../components/apiHelper";
import { Skeleton } from "@material-ui/lab";

export const Chatbot = ({ handleClose,messages,setMessages,parseResponse ,messageCount}) => {
    const [inputMessage, setInputMessage] = useState("");
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const chatEndRef = useRef(null);

    const handleSendMessage = () => {
        if (inputMessage.trim()) {
            setMessages([...messages, {text: inputMessage, sender: "user" }]);
            setInputMessage("");
            let questions = {
                "question": inputMessage.trim()
            }
            setInputMessage('')

            handleResponse(questions);
        }
    };

    const handleResponse = async (question) => {
        setLoading(true)
        setMessages(prev => [
            ...prev,
            { sender: "bot", loading: true}, // This triggers the Skeleton
        ]);
        const answer = await answerChat(question)
        if (answer.result) {
            // Replace the loading message with the bot's response
            setMessages(prev =>
                prev.map((msg, index) =>
                    index === prev.length - 1 && msg.loading ? { text: parseResponse(answer.result), sender: "bot" } : msg
                )
            );
        } else if (answer.encoded_string) {
            // Replace the loading message with the bot's image response
            setMessages(prev =>
                prev.map((msg, index) =>
                    index === prev.length - 1 && msg.loading
                        ? { image: <EncodedImage encodedString={answer.encoded_string} />, sender: "bot" }
                        : msg
                )
            );
        }

        setLoading(false)
    }
    useEffect(() => {
        if (chatEndRef.current) {
          chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const IconsComponent = () =>{
        return(
            <Grid container justifyContent="space-around" style={{border: '0.4px solid #D9D9D9',width:'87px',height:'26px',margin:'0px'}}>
                <Grid item >
                    <IconButton size="small">
                        <SoundIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton size="small">
                        <CopyIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton size="small">
                        <ShareIcon />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f0f0f0",
                maxWidth:'500px'
            }}
        >
            <Paper style={{ padding: "10px", margin: "0px", width: '100%', flexGrow: 1, overflowY: "auto" }}>
                <Grid container justifyContent="space-between">
                    <Grid item style={{ display: 'flex' }}>
                        <Grid item style={{ backgroundColor: '#F2F8FF', borderRadius: '50%', padding: '10px', display: 'flex', marginRight: '10px', alignItems: 'center' }} >
                            <ChatBotIcon color='#2A7BEC' size="32" />
                        </Grid>

                        <Typography variant="h6" className={classes.typo} style={{ alignContent: 'center' ,fontWeight:'bold'}}>Device Pulse ChatBot</Typography>
                    </Grid>
                    <Grid item onClick={handleClose} style={{ border: '1px solid #E3E5E5', borderRadius: '50%', padding: '15px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Close />
                    </Grid>
                </Grid>
            </Paper>
            <Paper 
                style={{ padding: "10px", margin: "0px", width: '100%', height: '450px', flexGrow: 1, overflowY: "auto" ,flexDirection:'column',display:'flex'}}>
                {messages.map((msg, index) => (
                        <ListItem key={index}
                            style={{ justifyContent: msg.sender === "user" ? "flex-end" : "flex-start" }}
                        >
                            {msg.sender !== "user" && (
                                <Grid
                                    item
                                    style={{
                                        backgroundColor: '#F2F8FF',
                                        borderRadius: '50%',
                                        padding: '10px',
                                        display: 'flex',
                                        marginRight: '10px',
                                        alignItems: 'flex-start',
                                        alignSelf: 'flex-start'
                                    }}
                                >
                                    <ChatBotIcon color='#2A7BEC' size="20" />
                                </Grid>
                            )}
                            <Grid
                                container
                                direction={msg.sender !== "user" ? 'column' : null}
                                style={{ justifyContent: msg.sender === "user" ? "flex-end" : "flex-start" }}
                            >
                                <Paper
                                    style={{
                                        padding: "10px",
                                        backgroundColor: msg.sender === "user" ? "#1976d2" : "#f5f5f5",
                                        color: msg.sender === "user" ? "#fff" : "#000",
                                        wordWrap: "break-word",
                                        maxWidth: msg.text ? '80%' : '100%',
                                        margin: '0px',
                                        borderRadius: msg.sender !== "user" ? '0px 15px 15px 15px' : '15px 0px 15px 15px',
                                        fontSize: '12px'
                                    }}
                                    className={classes.typo}
                                >
                                    {msg.loading ? (
                                        // Show Skeleton if message is still loading
                                        <Skeleton variant="text" width={100} height={20} />
                                    ) : (
                                        // Render the message text or image
                                        msg.text ? msg.text : msg.image
                                    )}
                                </Paper>
                            </Grid>
                        </ListItem>
                ))}

            </Paper>
            <Grid
                container
                style={{
                    boxShadow: "0px -4px 16px 0px #00000014",
                    padding: "13px",
                    background: "var(--bg-footer, #FFFFFF)",
                    alignSelf: "flex-end", // Move alignSelf to sx
                }}
            >
                <TextField
                    fullWidth
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder="Type your message here..."
                    variant="filled"
                    className={classes.chatBotInput}
                    InputProps={{
                        classes: {
                            root: classes.chatBotInput,
                            input: classes.chatBotInput
                        },
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* <IconButton>
                                    <AttachIcon />
                                </IconButton> */}
                                <IconButton onClick={() => handleSendMessage()}>
                                    <SentIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                />
            </Grid>
        </Box>
    );
};

export const EncodedImage = ({ encodedString }) => {
    return (
        <div>
            <img src={`data:image/jpeg;base64,${encodedString}`}
                alt="Encoded"
                style={{ width: '400px', height: '400px' }}
            />
        </div>
    );
};
