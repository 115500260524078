import * as React from "react";
import { Box, Button, createTheme, Grid, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import useStyles from "../assets/styles";
import { useEffect, useState } from "react";
import { lightGray, primaryBlue, primaryGray } from "src/components/colors";
import { DropDown } from "./DropDown";
import axios from "axios";
import { environment } from "src/environments/environment";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function Step1(props) {
  const classes = useStyles();
  const [productTypes,setProductTypes]=useState([]);
  const [versions,setVersions]=useState([]);
  const [deviceIds,setDeviceIds]=useState([]);
  //const [selecedProductTypeObj,setSelectedProductTypeObj]=useState({productName:'',versions:[]})

  const [errors,setErrors]=useState({});
  const [disableVersionDropdown,setdisableVersionDropdown]=useState(false);

  useEffect(() => {
    fetchProductType();
    
  }, []);
const fetchProductType=async()=>{axios.get(`${environment.host}/core/products`, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
  },
})
  .then((response) => {
    if (response.data.content !== undefined) {
      let productTypeNames = [];
      response.data.content.forEach((productType) => {
        productTypeNames.push(productType.productName);
      });
      setProductTypes(response.data.content.filter(product=>product.remotelyConfigurable===true));

    }
  }
)
  .catch((err) => {
    console.error('Failed to fetch product types:', err);
    setProductTypes([]);
  });}
  const getProductVersions=(productType)=>{
    let activeVersions=[]
    
    productType.versions.forEach(version => {
      if (version.status=='APPROVED'){
        activeVersions.push(version.versionNum);
      }
    });
return activeVersions;
  }
 
  function getProductTypeConfig(productType){
  
    props.setproductTypeRemoteConfigs(productType.versions[0].remoteConfigurations);
    
  }

  const fetchDevicesFiltered=(productType,version)=>{
    
    axios.get(`${environment.host}/core/product/devices?productType=${productType}${(version!='')?(`&currentVersion=${version}`):''}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content !== undefined) {
          let deviceIds = [];
          
          response.data.content.forEach((device) => {
            deviceIds.push(device.id);
            
          });
          setDeviceIds(deviceIds);
        }
      })
      .catch((err) => {
        console.error('Failed to fetch product types:', err);
        setDeviceIds([]);
      });
  }

  const handleError = (name) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  }
  const handleSelectProductType = (value) => {
    props.setSelectedProductType(value);
    handleError('productType');
    const activeVersions = getProductVersions(value);
    setVersions(activeVersions);
 
    if (versions.length===0){
    fetchDevicesFiltered(value.productName,'');
    setdisableVersionDropdown(true);
   }

   props.setSelectedVersion('');
   props.setSelectedDeviceIds('');
    
  };
  const handleSelectProductVersion = (value) => {
    props.setSelectedVersion(value);
    handleError('version');
    fetchDevicesFiltered(props.selectedProductType.productName,value);
  };
  const handleSelectDevice = (value) => {
    props.setSelectedDeviceIds(value);
    handleError('deviceID');
    getProductTypeConfig(props.selectedProductType);
    
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>

        <Grid container className={classes.root} style={{padding:"0 400px " }}>
          <Grid container  className={classes.formContent}   >

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typo}>
                  Product Type:
                  <span style={{ color: 'red' }}>*</span>
                </Typography> 
                <DropDown
                    options={productTypes}
                    type='productType'
                    emptyTag='-Select Product Type-'
                    setSelectOption={handleSelectProductType}
                    value={props.selectedProductType}
                    handleError={handleError}
                    isSingle={true}
                  />
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2} style={{marginTop: "20px"}}>
              <Grid item xs={12} md={12}>
                  <Typography className={classes.typo}>
                    Version:
                  </Typography>  
                  <DropDown
                    options={versions}
                    
                    type='version'
                    emptyTag='-Select Version-'
                    setSelectOption={handleSelectProductVersion}
                    value={props.selectedVersion}
                    handleError={handleError}
                    isSingle={false}
                  />
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2} style={{marginTop: "20px"}}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.typo}>
                    Select Device ID's:
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>  
                  <DropDown
                    options={deviceIds}
                    disabled={disableVersionDropdown} 
                    type='deviceId'
                    emptyTag='-Select Device IDs-'
                    setSelectOption={handleSelectDevice}
                    value={props.selectedDeviceIds}
                    handleError={handleError}
                    isSingle={false}
                  />
              </Grid>
            </Grid>   
          </Grid>
        </Grid>

      </div>
    </ThemeProvider>
  );
}
