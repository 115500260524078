import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { SearchBar } from "src/components/sub-components/SearchBar";
import { primaryBlue, secondaryTextColor, white, } from "src/components/colors";
import { IconDropDown } from "src/components/sub-components/IconDropDown";
import { environment } from "src/environments/environment";
import * as _ from "underscore";
import { Alert } from "@material-ui/lab";
import { ConfigureIcon, ContainedStar, ListIcon, MenuDots, OutlinedStar, Refresh } from "src/components/Icons";
import OTAHistory from "src/components/sub-components/OTAHistory";
import {AddNewFirmWare} from "../product-types/add-new-firmware/AddNewFirmWare";
import axios from "axios";

export function OTAManagerComponent(props) {
  const classes = useStyles();
  const userId = localStorage.getItem("USER_ID");
  const [checked, setChecked] = React.useState([true, false]);
  const [viewDevice, setViewDevice] = useState(false);
  const [upgradeDevice, setUpgradeDevice] = useState(false);
  const [devices, setDevices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [annexed, setAnnexed] = useState([]);
  const [constAnnexed, setConstAnnexed] = useState([]);
  const scrollRefs = React.useRef(null);
  const [max, setMax] = useState(5);
  const [favoriteDevices, setFavouriteDevices] = useState([]);
  const [filterFavouriteDevices, setFilterFavouriteDevices] = useState(false);
  const [selectVersions, setSelectVersions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(true);
  //Filter sets
  const [clients, setClients] = useState([]);
  const [device, setDevice] = useState([]);
  const [productType, setProductType] = useState([]);
  const [status, setStatus] = useState([
    "All",
    "Updated",
    "Pending",
    "Failure",
  ]);
  const [availableVersions, setAvailableVersions] = useState([]);
  const [availablePreviousVersions, setAvailablePreviousVersions] = useState(
    []
  );
  const [constAnnexedFiltered, setConstAnnexedFiltered] = useState([]);
  const uniqueClientDeviceCombinations = new Set();
  const [updateOpen, setUpdateOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [constProducts, setConstProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const closeAddNew = () => {
    setAddNewOpen(false);
  };

  React.useEffect(() => {
    setPage(0);
  }, [search]);

  React.useEffect(() => {
    //getting states to default values
    setDevices([]);
    getAllDevices();
    getAllProducts();
  }, []);
  React.useEffect(() => {
    //getting states to default values
    getProjects();
  }, []);

  const getProjects = () => {
    setProjects([]);
    axios.get(`${environment.host}/user/${userId}/project`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content) {
          setProjects(response.data.content);
          setClients(["All", ..._.pluck(response.data.content, "name").sort()]);
        } else {
          setProjects([]);
        }
      })
      .catch((err) => {
        alert(err);
      });
    getFavourites(userId);
  };
  React.useEffect(() => {
    if (devices.length > 0) {
      let deviceAnnex = [];
      let versionAnnex = [];
      let previousVersionAnnex = [];

      devices.forEach((device) => {
        if (device.otaRequestTopic) {

          const updatedDevice = { ...device };

          if (updatedDevice.product) {
            updatedDevice.product.majorVersionUpgrade = false;

            if (updatedDevice.product.currentProductVersion) {
              versionAnnex.push(updatedDevice.product.currentProductVersion);
            }

            if (updatedDevice.product.allProductVersionsOfDevice) {
              const versions = updatedDevice.product.allProductVersionsOfDevice
                .split(",")
                .map((num) => parseInt(num.trim(), 10));
              previousVersionAnnex.push(...versions);
            }
          }
          deviceAnnex.push(updatedDevice);
        }
      });

      setAnnexed(deviceAnnex);
      setConstAnnexed(deviceAnnex);
      setDevice(["All", ..._.pluck(deviceAnnex, "id").sort()]);

      setAvailableVersions(
        ["All", ...new Set(versionAnnex)].sort((a, b) => a - b)
      );
      setAvailablePreviousVersions(
        ["All", ...new Set(previousVersionAnnex)].sort((a, b) => a - b)
      );
    }
  }, [devices]);

  const getAllDevices = () => {
    axios.get(`${environment.host}/core/device`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content) {
          setDevices(response.data.content);
        } else {
          setDevices([]);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  React.useEffect(() => {
    const uId = localStorage.getItem("USER_ID");
    axios.get(`${environment.host}/core/product/configDetails`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        let datas = response.data.content.productTypes;
        setProductType([
          "All",
          ..._.pluck(response.data.content.productTypes[0], "productType").sort(),
        ]);
      })
      .catch((err) => {
        setProductType([]);
      });
    getFavourites(uId);
  }, []);

  const handleUpdateOpen = (props) => {
    setSelectedDevice(props);
    setUpdateOpen(true);
  };

  const [actionNames, setActionNames] = useState([]);
  var actions_name = [];
  React.useEffect(() => {
    axios.get(`${environment.host}/core/device`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        actions_name.push("ALL");
        response.data.content.map((item) => {
          if (item.product !== undefined) {
            if (item.product.ActionBy !== undefined) {
              actions_name.push(item.product.ActionBy);
            }
          }
        });
      })
      .then(() => {
        const arr = [...new Set(actions_name)];
        setActionNames(arr);
      })
      .catch((err) => console.log(err));
  }, []);

  const getAllProducts = () => {
      axios.get(`${environment.host}/core/products`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        setConstProducts(response.data.content);
      })
      .catch((err) => {
        setConstProducts([]);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const scrollSmoothHandler = (index) => () => {
    scrollRefs.current.scrollIntoViewIfNeeded();
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    const updatedSelectVersions = selectAll
      ? [] // Unselect all
      : annexed.map((ele) => ({
        id: ele.id,
        version: ele.product.currentProductVersion,
        productId: ele.product.productId,
      }));
    setSelectVersions(updatedSelectVersions);
  };
  const getFavourites = (id) => {
    axios.get(`${environment.host}/core/user/${id}/device/favourite`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        setFavouriteDevices(response.data.content);
      })
      .catch((error) => {
        setFavouriteDevices([]);
      });
  };
  const filterFavourite = () => {
    setFilterFavouriteDevices(!filterFavouriteDevices);
    if (filterFavouriteDevices === false) {
      setAnnexed(annexed.filter((obj) => favoriteDevices.includes(obj.id)));
      setPage(0);
    }
    if (filterFavouriteDevices === true) {
      getAllDevices();
    }
  };
  const addOrRemoveFavourite = (favourite, id) => {
    if (favourite === false) {
      axios.put(`${environment.host}/core/user/${userId}/device/${id}?action=add`, id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
        .then((response) => {
          getFavourites(userId);
        })
        .catch((error) => {
          getFavourites(userId);
        });
    }
    if (favourite === true) {
      axios.put(
        `${environment.host}/core/user/${userId}/device/${id}?action=remove`, id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      )
        .then((response) => {
          getFavourites(userId);
        })
        .catch((error) => {
          getFavourites(userId);
        });
    }
  };
  const viewSelectedDevices = () => {
    if (constAnnexedFiltered.length > 0 || selectVersions.length === 0) {
      setAnnexed(constAnnexed);
      setConstAnnexedFiltered([]);
      return;
    }
    const constAnnexedFiltered_ = constAnnexed.filter((el) => {
      return selectVersions.some((f) => {
        return f.id === el.id;
      });
    });
    setConstAnnexedFiltered(constAnnexedFiltered_);
    setAnnexed(constAnnexedFiltered_);
    setPage(0);

  };
  const upgradeDevices = () => {
    if (selectVersions.length > 0) {
      let finalArray = [];
      finalArray = selectVersions.map((e) => _.omit(e, "id"));
      axios.put(`${environment.host}/core/product/version/${localStorage.getItem("USERNAME")}/update`, finalArray, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
        .then((response) => {
          setSelectVersions([]);
          setConstAnnexedFiltered([]);
          setUpgradeDevice(false);
          setViewDevice(false);
          getProjects();
          getAllDevices();
          setFilterFavouriteDevices(false);

          if (response.data.errorCode && response.data.errorCode.length > 0) {
            setReqSuccess(false);
            setOpen(true);
            setErrorMessage(response.data.message);
            setTimeout(() => {
              setOpen(false);
              setErrorMessage("");
            }, 6000);
          } else {
            setReqSuccess(true);
            setOpen(true);
            setTimeout(() => {
              setOpen(false);
            }, 6000);
          }
        })
        .catch((err) => {
          console.log("err0,", err);
          setSelectVersions([]);
          setConstAnnexedFiltered([]);
          getProjects();
          getAllDevices();
          setReqSuccess(false);
          setFilterFavouriteDevices(false);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 6000);
        });
    }
  };
  const selectVersion = (id, version, productId) => {
    let obj = {};
    if (
      selectVersions.filter(
        (e) => e["id"] === id && e["version"].versionNum === version
      ).length > 0
    ) {
      setSelectVersions(selectVersions.filter((e) => e["id"] !== id));
      return;
    }
    if (selectVersions.filter((e) => e["id"] === id).length > 0) {
      let a = selectVersions.filter((e) => e["id"] !== id);
      obj = {
        id: id,
        productId: productId,
        version: {
          versionNum: version,
          devices: [id],
          majorVersionUpgrade: false,
        },
      };
      setSelectVersions([...a, obj]);
      return;
    }
    obj = {
      id: id,
      productId: productId,
      version: {
        versionNum: version,
        devices: [id],
        majorVersionUpgrade: false,
      },
    };
    setSelectVersions((prevState) => [...prevState, obj]);
  };
  const clientNameFilter = (e) => {
    if (e.target.value === "All") {
      setAnnexed(constAnnexed);
      return;
    }
    const selectedClient = constAnnexed.filter(
      (obj) => obj.client === e.target.value
    );
    setAnnexed(selectedClient);
    setFilterFavouriteDevices(false);
  };
  const deviceFilter = (e) => {
    if (e.target.value === "All") {
      setAnnexed(constAnnexed);
      return;
    }
    const selectedDevice = constAnnexed.filter(
      (obj) => obj.id === e.target.value
    );
    setAnnexed(selectedDevice);
    setFilterFavouriteDevices(false);
  };
  const productTypeFilter = (e) => {
    if (e.target.value === "All") {
      setAnnexed(constAnnexed);
      return;
    }
    const selectedProductType = constAnnexed.filter(
      (obj) => obj.product && obj.product.productType === e.target.value
    );
    setAnnexed(selectedProductType);
    setFilterFavouriteDevices(false);
  };
  const statusFilter = (e) => {
    if (e.target.value === "All") {
      setAnnexed(constAnnexed);
      return;
    }

    const selectedStatus = constAnnexed.filter((obj) => {
      if (e.target.value === "Updated") {
        return obj.product && obj.product.currentVersionStatus === "APPROVED";
      } else {
        return (
          obj.product &&
          obj.product.currentVersionStatus === e.target.value.toUpperCase()
        );
      }
    });

    setAnnexed(selectedStatus);
    setPage(0);
    setFilterFavouriteDevices(false);
  };

  const versionFilter = (e) => {
    if (e.target.value === "All") {
      setAnnexed(constAnnexed);
      return;
    }
    const selectedVersion = constAnnexed.filter(
      (obj) =>
        obj.product && obj.product.currentProductVersion === e.target.value
    );
    setAnnexed(selectedVersion);
    setFilterFavouriteDevices(false);
  };

  const previousVersionsFilter = (e) => {
    if (e.target.value === "All") {
      setAnnexed(constAnnexed);
      return;
    }

    const selectedVersion = constAnnexed.filter((obj) => {
      if (obj.product && obj.product.allProductVersionsOfDevice) {
        const versions = obj.product.allProductVersionsOfDevice
          .split(",")
          .map((num) => num.trim());

        // Check if the versions array includes the target value as a number
        const targetValue = parseInt(e.target.value, 10);
        return versions.some(
          (version) => parseInt(version, 10) === targetValue
        );
      }
      return false;
    });
    setAnnexed(selectedVersion);
    setFilterFavouriteDevices(false);
  };

  const availableVersionFilter = (e) => {
    if (e.target.value === "All") {
      setAnnexed(constAnnexed);
      return;
    }
    const availableVersion = constAnnexed.filter(
      (obj) =>
        obj.product &&
        obj.product.availableProductVersions &&
        obj.product.availableProductVersions !== "" &&
        obj.product.availableProductVersions.includes(e.target.value)
    );
    setAnnexed(availableVersion);
    
    setFilterFavouriteDevices(false);
  };

  const ActionByFilter = (e) => {
    if (e.target.value === "ALL") {
      setAnnexed(constAnnexed);
    } else {
      const actionByDevice = constAnnexed.filter(
        (obj) =>
          obj.product &&
          obj.product.ActionBy &&
          obj.product.ActionBy.includes(e.target.value)
      );
      setAnnexed(actionByDevice);
    }
  };

  const toggleState = (checked, id) => {
    let obj = {};

    let updatedSelectVersions = [...selectVersions];
    const existingVersionIndex = updatedSelectVersions.findIndex(
      (e) => e.id === id
    );

    if (existingVersionIndex >= 0) {
      let a = updatedSelectVersions.filter((e) => e.id !== id);
      const existingVersion = updatedSelectVersions.find((e) => e.id === id);
      obj = {
        id: id,
        productId: existingVersion.productId,
        version: {
          versionNum: existingVersion.version.versionNum,
          devices: [id],
          majorVersionUpgrade: checked,
        },
      };
      updatedSelectVersions = [...a, obj];
    }

    setSelectVersions(updatedSelectVersions);
  };

  return (
    <div className={`testing`}>
      <div className="page-heading">
        OTA Manager
      </div>
      {/* <div>{console.log('selectVersions', selectVersions)}</div> */}
      <Grid container className={classes.container}>
        <Grid container alignItems="center">
          <Grid
            item
            xs={6}
            md={3}
            style={{ textAlign: "left" }}
          >
            <SearchBar placeholder="Search Client Name" onChange={(e) => setSearch(e.target.value)} />
          </Grid>
          <Grid item md={7}></Grid>
          <Grid
            item
            xs={6}
            md={2}
            style={{ textAlign: "left", paddingLeft: "10px" }}
          >
            <Button
              variant="contained"
              onClick={() => setAddNewOpen(true)}
              className={classes.addFirmware}
              style={{ textTransform: "none" }}
            >
              Add Firmware
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            backgroundColor: "white",
            marginTop: "20px",
            marginLeft: "10px",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={5}
            md={5}
            style={{
              textAlign: "left",
              paddingLeft: "15px",
              paddingTop: "5px",
              alignItems: "center",
            }}
          >
            {!viewDevice && (
              <>
                <Checkbox
                  /*checked={constAnnexedFiltered.length > 0}
                  // indeterminate={selectVersions.length !== 0 && selectVersions.length !== devices.length}
                  color="primary"
                  // onChange={() => viewSelectedDevices()}*/
                  onClick={() => handleSelectAll()}
                  color="primary"
                  checked={selectAll}
                />
                <Checkbox
                  onClick={() => filterFavourite()}
                  style={{ padding: "0px" }}
                  checked={filterFavouriteDevices}
                  icon={<OutlinedStar color="#0000008A" />}
                  checkedIcon={<ContainedStar />}
                />
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setSelectVersions([]);
                    setUpgradeDevice(false);
                    setViewDevice(false);
                    setAnnexed(constAnnexed);
                    setConstAnnexedFiltered([]);
                    setFilterFavouriteDevices(false);
                  }}
                >
                  <Refresh />
                </IconButton>
                <IconButton style={{ padding: "0px" }}>
                  <MenuDots />
                </IconButton>
              </>
            )}
            {selectAll || selectVersions.length > 0 ? (
              <>
                {!viewDevice && (
                  <Button
                    onClick={() => {
                      setUpgradeDevice(!upgradeDevice), viewSelectedDevices();

                    }}
                    size="small"
                    style={{
                      border: upgradeDevice
                        ? `1px solid #2A7CED`
                        : `1px solid #F2F2F2`,
                      color: upgradeDevice ? "#2A7CED" : "#3C3C3C",
                      fontSize: "12px",
                      textTransform: "none",
                      marginTop: "12px",
                      marginBottom: "12px",
                      marginLeft: "12px",
                      backgroundColor: upgradeDevice ? "#fff" : "#f2f2f2",
                      borderRadius: "54px",
                      paddingInline: "9px",
                      fontWeight: "regular",
                    }}
                  >
                    Upgrade Device
                  </Button>
                )}
                {!upgradeDevice && (
                  <Button
                    onClick={() => {
                      setViewDevice(!viewDevice), viewSelectedDevices();
                    }}
                    size="small"
                    style={{
                      border: viewDevice
                        ? `1px solid #2A7CED`
                        : `1px solid #F2F2F2`,
                      color: viewDevice ? "#2A7CED" : "#3C3C3C",
                      fontSize: "12px",
                      textTransform: "none",
                      backgroundColor: viewDevice ? "#fff" : "#f2f2f2",
                      margin: "12px",
                      borderRadius: "54px",
                      paddingInline: "9px",
                      fontWeight: "regular",
                    }}
                    variant="outlined"
                  >
                    View Device
                  </Button>
                )}
              </>
            ) : null}
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            style={{
              textAlign: "end",
              alignItems: "flex-end",
              display: "flex",
              justifyContent: "end",
            }}
          >
            {selectVersions.length > 0 && upgradeDevice && (
              <>
                <Button
                  onClick={() => (
                    setSelectVersions([]),
                    setConstAnnexedFiltered([]),
                    setAnnexed(constAnnexed),
                    setUpgradeDevice(false),
                    setFilterFavouriteDevices(false)
                  )}
                  size="small"
                  style={{
                    color: "#0000008A",
                    fontSize: "14px",
                    marginRight: "9px",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => upgradeDevices()}
                  size="small"
                  style={{
                    border: `none`,
                    color: "#2A7CED",
                    fontSize: "14px",
                    textTransform: "none",
                  }}
                  variant="outlined"
                >
                  Update
                </Button>
              </>
            )}
          </Grid>
          <Grid
            style={{ textAlign: "right", alignItems: "center" }}
            item
            xs={2}
            md={2}
          >
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={
                annexed !== undefined && annexed.length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{ marginTop: "20px", marginLeft: "10px" }}
        >
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {!viewDevice && (
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{ display: "inline-flex" }}>
                        <Typography
                          display="inline"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          {" "}
                        </Typography>
                      </div>
                      {/* <IconDropDown onChange={(e) => clientNameFilter(e)} data={clients} /> */}
                    </TableCell>
                  )}
                  {viewDevice && (
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{ display: "inline-flex" }}>
                        <Typography
                          display="inline"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          DATE
                        </Typography>
                        <IconDropDown
                          onChange={(e) => clientNameFilter(e)}
                          data={[]}
                        />
                      </div>
                    </TableCell>
                  )}
                  {/* <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Client Name
                      </Typography>
                      <IconDropDown
                        onChange={(e) => clientNameFilter(e)}
                        data={clients}
                      />
                    </div>
                  </TableCell> */}
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Device Name
                      </Typography>
                      <IconDropDown
                        onChange={(e) => deviceFilter(e)}
                        data={device}
                      />
                    </div>
                  </TableCell>
                  {!viewDevice && (
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{ display: "inline-flex" }}>
                        <Typography
                          display="inline"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          Product Type
                        </Typography>
                        <IconDropDown
                          onChange={(e) => productTypeFilter(e)}
                          data={productType}
                        />
                      </div>
                    </TableCell>
                  )}
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Status
                      </Typography>
                      <IconDropDown
                        onChange={(e) => statusFilter(e)}
                        data={status}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Current Version
                      </Typography>
                      <IconDropDown
                        onChange={(e) => versionFilter(e)}
                        data={availableVersions}
                      />
                    </div>
                  </TableCell>
                  {!upgradeDevice && (
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{ display: "inline-flex" }}>
                        <Typography
                          display="inline"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          Previous Versions
                        </Typography>
                        <IconDropDown
                          onChange={(e) => previousVersionsFilter(e)}
                          data={availablePreviousVersions}
                        />
                      </div>
                    </TableCell>
                  )}
                  {viewDevice && (
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{ display: "inline-flex" }}>
                        <Typography
                          display="inline"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          Action By
                        </Typography>
                        <IconDropDown onChange={(e) => null} data={[]} />
                      </div>
                    </TableCell>
                  )}
                  {upgradeDevice && (
                    <>
                      <TableCell
                        style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <div style={{ display: "inline-flex" }}>
                          <Typography
                            display="inline"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            Update Versions
                          </Typography>
                          <IconDropDown
                            onChange={(e) => availableVersionFilter(e)}
                            data={availableVersions}
                          />
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <div style={{ display: "inline-flex" }}>
                          <Typography
                            display="inline"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            Major Version Change
                          </Typography>
                        </div>
                      </TableCell>
                    </>
                  )}
                  {true && (
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{ display: "inline-flex" }}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          Actions
                        </Typography>
                        <IconDropDown
                          onChange={(e) => ActionByFilter(e)}
                          data={actionNames}
                        />
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {annexed.length > 0 &&
                  annexed
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((ele, i) => {
                      const clientDeviceCombination = `${ele.client}_${ele.id}`;

                      // Check if the combination of client name and device name is unique
                      if (
                        !uniqueClientDeviceCombinations.has(
                          clientDeviceCombination
                        )
                      ) {
                        // Add the combination to the set
                        uniqueClientDeviceCombinations.add(
                          clientDeviceCombination
                        );
                        return (
                          <TableRow key={ele.id}
                            style={{
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white"
                            }}>
                            {!viewDevice && (
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  borderBottomColor: "white",
                                  borderTopColor: "white",
                                  display: "flex",
                                }}
                              >
                                {/* <Checkbox color="primary"/> */}
                                <FormControlLabel
                                  label=""
                                  control={
                                    <Checkbox
                                      checked={selectAll ||
                                        selectVersions.filter(
                                          (e) => e["id"] === ele.id
                                        ).length > 0
                                      }
                                      color="primary"
                                      onChange={() => {
                                        if (selectAll) {
                                          return;
                                        }
                                        selectVersions.filter(
                                          (e) => e["id"] === ele.id
                                        ).length > 0
                                          ? setSelectVersions(
                                            selectVersions.filter(
                                              (e) => e["id"] !== ele.id
                                            )
                                          )
                                          : selectVersion(
                                            ele.id,
                                            ele.product.currentProductVersion,
                                            ele.product.productId
                                          )
                                      }}
                                    />
                                  }
                                />
                                <Checkbox
                                  onClick={() =>
                                    addOrRemoveFavourite(
                                      favoriteDevices !== undefined &&
                                      favoriteDevices.includes(ele.id),
                                      ele.id
                                    )
                                  }
                                  style={{ padding: "0px" }}
                                  checked={
                                    favoriteDevices !== undefined &&
                                    favoriteDevices.includes(ele.id)
                                  }
                                  icon={<OutlinedStar color="#00000029" />}
                                  checkedIcon={<ContainedStar />}
                                />
                              </TableCell>
                            )}
                            {viewDevice && (
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  borderBottomColor: "white",
                                  borderTopColor: "white"
                                }}
                              >
                                <Typography className={classes.tableText}>
                                  {ele.product &&
                                    ele.product.Date &&
                                    ele.product.Date.toString().slice(0, 10)}
                                </Typography>
                              </TableCell>
                            )}
                            {/* <TableCell
                              style={{
                                textAlign: "left",
                                borderBottomColor: "white",
                                paddingTop: "18px",
                                paddingBottom: "18px",
                                borderTopColor: "white"
                              }}
                            >
                              <Typography className={classes.tableText}>
                                {ele.client}
                              </Typography>
                            </TableCell> */}
                            <TableCell
                              style={{
                                textAlign: "left",
                                borderBottomColor: "white",
                                borderTopColor: "white"
                              }}
                            >
                              <Typography className={classes.tableText}>
                                {ele.id}
                              </Typography>
                            </TableCell>
                            {!viewDevice && (
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  borderBottomColor: "white",
                                  borderTopColor: "white"
                                }}
                              >
                                <Typography className={classes.tableText}>
                                  {ele.product ? ele.product.productType : ""}
                                </Typography>
                              </TableCell>
                            )}
                            <TableCell
                              style={{
                                textAlign: "left",
                                borderBottomColor: "white",
                                borderTopColor: "white"
                              }}
                            >
                              {ele.product &&
                                ele.product.currentVersionStatus ===
                                "APPROVED" && (
                                  <Chip
                                    className={classes.greenChip}
                                    label="Updated"
                                  />
                                )}
                              {ele.product &&
                                ele.product.currentVersionStatus ===
                                "PENDING" && (
                                  <Chip
                                    className={classes.yellowChip}
                                    label="Pending"
                                  />
                                )}
                              {ele.product &&
                                ele.product.currentVersionStatus ===
                                "FAILURE" && (
                                  <Chip
                                    className={classes.redChip}
                                    label="Failure"
                                  />
                                )}
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "left",
                                borderBottomColor: "white",
                                borderTopColor: "white"
                              }}
                            >
                              <Typography className={classes.tableText}>
                                {ele.product
                                  ? ele.product.currentProductVersion
                                  : ""}
                              </Typography>
                            </TableCell>
                            {!upgradeDevice && (
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  borderBottomColor: "white",
                                  borderTopColor: "white"
                                }}
                              >
                                <Typography className={classes.tableText}>
                                  {ele.product
                                    ? ele.product.allProductVersionsOfDevice &&
                                    ele.product.allProductVersionsOfDevice
                                    : ""}
                                </Typography>
                              </TableCell>
                            )}
                            {viewDevice && (
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  borderBottomColor: "white",
                                  borderTopColor: "white"
                                }}
                              >
                                <Typography className={classes.tableText}>
                                  {ele.product &&
                                    ele.product.ActionBy &&
                                    ele.product.ActionBy.toString()}
                                </Typography>
                              </TableCell>
                            )}
                            {upgradeDevice && (
                              <>
                                {" "}
                                <TableCell
                                  style={{
                                    textAlign: "left",
                                    borderBottomColor: "white",
                                    borderTopColor: "white"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "inline-flex",
                                      maxWidth: "210px",
                                      overflow: "auto",
                                      maxHeight: 48,
                                    }}
                                  >
                                    <div className="hiddenScroll">
                                      {ele.product &&
                                        ele.product.availableProductVersions &&
                                        ele.product.availableProductVersions
                                          .slice(1, -1) // Remove square brackets
                                          .split(",") // Split by comma
                                          .map((ver) => ver.trim()) // Trim whitespace from each version
                                          .filter((trimmedVersion) => trimmedVersion !== "") // Filter out empty strings
                                          .map((trimmedVersion, ind) => {
                                            // Check if it's a whole number or a float
                                            const isFloat = trimmedVersion.includes(".");
                                            
                                            return (
                                              <Chip
                                                clickable={false}
                                                variant="outlined"
                                                key={trimmedVersion}
                                                onClick={() =>
                                                  ele.product
                                                    .currentProductVersion !==
                                                  trimmedVersion &&
                                                  selectVersion(
                                                    ele.id,
                                                    trimmedVersion,
                                                    ele.product.productId
                                                  )
                                                }
                                                style={{ margin: 3 }}
                                                className={
                                                  selectVersions.filter(
                                                    (e) =>
                                                      e["id"] === ele.id &&
                                                      e["version"]
                                                        .versionNum ===
                                                      trimmedVersion
                                                  ).length > 0
                                                    ? classes.blueChip
                                                    : classes.versionChip
                                                }
                                                label={trimmedVersion}
                                              />
                                            );
                                          })}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "left",
                                    borderBottomColor: "white",
                                    borderTopColor: "white"
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) =>
                                          toggleState(e.target.checked, ele.id)
                                        }
                                        style={{
                                          marginLeft: "50px",
                                          padding: "0px",
                                        }}
                                        checked={
                                          selectVersions.filter(
                                            (e) =>
                                              e.id === ele.id &&
                                              e.version.majorVersionUpgrade ===
                                              true
                                          ).length > 0
                                        }
                                        color="primary"
                                      />
                                    }
                                    label={""}
                                  />
                                </TableCell>
                              </>
                            )}
                            <TableCell
                              style={{
                                textAlign: "left",
                                borderBottomColor: "white",
                                borderTopColor: "white"
                              }}
                            >
                              <IconButton
                                style={{ padding: "0px" }}
                                onClick={() => null}
                              >
                                <ConfigureIcon />
                              </IconButton>
                              <IconButton
                                style={{ padding: "0px", paddingLeft: "10px" }}
                                onClick={() => {
                                  handleUpdateOpen({
                                    device: ele,
                                  });
                                }}
                              >
                                <ListIcon />
                              </IconButton>
                              {updateOpen === true && (
                                <OTAHistory
                                  device={selectedDevice}
                                  close={() => {
                                    setUpdateOpen(false);
                                  }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={reqSuccess === true ? "success" : "warning"}>
          <h3 style={{ textAlign: "left" }}>
            {reqSuccess === true ? "Success" : "Error"}
          </h3>
          {reqSuccess
            ? "Successfully Updated"
            : errorMessage.length > 0
              ? errorMessage
              : "Something Went Wrong"}
        </Alert>
      </Snackbar>


      <AddNewFirmWare
        open={addNewOpen}
        onClose={() => closeAddNew()}
        refresh={() => getAllProducts()}
        products={constProducts}
      />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  container: {
    paddingTop: "20px",
    paddingRight: "9px",
  },
  versionSelected: {
    backgroundColor: theme.palette.grey[50],
    border: `2px solid #2A7CED`,
    color: "#2A7CED",
    margin: "6",
  },
  version: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid #707170`,
    color: "#707170",
    margin: "6",
  },
  filter: {
    backgroundColor: white,
    color: secondaryTextColor,
    boxShadow: "none",
  },
  addFirmware: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  versionCircleText: {
    fontSize: "10px",
  },
  versionText: {
    fontSize: "14px",
    color: "#2A7CED",
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
  },
  versionChip: {
    backgroundColor: "#e6edf7",
    color: "#2a7ced",
    height: "20px",
    border: `1px solid #2A7CED`,
  },
  availableVersionChip: {
    backgroundColor: "#fff",
    color: "#707070",
    height: "20px",
  },
  yellowChip: {
    backgroundColor: "#FFB40A1F",
    color: "#FFB40A",
    height: "20px",
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#EE3939",
    height: "20px",
  },
  blueChip: {
    backgroundColor: "#2a7ced",
    color: "#fff",
    height: "20px",
  },
  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px",
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px",
  },
}));
const NewTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    padding: "10px",
  },
})(Tooltip);

