import { ThemeProvider, Grid, Typography, createTheme, Button } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { lightGray, primaryBlue, primaryGray, white } from 'src/components/colors';
import { ProjectClientConfigurationsComponent } from './ProjectClientConfigurations';
import useStyles from 'src/app/maintenance/assets/styles';
import {useHistory} from 'react-router-dom';
import { KnowledgeBasedUploadsComponents } from './KnowledgeBasedUploads';
import { SaveIcon, TrashIcon, UploadFile, UploadIcon } from 'src/components/Icons';
import { ApiConfigurationComponent } from './ApiConfiguration';
import { message } from 'antd';
import { environment } from 'src/environments/environment';
import { analyticsConfiguration, apiConfiguration, uploadFile } from '../components/apiHelper';
import { Loader } from '../components/Loader';
import { ToasterComponent } from '../components/Toaster';

const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
});

const STEPS = [
    {
      text: "Project / Client Configurations",
      activate: true,
    },
    {
      text: "Knowledge Based Uploads",
      activate: false,
    },
    {
      text: "API Configurations",
      activate: false,
    },
];

export const HumanoidNotificationSystemComponent = () =>{
    const [steps,setSteps] = useState(STEPS)
    const [step,setStep] = useState(0)
    const [fileStatus, setFileStatus] = useState("")
    const classes = useStyles()
    const history = useHistory();
    const [files, setFiles] = useState([])
    const [sensorApi,setSensorApi] = useState('')
    const [errors,setErrors] = useState([])  
    const [projectName, setProjectName] = useState("")
    const [isLoading,setIsLoading] = useState(false)
    const [batchNumber,setBatchNumber] = useState('')
    const [metaApi,setMetaApi] = useState('')

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)


    useEffect(() => {
        updateStepActivation(step);
      }, [step]);
    
      function updateStepActivation(currentStep) {
        const updatedSteps = steps.map((step, index) => ({
          ...step,
          activate: index === currentStep,
        }));
        setSteps(updatedSteps);
    }

    function decreaseStep() {
      if (step === 0) {
        // resetFormFields();
        history.push(`/TestAutomation`)
      } else {
        setStep(prevStep => 
          Math.max(prevStep - 1, 0)
        );
      }
    }

    function handleSubmit() {
      // Submit logic here
      if (step === 2) {
        // resetFormFields();
        history.push(`/TestAutomation`)
      }
    }

    const handleProjectConfiguration = async () =>{
      let error : any = {}
      if(projectName == "") {
        error.projectConfig = "Please enter project name"
      }
      if(batchNumber == ''){
        error.batchNumberError = 'Please select a batch number'
      }
      setErrors(error)
      if (Object.keys(error).length > 0) {
        return true;
      }
        try{
          setIsLoading(true)
          let analytics = {analysis_configs:projectName}
          let response = await analyticsConfiguration(analytics)
          setIsToasterOpen(true);
          setReqSuccess(true);
          setToasterMessage(response[0]);
          setTimeout(() => {
            setIsToasterOpen(false);
          }, 2500);
          setStep(prevStep => Math.min(prevStep + 1, STEPS.length - 1));
        }catch(err){
          console.log('Error fetching api submit',err)
        }
        setIsLoading(false)
      
    }

    const handleKnowledgedBasedUpload = () =>{
      if(files.length == 0){
        setErrors(prevError =>({
          ...prevError,
          KnowledgedBase:'Please select a file'
        }))
        return true;
      }
    }

    const handleApiConfigSubmit = () =>{
      let error : any = {}
      if(sensorApi == ""){
        error.sensorApiError = "Sensor API is Required"
      }
      if(metaApi == ""){
        error.metaApiError = "Meta API is Required"
      }
      setErrors(error)
      if(Object.values(error).length > 0){
        return true;
      }
      handleApiSubmit()
    }

    function increaseStep() {
      const stepHandlers = [
          handleProjectConfiguration,
          handleKnowledgedBasedUpload,
          handleApiConfigSubmit,
      ];
  
      const currentHandler = stepHandlers[step];
      
      if (currentHandler) {
          if (currentHandler()) {
              return; // If the handler returns true, exit early
          }
      }
  
      // If we're at the last step or the handler returned false
      if (step === 2) {
          history.push(`/TestAutomation`);
      } else {
          setStep(prevStep => Math.min(prevStep + 1, STEPS.length - 1));
      }
  }
  

    const handleSaveAll = async () =>{
      let formData = new FormData()
      try{
        setIsLoading(true)
        files.forEach((file,index)=>{
          if (file.file) {
              formData.append('files', file.file, file.name);
          }
        })
        const response = await uploadFile(formData)
      
        if(response){
          setStep(prevStep => 
            Math.min(prevStep + 1, STEPS.length - 1)
          );
        }
      }catch(err){
        console.log('Uploading file error :',err)
      }
      setIsLoading(false)
      
    }

    const handleApiSubmit =async () =>{
      
      try{
        setIsLoading(true)
        let api = {sensor_data_api:sensorApi}
        let response = await apiConfiguration(api)
        setIsToasterOpen(true);
        setReqSuccess(true);
        setToasterMessage("Success");
        setTimeout(() => {
          setIsToasterOpen(false);
        }, 2500);
      }catch(err){
        console.log('Error fetching api submit',err)
      }
      setIsLoading(false)
    }
    
    const onChangeProjectName = (event) =>{
      let {value} = event.target
      if(batchNumber == ''){
        setErrors(prevError =>({
          ...prevError,
          batchNumberError:'Batch is required'
        }))
        return;
      }
      setProjectName(value)
      setErrors(prevError => ({
        ...prevError,
        projectConfig:null
      }))
    }

    function renderStepsComponent() {
      if (step === 1) {
        return <KnowledgeBasedUploadsComponents files={files} setFiles={setFiles} errors={errors} setErrors={setErrors}/>;
      }else if(step == 2){
        return <ApiConfigurationComponent setMetaApi={setMetaApi} setSensorApi={setSensorApi} errors={errors} setErrors={setErrors} />
      }else {
        return <ProjectClientConfigurationsComponent 
          setProjectName={setProjectName} 
          projectName={projectName} 
          errors={errors} 
          setErrors={setErrors} 
          onChangeProjectName={onChangeProjectName}
          setLoading = {setIsLoading}
          setSelectedBatchNumber={setBatchNumber}
          selectedBatchNumber = {batchNumber}
        />;
      }
    };
    
    return(
      <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <div className="page-heading" style={{ marginBottom: "50px" }}>
          Step 05
        </div>

        <Grid container alignItems="center" >
          <Grid item xs={12} md={12} style={{ textAlign: "center", paddingBottom: "40px" }}>
            <h4>Humanoid Notification System</h4>
          </Grid>
        </Grid>

        <Grid container style={{textAlign: "center",paddingRight: "80px"}}>
        <Grid item xs={1} md={4}></Grid>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2} md={1}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Circle number={index + 1} active={step.activate}completed={steps.findIndex(s => s.activate) > index}/>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "12px",
                      paddingTop: "15px",
                      fontFamily: "Poppins, sans-serif",
                      color: step.activate ? primaryBlue : primaryGray,
                    }}
                  >
                    {step.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {index !== steps.length - 1 && (
              <Grid item xs={2} md={1}>
                <hr
                  style={{
                    marginTop: "32px",
                    opacity: steps[index + 1].activate ? "1" : "0.12",
                  }}
                  color={
                    steps[index + 1].activate ? primaryBlue : "rgba(112, 112, 112, .12)"
                  }
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
      <Grid container style={{paddingTop:'20px',width:'60%'}} alignItems="center" justifyContent="center" direction="column">
        <Grid item style={{width:'100%',marginBottom:'15px'}}>
          {renderStepsComponent()}
        </Grid>
        
        <Grid item xs={12} md={12} className={classes.root}  style={{width:'100%'}}>
            <Grid container spacing={2} justifyContent="flex-end" className="testing">
                {step !== 3 && (
                    <Button
                      onClick={decreaseStep}
                      variant="outlined"
                      className={classes.backButton}
                      style={{ margin:'10px'}}
                    >
                      {step === 1 || step === 2 ? "Back" : "Cancel"}
                    </Button>
                )}
                {/* {(step == 1) && 
                    <Button
                      variant="outlined"
                      className={classes.button}
                      style={{margin:'10px',color:'#FF4343',borderColor:'#FF4343'}}
                      startIcon={<TrashIcon/>}
                    >
                      Remove All
                    </Button>
                } */}
                    <Button
                      onClick={ step == 1 && files.length != 0 ? handleSaveAll : increaseStep}
                      variant="contained"
                      className={`${classes.getStarted} ${classes.button}`}
                      style={{margin:'10px'}}
                      startIcon = {
                        step === 1
                          ? files.length !== 0
                            ? <SaveIcon color="white" />
                            : <UploadIcon color='white' />
                          : null
                      }
                    >
                      {step === 1 
                        ? files.length == 0
                          ? 'Upload All' 
                          : 'Save All'
                        : "Submit"}
                    </Button>
              </Grid>
          </Grid>
      </Grid>
      <Loader loading={isLoading} />
      <ToasterComponent
                  toaster={isToasterOpen}
                  message={toasterMessage}
                  reqSuccess={reqSuccess}
                />
      </div>
      </ThemeProvider>
    )
} 

const Circle = (props) => {
    const paddedNumber = (props.number).toString().padStart(2, '0');
    return (
      <div>
        <div
          style={{
            borderRadius: "50%",
            width: "65px",
            height: "65px",
            backgroundColor: props.completed ? primaryBlue : white,
            border: props.active ? "2px solid #2A7CED" : (props.completed ? `2px solid ${primaryBlue}` : "0.25px solid #8F8F8F"),
            textAlign: "center",
            paddingTop: "10px",
            boxShadow: "0px 4px 8px #0000001F",
          }}
        >
          <h5 style={{
            paddingTop: "10px",
            fontSize: "16px",
            color: props.active ? primaryBlue : (props.completed ? white : primaryGray)
          }}>
            {paddedNumber}
          </h5>
        </div>
      </div>
    );
};