import { Button, createTheme, Grid, IconButton, ThemeProvider, LinearProgress, Box } from '@material-ui/core'
import * as React from 'react'
import { DocumentIcon, PenIcon, TrashIcon, UploadIcon } from 'src/components/Icons';
import useStyles from '../../assets/styles';
import { DragDrop } from 'src/components/sub-components/DragAndDrop';
import { Upload, Icon, message, Progress, Typography } from 'antd';
import { useEffect, useState } from 'react';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
});

export const KnowledgeBasedUploadsComponents = () => {
    const classes = useStyles();
    const Dragger = Upload.Dragger;
    const [fileStatus, setFileStatus] = useState("")
    const [fileName, setFileName] = useState("")
    const [fileSize, setFileSize] = useState(0)
    const [files, setFiles] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            setFiles(prevFiles =>
                prevFiles.map(file => {
                    if (file.progress >= 100) return file; // Stop increment if progress reaches 100%
                    return { ...file, progress: file.progress + 10 }; // Increment progress by 10%
                })
            );
        }, 1000);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [files]);

    const props = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        action: '//jsonplaceholder.typicode.com/posts/',
        onChange(info) {
            let selectedFiles = []
            const { status, name, size } = info.file;
            if (status === 'uploading') {
                setFiles(prevFiles => {
                    const fileExists = prevFiles.find(file => file.name === name);
                    if (!fileExists) {
                        return [...prevFiles, { name, size: Math.round(size / 1024), progress: 0 }];
                    }
                    return prevFiles;
                });
            } else if (status === 'done') {
                console.log('Upload successful:', name, size);
                message.success(`${name} file uploaded successfully.`);
                setFileStatus(status);
                setFileName(name);
                let roundSize = Math.round(size / 1024)
                console.log(roundSize)
                setFiles(prevFiles => prevFiles.map(file =>
                    file.name === name ? { ...file, progress: 100 } : file
                ));
                setFileSize(roundSize);
            } else if (status === 'error') {
                console.log('Upload failed:', name);
                message.error(`${name} file upload failed.`);
                setFileStatus(status);
            }
            console.log(selectedFiles)
        },
    };

    const FileComponent = ({ fileName, fileSize }) => {
        return (
            <div>

            </div>
        )
    }

    return (
        <ThemeProvider theme={theme} >
            <div className='testing'>
                <Grid container direction='column'>
                    {fileStatus == 'uploading' || fileStatus == 'done' &&
                        <Grid container>
                            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                <Upload {...props}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<UploadIcon color='#2A7BEC' />}
                                        className={classes.button}
                                        style={{ borderColor: '#2A7BEC', color: '#2A7BEC' }}
                                    >
                                        Upload Files
                                    </Button>
                                </Upload>
                            </Grid>

                            <Grid container style={{ marginTop: '15px' ,maxHeight:'450px',overflowY:'auto'}}>
                                {fileName !== "" && fileSize && (
                                    files.map((file, index) => (
                                        <Grid container key={index} direction='column' style={{border: '1px solid #CACACA',borderRadius:'4px',padding:'10px',marginTop:'15px'}}>
                                            <Grid item xs={12} md={12}>
                                                <Grid container justifyContent='space-between'>
                                                    <Grid item style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                                                        <DocumentIcon />
                                                        <span style={{ color: '#353535', textAlign: 'center', fontFamily: 'Poppins', margin: '0 10px' }}>
                                                            {file.name}
                                                        </span>
                                                        <PenIcon />
                                                    </Grid>
                                                    <Grid item>
                                                        <TrashIcon />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ color: '#B4B2B2', textAlign: 'center', fontFamily: 'Poppins', display: 'flex' }}>
                                                {`${file.size} MB`}
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                                                <progress value={file.progress} max={100} style={{ width: '100%', marginRight: '10px' }} />
                                                <span>{`${file.progress}%`}</span>
                                            </Grid>
                                        </Grid>

                                    ))
                                )}
                            </Grid>


                        </Grid>
                    }

                    {fileStatus == "" &&
                        <Grid item className={classes.dragAndDrop}>
                            <Dragger {...props}>
                                <DragDrop />
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-text" style={{ color: '#B4B2B2', textAlign: 'center' }}>maximum upload file size : 5MB</p>
                            </Dragger>
                        </Grid>
                    }



                </Grid>
            </div>
        </ThemeProvider>
    )
}