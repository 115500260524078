import {uploadFile} from "react-s3";
import {environment} from "src/environments/environment";
import {Buffer} from "buffer";
import axios from "axios";

window.Buffer = window.Buffer || Buffer;

const flowChartConfig = {
  dirName: "product-types/flow-chart",
  bucketName: "senzmate-polar",
  region: "us-west-2",
  accessKeyId: "AKIA5XGNU22IOH7IWSV4",
  secretAccessKey: "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",
  s3Url: "s3://senzmate-polar/product-types/flow-chart/",
};

export const submit = async (
  binFile,
  binFileName,
  flowChart,
  version,
  parameters,
  existingFlowChart,
  id,
  majorVersionUpgrade
) => {
 
  const dependencyMap = new Map();
  parameters.forEach(param => {
      const paramId = param.parameterId;
      const joinParameters = param.joinParameter;

      joinParameters.forEach(joinParamId => {
        if (!dependencyMap.has(joinParamId)) {
          dependencyMap.set(joinParamId, []);
        }
        if (!dependencyMap.get(joinParamId).includes(paramId)) {
          dependencyMap.get(joinParamId).push(paramId);
        }

        if (!dependencyMap.has(paramId)) {
          dependencyMap.set(paramId, []);
        }
        if (!dependencyMap.get(paramId).includes(joinParamId)) {
          dependencyMap.get(paramId).push(joinParamId);
        }
      });

      delete param.joinParameter;
    });


    const joinParametersObj = Array.from(dependencyMap).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  

  let flowPath;
  if (existingFlowChart === "") {
    flowPath = await uploadFlowChart(flowChart, flowChartConfig);
  }
  if (existingFlowChart !== "") {
    flowPath = existingFlowChart;
  }
  return updateProductWithFileLinks(
    id,
    version,
    parameters,
    binFile,
    binFileName,
    flowPath,
    dependencyMap,
    majorVersionUpgrade
  );
};

export const uploadFlowChart = (flowFile, flowChartConfig) => {
  return uploadFile(flowFile, flowChartConfig)
    .then((data) => {
      return data.location;
    })
    .catch((err) => {
      return err;
    });
};

export const updateProductWithFileLinks = (
  id,
  version,
  remoteConfig,
  bin,
  binFileName,
  flow,
  joinParameters,
  majorVersionUpgrade
) => {
  const versionObject = {
    versionNum: version,
    fileName: binFileName,
    flowChartURL: flow,
    remoteConfigurations: remoteConfig,
    joinParameters: joinParameters,
    majorVersionUpgrade: majorVersionUpgrade,
  };
  const formData = new FormData();
  formData.append("binFile", bin);
  formData.append("version", JSON.stringify(versionObject));

  return axios.post(`${environment.host}/core/product/${id}/user/${localStorage.getItem("USERNAME")}`, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return;
    });
};
