import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  Theme,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {Alert} from "@material-ui/lab";
import React, {useEffect, useRef, useState} from "react";
import {CloseIcon, CloseIconColor, ExpandIconTwo} from "../../Icons";
import {TextBar} from "../TextBar";
import {primaryBlue, primaryGray, textBarColor} from "../../colors";
import {environment} from "src/environments/environment";
import DeviceSettingUpdatePopup from "./DeviceSettingUpdatePopup";
import axios from "axios";

const EditConfiguration = (props) => {
  const device = props.device.device.deviceParameterConfiguration;
  const {productType, versionNum, deviceCategory} =
    props.device.device.deviceParameterConfiguration;

  const sortedDetailsArray =
    props.device.device.deviceParameterConfiguration.remoteConfigurations.sort(
      (a, b) => a.id - b.id
    );

  const joinParameters =
    props.device.device.deviceParameterConfiguration.joinParameters;
  const [detailsArray, setDetailsArray] = useState(sortedDetailsArray);
  const constDetailsArray = sortedDetailsArray;

  const [openIcon1, setOpenIcon1] = useState(false);
  const [openIcon2, setOpenIcon2] = useState(false);
  const [openIcon3, setOpenIcon3] = useState(false);

  const [parametersToEdit, setParametersToEdit] = useState([]);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [updateMsgOpen, setUpdateMsgOpen] = useState(false);
  const contentContainerRef = useRef(null);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  useEffect(() => {
    if (updateMsgOpen) {
      contentContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [updateMsgOpen]);

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      paddingTop: "20px",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: "#F4F5F7",
    },
    label: {
      fontSize: "14px",
      color: primaryGray,
      paddingBottom: "5px",
    },
    helperText: {
      color: "red",
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: 1,
      width: "100%",
    },
    textBarStyle: {
      backgroundColor: textBarColor,
      height: "44px",
    },
    textBarMessageStyle: {
      width: "219px",
      backgroundColor: textBarColor,
      height: "44px",
    },
    labelMessage: {
      fontSize: "14px",
      color: primaryGray,
      paddingBottom: "5px",
      width: "121px",
    },
    select: {
      width: "252px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      backgroundColor: textBarColor,
      height: "44px",
      padding: "4px",
      fontSize: "14px",
    },
    menuItem: {
      fontSize: "14px",
    },
  }));

  const classes = useStyles();

  const handleSaveClick = () => {
    const networkAndCommunicationParams = {};
    const topicFormatParams = {};
    const messageFormatParams = {};

    detailsArray.forEach((item) => {
      if (item.checkUpdate === true) {
        switch (item.parameterCategory) {
          case "Network & Communication":
            networkAndCommunicationParams[item.parameter] = item.defaultValue;
            break;
          case "Topic Format & Interval":
            topicFormatParams[item.parameter] = item.defaultValue;
            break;
          case "Message Format":
            messageFormatParams[item.parameter] = item.defaultValue;
            break;
          default:
            break;
        }
      }
    });

    const updateHistory = {
      actionBy: localStorage.getItem("USERNAME"),
      networkAndCommunication: networkAndCommunicationParams,
      topicFormat: topicFormatParams,
      messageFormat: messageFormatParams,
    };

    const data = {
      productType,
      versionNum,
      deviceCategory,
      remoteConfigurations: detailsArray.map(({checkUpdate, ...item}) => ({
        ...item,
        defaultValue:
          item.id === "13" || item.id === "14"
            ? item.defaultValue.endsWith(",")
              ? item.defaultValue
              : item.defaultValue + ","
            : item.defaultValue,
      })),
      updateHistory: [updateHistory],
    };
    setLoading(true);
    axios.put(`${environment.host}/core/device/deviceParameterConfiguration/${props.device.device.id}`, data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (
          (Object.keys(networkAndCommunicationParams).length > 0 ||
            Object.keys(topicFormatParams).length > 0 ||
            Object.keys(messageFormatParams).length > 0) &&
          response.data.message === "Success"
        ) {
          setUpdateMsgOpen(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const readChips = () => {
  };

  const updateDetailsArray = (prevState, index, value) => {
    return prevState.map((detail, i) =>
      i === index
        ? {...detail, defaultValue: value, checkUpdate: true}
        : detail
    );
  };

  const updateParamsToAdd = (
    detailsArray,
    constDetailsArray,
    joinParameters
  ) => {
    const paramsToAdd = [];

    //todo:optimize this
    for (const paramId of Object.keys(joinParameters)) {
      const paramValueArray = Array.isArray(joinParameters[paramId])
        ? joinParameters[paramId]
        : [];
      const matchingDetail = detailsArray.find(
        (detail) => detail.id === paramId
      );
      const constMatchingDetail = constDetailsArray.find(
        (constDetail) => constDetail.id === paramId
      );
      if (matchingDetail.defaultValue !== constMatchingDetail.defaultValue) {
        for (const id of paramValueArray) {
          const matchingDetail = detailsArray.find(
            (detail) => detail.id === id
          );
          const constMatchingDetail = constDetailsArray.find(
            (constDetail) => constDetail.id === id
          );

          if (
            matchingDetail &&
            constMatchingDetail &&
            matchingDetail.defaultValue === constMatchingDetail.defaultValue
          ) {
            paramsToAdd.push(matchingDetail.parameter);
          }
        }
      }
    }
    return paramsToAdd;
  };

  const handleInputChange = (event, index) => {
    const {value} = event.target;
    const trimmedValue = value.trim();
    setDetailsArray((prevState) => {
      const updatedDetailsArray = updateDetailsArray(
        prevState,
        index,
        trimmedValue
      );
      const updatedParamsToAdd = updateParamsToAdd(
        updatedDetailsArray,
        constDetailsArray,
        joinParameters
      );
      setParametersToEdit(updatedParamsToAdd);

      setChangesMade(true);
      const parameterWithId8 = updatedDetailsArray.find((item) => item.id == 8);
      const parameterWithId7 = updatedDetailsArray.find((item) => item.id == 7);

      if (
        parameterWithId8 &&
        parameterWithId7 &&
        parameterWithId7.defaultValue !== 0 &&
        parameterWithId7.defaultValue === "" &&
        parameterWithId8 === ""
      ) {
        const isMultiple =
          parameterWithId8.defaultValue % parameterWithId7.defaultValue === 0;

        if (!isMultiple) {
          setShowValidationAlert(true);
        } else {
          setShowValidationAlert(false);
        }
      }
      return updatedDetailsArray;
    });
  };

  const handleSelectChange = (event, index) => {
    const {value} = event.target;
    setDetailsArray((prevState) => {
      const updatedDetailsArray = prevState.map((detail, i) => {
        if (i === index) {
          const updatedDefaultValues = [...detail.defaultValues];
          const oldValueIndex = updatedDefaultValues.indexOf(value);

          if (oldValueIndex !== -1) {
            updatedDefaultValues[oldValueIndex] = detail.defaultValue;
          }
          return {
            ...detail,
            defaultValue: value,
            defaultValues: updatedDefaultValues,
            checkUpdate: true,
          };
        } else {
          return detail;
        }
      });
      const updatedParamsToAdd = updateParamsToAdd(
        updatedDetailsArray,
        constDetailsArray,
        joinParameters
      );
      setParametersToEdit((prevParams) => [
        ...new Set([...prevParams, ...updatedParamsToAdd]),
      ]);
      setChangesMade(true);
      return updatedDetailsArray;
    });
  };

  const keyOrder = ["productType", "versionNum", "deviceCategory", "deviceIds"];

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={"md"}
        style={{width: "766px", height: "591px"}}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: "0.4",
          },
        }}
        PaperProps={{
          // className: classes.paper,
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: "1",
            boxShadow: "0px 4px 30px #00000008",
          }}
        >
          <Grid item xs={10} md={10}>
            <DialogTitle>Edit Configuration</DialogTitle>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            style={{display: "flex", justifyContent: "center"}}
          >
            <IconButton onClick={props.onClose}>
              <CloseIconColor/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
        <>
          <DialogContent>
            <Grid container className={classes.container}>
              {!updateMsgOpen &&
                keyOrder.map((key, id) => {
                  var label = "";
                  if (key === "productType") {
                    label = "Product Type";
                  } else if (key === "versionNum") {
                    label = "Version";
                  } else if (key === "deviceCategory") {
                    label = "Device Category";
                  } else if (key === "deviceIds") {
                    label = "Device IDs";
                  } else {
                    label = key;
                  }
                  if (device.hasOwnProperty(key)) {
                    return (
                      <Grid
                        key={id}
                        item
                        xs={6}
                        md={12}
                        style={{
                          textAlign: "left",
                          marginBottom: "22px",
                          paddingRight: "2%",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                            fontStyle: "normal",
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: primaryGray,
                          }}
                        >
                          {label}
                        </Typography>
                        <TextBar
                          disabled={true}
                          variant="outlined"
                          defaultValue={device[key]}
                          error={{error: false}}
                          style={classes.textBarStyle}
                        />
                      </Grid>
                    );
                  }
                })}
              {updateMsgOpen && (
                <div ref={contentContainerRef}>
                  <DeviceSettingUpdatePopup
                    id={props.device.device.id}
                    productType={productType}
                    detailsArray={detailsArray}
                    onClose={() => {
                      setUpdateMsgOpen(false);
                    }}
                  />
                </div>
              )}


              <Grid
                container
                className={classes.container}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} md={4}>
                  <FormLabel
                    style={{
                      color: primaryGray,
                      opacity: "1",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    Network & Communication
                  </FormLabel>
                </Grid>
                <Grid item xs={7} md={7}>
                  <Divider className={classes.divider}/>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    style={{
                      backgroundColor: "ButtonShadow",
                      borderRadius: "80%",
                      boxShadow: "0px 3px 6px #00000029",
                      padding: "3px",
                    }}
                    onClick={() => setOpenIcon1(!openIcon1)}
                  >
                    <ExpandIconTwo rotation={openIcon1}/>
                  </IconButton>
                </Grid>
              </Grid>
              {/* /////////////////////////////////////////////////////////////////// */}

              {openIcon1 === true && (
                <Grid
                  container
                  className={classes.container}
                  style={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {detailsArray.map((item, id) => {
                    if (item.parameterCategory === "Network & Communication") {
                      let combinedValues = [];
                      if (item.defaultValues && item.defaultValues.length > 0) {
                        combinedValues = [
                          item.defaultValue,
                          ...item.defaultValues,
                        ];
                      }

                      return (
                        <Grid
                          key={id}
                          item
                          xs={6}
                          md={12}
                          style={{
                            textAlign: "left",
                            marginBottom: "22px",
                            paddingRight: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              color: primaryGray,
                              fontSize: "14px",
                              lineHeight: "21px",
                              opacity: "1",
                              width: "89px",
                              height: "44px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.parameter.charAt(0).toUpperCase() +
                              item.parameter.slice(1)}
                          </Typography>
                          {item.id == 6 ? (
                            <div
                              style={{display: "flex", alignItems: "center"}}
                            >
                              <input
                                type={passwordVisible ? "text" : "password"}
                                value={item.defaultValue}
                                onChange={(event) =>
                                  handleInputChange(event, id)
                                }
                                style={{
                                  width: "228px",
                                  marginLeft: "20px",
                                  backgroundColor: textBarColor,
                                  height: "44px",
                                  border: "none",
                                  outline: "none",
                                }}
                              />
                              <IconButton
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                                style={{marginLeft: "10px"}}
                              >
                                {passwordVisible ? (
                                  <VisibilityOffIcon/>
                                ) : (
                                  <VisibilityIcon/>
                                )}
                              </IconButton>
                            </div>
                          ) : item.defaultValues !== null &&
                          item.defaultValues.length > 0 ? (
                            <Select
                              className={classes.select}
                              value={item.defaultValue}
                              onChange={(event) =>
                                handleSelectChange(event, id)
                              }
                            >
                              {combinedValues.map((value, index) => (
                                <MenuItem
                                  className={classes.menuItem}
                                  key={index}
                                  value={value}
                                >
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <TextBar
                              style={classes.textBarStyle}
                              defaultValue={item.defaultValue}
                              error={{error: false}}
                              onChange={(event) => handleInputChange(event, id)}
                            />
                          )}
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              )}

              <Grid
                container
                className={classes.container}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} md={4}>
                  <FormLabel
                    style={{
                      color: primaryGray,
                      opacity: "1",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    Topic Format & Interval
                  </FormLabel>
                </Grid>
                <Grid item xs={7} md={7}>
                  <Divider className={classes.divider}/>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    style={{
                      backgroundColor: "ButtonShadow",
                      borderRadius: "80%",
                      boxShadow: "0px 3px 6px #00000029",
                      padding: "3px",
                    }}
                    onClick={() => setOpenIcon2(!openIcon2)}
                  >
                    <ExpandIconTwo rotation={openIcon2}/>
                  </IconButton>
                </Grid>
              </Grid>
              {/* //////////////////////////////////////////////////// */}

              {openIcon2 === true && (
                <Grid container className={classes.container}>
                  {detailsArray.map((item, id) => {
                    if (item.parameterCategory === "Topic Format & Interval") {
                      let combinedValues: any[] = [];
                      if (item.defaultValues && item.defaultValues.length > 0) {
                        const set = new Set([
                          item.defaultValue,
                          ...item.defaultValues,
                        ]);
                        combinedValues = Array.from(set);
                      }

                      return (
                        <Grid
                          key={id}
                          item
                          xs={6}
                          md={12}
                          style={{
                            textAlign: "left",
                            marginBottom: "22px",
                            paddingRight: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              color: primaryGray,
                              fontSize: "14px",
                              lineHeight: "21px",
                              opacity: "1",
                              width: "89px",
                              height: "44px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.parameter.charAt(0).toUpperCase() +
                              item.parameter.slice(1)}
                          </Typography>
                          {item.defaultValues !== null &&
                          item.defaultValues.length > 0 ? (
                            <Select
                              className={classes.select}
                              value={item.defaultValue}
                              onChange={(event) =>
                                handleSelectChange(event, id)
                              }
                            >
                              {combinedValues.map((value, index) => (
                                <MenuItem
                                  className={classes.menuItem}
                                  key={index}
                                  value={value}
                                >
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <TextBar
                              style={classes.textBarStyle}
                              defaultValue={item.defaultValue}
                              error={{error: false}}
                              onChange={(event) => handleInputChange(event, id)}
                            />
                          )}
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              )}

              <Grid
                container
                className={classes.container}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} md={4}>
                  <FormLabel
                    style={{
                      color: primaryGray,
                      opacity: "1",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    Message Format
                  </FormLabel>
                </Grid>
                <Grid item xs={7} md={7}>
                  <Divider className={classes.divider}/>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    style={{
                      backgroundColor: "ButtonShadow",
                      borderRadius: "80%",
                      boxShadow: "0px 3px 6px #00000029",
                      padding: "3px",
                    }}
                    onClick={() => setOpenIcon3(!openIcon3)}
                  >
                    <ExpandIconTwo rotation={openIcon3}/>
                  </IconButton>
                </Grid>
              </Grid>
              {/* //////////////////////////////////////////////////// */}

              {openIcon3 === true && (
                <Grid container className={classes.container}>
                  {detailsArray.map((item, id) => {
                    if (item.parameterCategory === "Message Format") {
                      let combinedValues = [];
                      if (item.defaultValues && item.defaultValues.length > 0) {
                        combinedValues = [
                          item.defaultValue,
                          ...item.defaultValues,
                        ];
                      }

                      return (
                        <Grid
                          key={id}
                          item
                          xs={6}
                          md={12}
                          style={{
                            textAlign: "left",
                            marginBottom: "22px",
                            paddingRight: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              color: primaryGray,
                              fontSize: "14px",
                              lineHeight: "21px",
                              opacity: "1",
                              width: "89px",
                              height: "44px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.parameter.charAt(0).toUpperCase() +
                              item.parameter.slice(1)}
                          </Typography>
                          {item.defaultValues !== null &&
                          item.defaultValues.length > 0 ? (
                            <Select
                              className={classes.select}
                              value={item.defaultValue}
                              onChange={(event) =>
                                handleSelectChange(event, id)
                              }
                            >
                              {combinedValues.map((value, index) => (
                                <MenuItem
                                  className={classes.menuItem}
                                  key={index}
                                  value={value}
                                >
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <TextBar
                              style={classes.textBarStyle}
                              defaultValue={item.defaultValue}
                              error={{error: false}}
                              onChange={(event) => handleInputChange(event, id)}
                            />
                          )}
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              )}
              <DialogContent
                className={classes.container}
                style={{
                  width: "100%",
                  paddingBottom: "20px",
                  display: "flex",
                }}
              >
                <Grid item xs={12} md={12}>
                  <DialogActions
                    style={{
                      justifyContent: "end",
                      display: "flex  ",
                    }}
                  >
                    <Button
                      onClick={props.onClose}
                      style={{
                        marginRight: "10px",
                        width: "30%",
                        border: "none",
                        color: primaryGray,
                      }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        backgroundColor: primaryBlue,
                        width: "30%",
                        color: "white",
                      }}
                      onClick={() => {
                        handleSaveClick();
                      }}
                      disabled={!changesMade || showValidationAlert}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit"/>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </DialogActions>
                </Grid>
              </DialogContent>
            </Grid>
          </DialogContent>
        </>
        <div>
          {showValidationAlert && (
            <Snackbar key="validationAlert" open={true} autoHideDuration={6000}>
              <Alert severity="error">
                {detailsArray.find((item) => item.id == 8).parameter} is not a
                multiple of{" "}
                {detailsArray.find((item) => item.id == 7).parameter}
              </Alert>
            </Snackbar>
          )}

          {parametersToEdit.length > 0 && (
            <Snackbar
              key="parameterEditAlert"
              open={true}
              autoHideDuration={6000}
              onClose={() => setParametersToEdit([])}
            >
              <Alert severity="warning" onClose={() => setParametersToEdit([])}>
                You need to edit the following parameters:{" "}
                {parametersToEdit.join(",")}
              </Alert>
            </Snackbar>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default EditConfiguration;
